<template>
  <div>
    <invite-header></invite-header>

    <v-container v-if="post">
      <p class="text-center black--text md:text-xl mb-10">
        Thank you for signing up! <br />Your listing has been attached with your
        user account!
      </p>
      <div class="invite-content">
        <post-card :post="post"></post-card>
      </div>
      <p class="text-center black--text md:text-xl md:w-2/4 m-auto my-10">
        Please download the around app and login with your phone to see all
        engagements on your post!
      </p>
      <v-row class="invite-content">
        <v-col cols="6" md="6">
          <a
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.mycompany.around&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          >
            <img src="../../assets/img/googleplay.svg" width="100%" />
          </a>
        </v-col>

        <v-col cols="6" md="6">
          <a
            target="_blank"
            href="https://apps.apple.com/in/app/around-app/id1659424193?itsct=apps_box_badge&itscg=30200"
          >
            <img src="../../assets/img/appstore.svg" width="100%" />
          </a>
        </v-col>
      </v-row>

      <h3
        class="text-lg md:text-3xl text-title font-bold text-center mt-10 !leading-tight mb-5"
      >
        your neighbourhood like never before!
      </h3>
      <div class="d-flex justify-center pb-10">
        <img src="../../assets/img/logo.png" width="140px" />
      </div>
    </v-container>
  </div>
</template>
  <script>
import PostCard from "@/components/invite/PostCard.vue";
import InviteHeader from "@/components/invite/InviteHeader.vue";
import inviteMixins from "@/mixins/invite.mixins";
export default {
  components: { PostCard, InviteHeader },
  data() {
    return {
      isPostLoading: false,
      post: null,
    };
  },
  mixins: [inviteMixins],
  mounted() {
    this.getPost();
  },
};
</script>

<style>
</style>