<template>
  <app-layout :title="title">
    <Textarea
      :label="contentLabel"
      v-model="form.content"
      hide-details
      class="mb-3"
    />
    <div class="d-flex align-center my-3">
      <div class="input-label">Attach Photos / Video</div>
      <v-spacer></v-spacer>
      <v-btn icon tile large color="green" @click="$refs.image.click()">
        <v-icon>mdi-image</v-icon>
      </v-btn>
      <v-btn icon tile large color="red" @click="$refs.video.click()">
        <v-icon size="30">mdi-video</v-icon>
      </v-btn>
    </div>
    <input
      type="file"
      accept="image/*"
      ref="image"
      multiple
      @change="onImageChange"
      class="d-none"
    />
    <input
      type="file"
      accept="video/*"
      ref="video"
      @change="onVideoChange"
      class="d-none"
    />
    <v-row class="mb-1">
      <v-col v-for="(file, i) in files" :key="i" cols="6" md="4">
        <v-card outlined class="attachment-card" style="border-radius: 12px">
          <v-btn icon color="red" @click="removeFile(file)"
            ><v-icon>mdi-delete</v-icon></v-btn
          >
          <v-img aspect-ratio="1" class="ma-2" :src="file.src" contain> </v-img>
          <div
            class="d-flex progress-wrapper align-center justify-center"
            v-if="progress != undefined && file.type == 'video'"
          >
            <v-progress-circular
              :rotate="180"
              :size="100"
              :width="5"
              :value="progress"
              class="text-h5 font-weight-bold"
              color="red"
            >
              {{ progress }}%
            </v-progress-circular>
          </div>
          <div
            class="d-flex progress-wrapper align-center justify-center"
            v-if="progressIndex[i] != undefined && file.type != 'video'"
          >
            <v-progress-circular
              :rotate="180"
              :size="100"
              :width="5"
              :value="progressIndex[i]"
              :indeterminate="progressIndex[i] != 100"
              class="text-h5 font-weight-bold"
              color="red"
            >
              {{ progressIndex[i] }}%
            </v-progress-circular>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <address-field
      label="Add Location (optional)"
      placeholder=""
      v-model="place"
      hide-details
      class="mb-3"
      @onAddress="onAddress"
    />

    <div class="text-center mb-3">or</div>
    <v-btn
      outlined
      block
      :loading="isLocationLoading"
      @click="useCurrent()"
      color="primary"
      class="my-3"
      >Use Current Location</v-btn
    >

    <combo-box
      prepend-inner-icon="mdi-pound"
      label="Hashtags for your post"
      class="mb-3"
      hide-details
      v-model="form.hashtags"
    ></combo-box>
    <div class="input-label">Your post category</div>
    <v-row class="my-0">
      <v-col
        cols="6"
        md="4"
        class="py-2"
        v-for="(item, i) in categories"
        :key="i"
      >
        <v-btn
          depressed
          @click="form.tag = item.name"
          block
          :outlined="form.tag != item.name"
          color="primary"
          rounded
        >
          {{ item.name }}
        </v-btn>
      </v-col>
    </v-row>
    <v-btn
      class="primary my-5"
      block
      :loading="loading"
      depressed
      @click="submit()"
      >Post</v-btn
    >
  </app-layout>
</template>

<script>
import AppLayout from "@/components/ui/AppLayout.vue";
import AddressField from "@/components/ui/form/AddressField.vue";
import ComboBox from "@/components/ui/form/ComboBox.vue";
import {
  GeoPoint,
  collection,
  getDocs,
  orderBy,
  query,
  where,
  addDoc,
  doc,
} from "firebase/firestore";
import { getBase64, mapDocs } from "@/utils/common.utils";
import {
  colCohorts,
  colPosts,
  colUsers,
  db,
  storage,
} from "@/utils/firebase.utils";
import Textarea from "@/components/ui/form/Textarea.vue";
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
  uploadString,
} from "firebase/storage";
import _ from "lodash";
import { MAP_API_KEY } from "@/assets/constants/config.constants";
import axios from "axios";
export default {
  name: "CreatePost",
  components: { AppLayout, AddressField, ComboBox, Textarea },
  data() {
    return {
      form: {
        content: "",
        category: "",
        image: [],
        video: null,
        hashtags: [],
        tag: "",
      },
      place: "",
      isLocationLoading: false,
      progressIndex: {},
      progress: undefined,
      loading: false,
      categories: [],
    };
  },
  computed: {
    contentLabel() {
      if (this.isGroupSelected)
        return `Share with ${this.currentLocation.name} members`;
      return "Say something to people around...";
    },
    title() {
      let name = this.authUser.display_name || "";
      name = name ? name.slice(0, 7) + "..." : "";
      return `Whats up${name ? " " + name : ""}?`;
    },
    files() {
      let files = [...this.form.image].map((item, i) => {
        return { type: "image", src: item, index: i };
      });
      if (this.form.video)
        files.push({
          type: "video",
          file: this.form.video,
          src: require("../../assets/img/video.png"),
        });
      return files;
    },
  },
  methods: {
    removeFile(file) {
      if (file.type == "video") {
        this.form.video = "";
      } else {
        this.form.image.splice(file.index, 1);
      }
    },
    async getPostCategories() {
      const vm = this;
      let { hashtag } = this.$route.query;
      if (hashtag) vm.form.hashtags.push(hashtag);
      try {
        let q = query(
          collection(db, "post_categories"),
          orderBy("display_order", "asc"),
          where("is_active", "==", true)
        );
        vm.categories = mapDocs((await getDocs(q)).docs);
      } catch (error) {
        vm.handleError(error);
      }
    },
    async onImageChange(e) {
      try {
        for (let file of e.target.files) {
          if (file.type.startsWith("image/")) {
            let image = await getBase64(file);
            this.form.image.push(image);
          }
        }
      } catch (error) {
        this.handleError(error);
      }
      this.$refs.image.value = null;
    },
    onVideoChange(e) {
      let file = e.target.files[0];
      if (file && file.type.startsWith("video/")) {
        this.form.video = file;
      }
      this.$refs.video.value = null;
    },
    onAddress(e) {
      if (e) {
        this.form.postLocation = {
          State: e.administrative_area_level_1,
          Address: `${e.name}, ${e.locality}, ${e.country}`,
          Country: e.country,
          City: e.locality,
          zipcode: e.postal_code || "",
          LatLang: new GeoPoint(e.latitude, e.longitude),
        };
        console.log(this.form);
      } else {
        this.form.postLocation = undefined;
      }
    },
    useCurrent() {
      const vm = this;
      vm.isLocationLoading = true;
      if ("geolocation" in navigator) {
        // Get the user's current position
        navigator.geolocation.getCurrentPosition(
          function (position) {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            let apiKey = MAP_API_KEY;
            const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
            axios
              .get(apiUrl)
              .then((res) => {
                let results = res.data.results;
                vm.isLocationLoading = false;
                if (results.length == 0) {
                  vm.$alert("Nothing found here!");
                  return;
                }
                let e = results[0];

                vm.form.postLocation = {
                  State: vm.findAddressDetail(e, "administrative_area_level_1"),
                  Address: `${vm.findAddressDetail(
                    e,
                    "sublocality"
                  )}, ${vm.findAddressDetail(
                    e,
                    "locality"
                  )}, ${vm.findAddressDetail(e, "country")}`,
                  Country: vm.findAddressDetail(e, "country"),
                  City: vm.findAddressDetail(e, "locality"),
                  zipcode: vm.findAddressDetail(e, "postal_code") || "",
                  LatLang: new GeoPoint(latitude, longitude),
                };
                vm.place = vm.form.postLocation.Address;

                // vm.$alert.show("Nothing found here!");
              })
              .catch((e) => {
                vm.handleError(e);
              });

            // Now you can use the latitude and longitude values as needed
          },
          function (error) {
            vm.isLocationLoading = false;
            vm.$alert.show(
              "Please enable location access for goaround.me.",
              "Access Needed"
            );
            console.error("Error getting location:", error.message);
          }
        );
      } else {
        vm.isLocationLoading = false;
        vm.$alert.show("Geolocation is not supported by this browser.");
        console.log("Geolocation is not supported by this browser.");
      }
    },
    findAddressDetail(address, key) {
      let item = address.address_components.find((i) => i.types.includes(key));
      return item ? item.long_name : "";
    },

    async submit() {
      const vm = this;
      console.log(vm.authUser);
      let longitude = _.get(vm.authUser, "residential.LatLang._long", null);
      let latitude = _.get(vm.authUser, "residential.LatLang._lat", null);
      let { video, image, content, tag, postLocation } = vm.form;
      content = content.trim();
      let hashtags = vm.form.hashtags.map((i) => {
        if (!i.startsWith("#")) return `#${i}`;
        return i;
      });
      if (image.length == 0 && !video && !content) {
        vm.$alert.show("Your post needs at least content / image / video!");
        return;
      }
      if (!tag) {
        vm.$alert.show("Please select the post category");
        return;
      }
      if (longitude == null && !postLocation) {
        vm.$alert.show("Please select your location.");
        return;
      }
      let location = new GeoPoint(latitude, longitude);
      if (postLocation) {
        location = postLocation.LatLang;
      }
      let userref = doc(colUsers, vm.uid);
      let post = {
        ...vm.form,
        content,
        hashtags,
        timeanddateofpost: new Date(),
        location,
        devicelocation: location,
        likes: [],
        show_more: true,
        userref,
        web_posted: true,
      };
      if (this.isGroupSelected) {
        let cohortsRef = doc(colCohorts, vm.currentLocation.id);
        post.cohorts = [cohortsRef];
      }
      try {
        vm.loading = true;
        let images = [];
        let index = 0;
        for (let img of image) {
          let id = new Date().getTime();
          let imageRef = ref(storage, `users/${vm.uid}/uploads/${id}.png`);
          vm.progressIndex[index] = 0;
          await uploadString(imageRef, img, "data_url");
          images.push(await getDownloadURL(imageRef));
          vm.progressIndex[index] = 100;
          index++;
        }
        post.image = images;
        if (video) {
          let id = new Date().getTime();
          let videoRef = ref(storage, `users/${vm.uid}/uploads/${id}.mp4`);
          // await uploadBytes(videoRef, video);
          await vm.upload({ storageRef: videoRef, file: video });
          post.video = await getDownloadURL(videoRef);
        }
        await addDoc(colPosts, post);
        setTimeout(() => {
          vm.$router.push("/feed");
          vm.loading = false;
        }, 2000);
      } catch (error) {
        vm.handleError(error);
      }
    },
    upload({ storageRef, file }) {
      return new Promise((resolve, reject) => {
        this.progress = 0;
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            if (!isNaN(progress)) {
              this.progress = progress.toFixed(1);
            }
          },
          (error) => {
            reject(error);
            // Handle unsuccessful uploads
          },
          () => {
            resolve();
          }
        );
      });
    },
  },
  mounted() {
    this.getPostCategories();
  },
};
</script>

<style lang="scss">
.attachment-card {
  position: relative;
  overflow: hidden;
  .v-btn {
    position: absolute;
    right: 0;
    cursor: pointer;
    z-index: 10;
  }
  .progress-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 75%);
    top: 0;
  }
}
</style>
