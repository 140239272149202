<template>
  <v-dialog v-model="dialog" max-width="300px" persistent>
    <v-card>
      <v-card-title>Confirm</v-card-title>
      <v-card-text v-html="message"></v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="error" class="text-none" @click="cancel">
          {{ cancelText }}
        </v-btn>
        <v-btn text color="green" class="text-none" @click="confirm">
          {{ confirmText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ConfirmModal from "./index";
export default {
  data() {
    return {
      message: "",
      dialog: false,
      onConfirm: {},
      onCancel: {},
      cancelText: "No",
      confirmText: "Yes",
    };
  },
  methods: {
    confirm() {
      const vm = this;
      if (typeof vm.onConfirm === "function") {
        vm.onConfirm();
      }
      vm.dialog = false;
    },
    cancel() {
      const vm = this;
      if (typeof vm.onCancel === "function") {
        vm.onCancel();
      }
      vm.dialog = false;
    },
    show(params) {
      const vm = this;
      vm.cancelText = "No";
      vm.confirmText = "Yes";
      Object.keys(params).forEach((key) => {
        vm[key] = params[key];
      });
      vm.dialog = true;
    },
  },
  beforeMount() {
    ConfirmModal.EventBus.$on("show", (params) => {
      this.show(params);
    });
  },
};
</script>

<style></style>
