<template>
  <v-card outlined class="mb-5">
    <v-list-item>
      <v-list-item-avatar>
        <v-avatar color="primary" size="36">
          <span class="white--text font-weight-bold">{{
            post?.invite_name?.charAt(0)
          }}</span>
        </v-avatar>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title class="text-sm md:text-lg"
          >{{ post.invite_name }}
        </v-list-item-title>
        <v-list-item-subtitle class="text-xs md:text-sm">{{
          timeAgo
        }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-card-text class="pt-3">
      <p class="mb-0 text-sm">
        {{ post.content }}
      </p>
      <img
        v-if="post.image && post.image.length > 0"
        class="mt-2"
        :src="post.image[0]"
        style="max-height: 400px; object-fit: cover"
        width="100%"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
export default {
  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    timeAgo() {
      return moment(this.post.timeanddateofpost.seconds * 1000).fromNow();
    },
  },
};
</script>

<style></style>
