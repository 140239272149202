<template>
  <div class="mb-5">
    <div class="d-flex mt-3 justify-space-between align-center">
      <div class="subtitle-1 font-weight-medium">{{ title }}</div>
      <a
        class="subtitle-2 font-weight-medium blue--text text-decoration-underline"
        @click="$router.push('/groups/nearby')"
        v-if="groups.length"
        >Show All</a
      >
      <div
        v-else
        class="subtitle-2 font-weight-medium grey--text text-decoration-underline"
      >
        Show All
      </div>
    </div>
    <v-slide-group
      multiple
      v-if="groups.length"
      show-arrows
      :max="2"
      class="people-slide"
      style="height: 140px"
    >
      <v-slide-item v-for="(group, i) in groups" :key="i">
        <v-card
          class="mr-3 my-1"
          width="160px"
          outlined
          rounded="lg"
          :to="`/group/${group.id}`"
        >
          <v-card-text class="d-flex align-center flex-column">
            <v-avatar color="secondary">
              <v-img v-if="group.iconPath" :src="group.iconPath"></v-img>
              <v-img src="@/assets/img/group.png" v-else></v-img>
            </v-avatar>
            <div
              class="subtitle-2 mt-2 text-truncate text-center black--text text-capitalize"
              style="width: 100%; height: 22px"
            >
              {{ group.name }}
            </div>
            <v-btn small text color="primary">View</v-btn>
          </v-card-text>
        </v-card>
      </v-slide-item>
    </v-slide-group>
    <div
      v-else
      style="height: 170px"
      class="d-flex flex-column align-center justify-center text-center"
    >
      <div class="text-body-2 mb-1">
        Fastest way to get together<br />
        with people around! <br />
        <br />
        Be the first one to start!
      </div>
      <v-btn small outlined color="primary" @click="openCreate()"
        >Create a group</v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    groups: {
      type: Array,
      default: () => [],
    },
    search: {
      type: String,
      default: "",
    },
  },
  computed: {
    title() {
      let search = this.search.trim();
      if (search) return `Groups matching: ${search}`;
      return "Nearby groups";
    },
  },
  methods: {
    isJoined({ id }) {
      if (!this.isAuth) return false;
      let cohorts = (this.authUser.cohorts || []).map((i) =>
        this.getDocPath(i)
      );
      return cohorts.indexOf(id) != -1;
    },
    openCreate() {
      const vm = this;
      if (!vm.confirmLogin()) return;
      vm.$router.push("/create-group");
    },
  },
};
</script>

<style></style>
