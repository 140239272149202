<template>
  <v-card class="mt-5" outlined v-if="false">
    <v-card-text class="subtitle-1 font-weight-regular">
      <b class="purple--text">#TalentOfVasaiVirar </b>

      <br />Show your unique talent to people around
      <br />
      <a
        @click="$router.push('/TalentOfVasaiVirar')"
        class="text-decoration-underline"
        >Learn More</a
      >
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  methods: {},
};
</script>

<style></style>
