<template>
  <div>
    <div class="d-flex align-center">
      <div class="black--text body-2 flex-1">
        <span class="font-weight-bold">{{ comment.username }}</span>
        {{ comment.comment }}
      </div>
      <v-btn
        icon
        color="red"
        v-if="getDocPath(comment.created_by) == uid"
        @click="removeComment(comment)"
        x-small
        ><v-icon>mdi-delete</v-icon></v-btn
      >
    </div>
    <div class="text-caption grey--text">
      {{ timeAgo(comment) }}
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { deleteDoc, doc, increment, writeBatch } from "firebase/firestore";
import { colPosts, db } from "@/utils/firebase.utils";
export default {
  props: {
    comment: {
      type: Object,
      default: () => ({}),
    },
    postId: {
      type: String,
      default: () => "",
    },
  },
  methods: {
    timeAgo({ created_time }) {
      if (created_time.seconds) {
        return moment(created_time.seconds * 1000).fromNow();
      }
      return moment(created_time).fromNow();
    },
    async removeComment(item) {
      const vm = this;
      let postRef = doc(colPosts, vm.postId);
      let commentRef = doc(postRef, "comments", item.id);
      await deleteDoc(commentRef);
      let batch = writeBatch(db);
      batch.delete(commentRef);
      batch.update(postRef, {
        comments: increment(-1),
      });
      await batch.commit();
      vm.$emit("onCommentDelete");
    },
  },
};
</script>

<style></style>
