<template>
  <div>
    <please-wait v-if="isLoading"></please-wait>
    <PinturaEditor
      v-else
      v-bind="editorProps"
      ref="editor"
      :style="editerStyle"
      @pintura:process="handleProcess($event)"
    />
    <video
      id="video"
      type="video/mp4"
      style="visibility: hidden"
      controls
    ></video>
  </div>
</template>
  <script>
import "@pqina/pintura/pintura.css";
import { PinturaEditor } from "@pqina/vue-pintura/vue-2";
import {
  getEditorDefaults,
  setPlugins,
  createDefaultMediaWriter,
  createDefaultImageWriter,
  imageStateToCanvas,
} from "@pqina/pintura";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { colGallery, storage } from "@/utils/firebase.utils";
import PleaseWait from "@/components/ui/PleaseWait.vue";
import {
  getDownloadURL,
  ref,
  uploadBytes,
  uploadString,
} from "firebase/storage";
import { getDate } from "@/utils/common.utils";
import {
  plugin_trim,
  plugin_trim_locale_en_gb,
  createDefaultVideoWriter,
  createMediaStreamEncoder,
} from "@pqina/pintura-video";
import "@pqina/pintura-video/pinturavideo.css";
setPlugins(plugin_trim);
export default {
  name: "App",
  components: {
    PinturaEditor,
    PleaseWait,
  },
  data() {
    return {
      editorProps: getEditorDefaults({
        locale: {
          ...plugin_trim_locale_en_gb,
        },
        imageWriter: createDefaultMediaWriter(
          // Generic writer options, passed to image and video writer
          {
            targetSize: {
              width: 512,
            },
          },
          [
            createDefaultImageWriter({
              // Specific image writer options
            }),
            createDefaultVideoWriter({
              // Specific video writer options
              encoder: createMediaStreamEncoder({
                // Required
                imageStateToCanvas,
              }),
            }),
          ]
        ),
      }),
      inlineResult: "",
      isLoading: false,
    };
  },
  computed: {
    editerStyle() {
      let height = window.innerHeight - 64 + "px";
      return { height };
    },
  },
  methods: {
    async handleProcess(event) {
      console.log(event);
      const vm = this;
      try {
        vm.isLoading = true;
        let id = vm.$route.params.id;
        let thumbnail = await vm.createVideoThumbnail(event.dest);
        let thumbnailRef = ref(storage, `gallery/${this.uid}/${id}_thumb.jpeg`);
        let videoRef = ref(storage, `gallery/${this.uid}/${id}_export.mp4`);

        await uploadString(thumbnailRef, thumbnail, "data_url");
        await uploadBytes(videoRef, event.dest);
        let thumbnailUrl = await getDownloadURL(thumbnailRef);
        let exportUrl = await getDownloadURL(videoRef);
        let data = {
          thumbnail: thumbnailUrl,
          json: JSON.stringify(event.imageState),
          updated_at: getDate(),
          export: exportUrl,
        };
        await updateDoc(doc(colGallery, id), data);
        vm.isLoading = false;
        vm.$router.replace("/gallery");
      } catch (error) {
        vm.handleError(error);
      }
    },
    async createVideoThumbnail(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = function (e) {
          const videoUrl = e.target.result;
          const video = document.getElementById("video");
          video.src = videoUrl;
          video.crossOrigin = "anonymous";
          video.addEventListener("loadedmetadata", function () {
            setTimeout(() => {
              const canvas = document.createElement("canvas");
              canvas.width = video.videoWidth;
              canvas.height = video.videoHeight;
              const context = canvas.getContext("2d");
              context.drawImage(video, 0, 0, canvas.width, canvas.height);
              const thumbnailUrl = canvas.toDataURL("image/jpeg");
              resolve(thumbnailUrl);
            }, 1000);
          });
        };
        // Read the selected video file as data URL
        reader.readAsDataURL(file);
      });
    },
    async fetchImage() {
      const vm = this;
      try {
        vm.isLoading = true;
        let id = vm.$route.params.id;
        let image = (await getDoc(doc(colGallery, id))).data();
        vm.isLoading = false;
        vm.$nextTick(() => {
          let json = {};
          try {
            json = JSON.parse(image.json);
          } catch (error) {
            console.error(error);
          }
          vm.$refs.editor.editor.loadImage(image.url, json);
        });
      } catch (error) {
        vm.handleError(error);
      }
    },
  },
  mounted() {
    this.fetchImage();
  },
};
</script>