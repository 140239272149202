<template>
  <form-wrapper ref="form" @submit="$emit('submit')">
    <v-card class="pa-2">
      <v-card-title class="primary--text pb-3 pt-3">
        {{ title }}
        <v-spacer />
        <v-btn icon color="red" @click="$emit('close')" :disabled="loading">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pb-0">
        <slot></slot>
      </v-card-text>
      <v-card-actions class="mr-2" v-if="isSubmit">
        <v-spacer></v-spacer>
        <slot name="footer">
          <v-btn text :disabled="loading" color="red" @click="$emit('close')"
            >Close</v-btn
          >
          <v-btn :loading="loading" text type="submit" color="primary">{{
            submitText
          }}</v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </form-wrapper>
</template>

<script>
import FormWrapper from "./form/FormWrapper.vue";
export default {
  components: { FormWrapper },
  props: {
    isSubmit: {
      type: Boolean,
      default: true,
    },
    submitText: {
      type: String,
      default: "Submit",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {},
  },
  methods: {
    reset() {
      this.$refs.form.reset();
    },
  },
};
</script>

<style></style>
