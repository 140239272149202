<template>
  <app-layout>
    <div>
      <div>
        <strong
          ><span style="font-size: 26px"
            ><span data-custom-class="title">PRIVACY NOTICE</span></span
          ></strong
        >
      </div>
      <div>
        <br />
      </div>
      <div>
        <span style="color: rgb(127, 127, 127)"
          ><strong
            ><span style="font-size: 15px"
              ><span data-custom-class="subtitle"
                >Last updated October 07, 2022</span
              ></span
            ></strong
          ></span
        >
      </div>
      <div>
        <br />
      </div>
      <div>
        <br />
      </div>
      <div>
        <br />
      </div>
      <div style="line-height: 1.5">
        <span style="color: rgb(127, 127, 127)"
          ><span style="color: rgb(89, 89, 89); font-size: 15px"
            ><span data-custom-class="body_text"
              >This privacy notice for GoAround.me ("
              <strong>Company</strong> ," "<strong>we</strong>,"
              "<strong>us</strong>," or "<strong>our</strong>"</span
            >
            <span data-custom-class="body_text"
              >), describes how and why we might collect, store, use, and/or
              share ("<strong>process</strong>") your information when you use
              our services ("<strong>Services</strong>"), such as when
              you:</span
            ></span
          ></span
        >
      </div>
      <ul>
        <li style="line-height: 1.5">
          <span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span data-custom-class="body_text"
                >Visit our website at goaround.me
                <span style="font-size: 15px"
                  ><span style="color: rgb(89, 89, 89)"
                    ><span data-custom-class="body_text"
                      ><span style="font-size: 15px"
                        ><span style="color: rgb(89, 89, 89)"
                          >, or any website of ours that links to this privacy
                          notice</span
                        ></span
                      ></span
                    ></span
                  ></span
                ></span
              ></span
            ></span
          >
        </li>
      </ul>
      <div>
        <div style="line-height: 1.5"></div>
        <ul>
          <li style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  >Engage with us in other related ways, including any sales,
                  marketing, or events</span
                ></span
              ></span
            >
          </li>
        </ul>
        <div style="line-height: 1.5">
          <span style="font-size: 15px"
            ><span style="color: rgb(127, 127, 127)"
              ><span data-custom-class="body_text"
                ><strong>Questions or concerns?</strong> Reading this privacy
                notice will help you understand your privacy rights and choices.
                If you do not agree with our policies and practices, please do
                not use our Services. If you still have any questions or
                concerns, please contact us at hello@goaround.me .</span
              ></span
            ></span
          >
        </div>
        <div style="line-height: 1.5">
          <br />
        </div>
        <div style="line-height: 1.5">
          <br />
        </div>
        <div style="line-height: 1.5">
          <strong
            ><span style="font-size: 15px"
              ><span data-custom-class="heading_1"
                >SUMMARY OF KEY POINTS</span
              ></span
            ></strong
          >
        </div>
        <div style="line-height: 1.5">
          <br />
        </div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px"
            ><span data-custom-class="body_text"
              ><strong
                ><em
                  >This summary provides key points from our privacy notice, but
                  you can find out more details about any of these topics by
                  clicking the link following each key point or by using our
                  table of contents below to find the section you are looking
                  for. You can also click</em
                ></strong
              ></span
            ></span
          >
          <a data-custom-class="link" href="#toc"
            ><span style="font-size: 15px"
              ><span data-custom-class="body_text"
                ><strong><em>here</em></strong></span
              ></span
            ></a
          >
          <span style="font-size: 15px"
            ><span data-custom-class="body_text"
              ><strong
                ><em>to go directly to our table of contents.</em></strong
              ></span
            ></span
          >
        </div>
        <div style="line-height: 1.5">
          <br />
        </div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px"
            ><span data-custom-class="body_text"
              ><strong>What personal information do we process?</strong> When
              you visit, use, or navigate our Services, we may process personal
              information depending on how you interact with GoAround.me and the
              Services, the choices you make, and the products and features you
              use. Click</span
            ></span
          >
          <a data-custom-class="link" href="#personalinfo"
            ><span style="font-size: 15px"
              ><span data-custom-class="body_text">here</span></span
            ></a
          >
          <span style="font-size: 15px"
            ><span data-custom-class="body_text">to learn more.</span></span
          >
        </div>
        <div style="line-height: 1.5">
          <br />
        </div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px"
            ><span data-custom-class="body_text"
              ><strong
                >Do we process any sensitive personal information?</strong
              >
              We do not process sensitive personal information.</span
            ></span
          >
        </div>
        <div style="line-height: 1.5">
          <br />
        </div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px"
            ><span data-custom-class="body_text"
              ><strong
                >Do we receive any information from third parties?</strong
              >
              We may receive information from public databases, marketing
              partners, social media platforms, and other outside sources.
              Click</span
            ></span
          >
          <a data-custom-class="link" href="#othersources"
            ><span style="font-size: 15px"
              ><span data-custom-class="body_text">here</span></span
            ></a
          >
          <span style="font-size: 15px"
            ><span data-custom-class="body_text">to learn more.</span></span
          >
        </div>
        <div style="line-height: 1.5">
          <br />
        </div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px"
            ><span data-custom-class="body_text"
              ><strong>How do we process your information?</strong> We process
              your information to provide, improve, and administer our Services,
              communicate with you, for security and fraud prevention, and to
              comply with law. We may also process your information for other
              purposes with your consent. We process your information only when
              we have a valid legal reason to do so. Click</span
            ></span
          >
          <a data-custom-class="link" href="#infouse"
            ><span style="font-size: 15px"
              ><span data-custom-class="body_text">here</span></span
            ></a
          >
          <span style="font-size: 15px"
            ><span data-custom-class="body_text">to learn more.</span></span
          >
        </div>
        <div style="line-height: 1.5">
          <br />
        </div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px"
            ><span data-custom-class="body_text"
              ><strong
                >In what situations and with which parties do we share personal
                information?</strong
              >
              We may share information in specific situations and with specific
              third parties. Click</span
            ></span
          >
          <a data-custom-class="link" href="#whoshare"
            ><span style="font-size: 15px"
              ><span data-custom-class="body_text">here</span></span
            ></a
          >
          <span style="font-size: 15px"
            ><span data-custom-class="body_text">to learn more.</span></span
          >
        </div>
        <div style="line-height: 1.5">
          <br />
        </div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px"
            ><span data-custom-class="body_text"
              ><strong>How do we keep your information safe?</strong> We have
              organizational and technical processes and procedures in place to
              protect your personal information. However, no electronic
              transmission over the internet or information storage technology
              can be guaranteed to be 100% secure, so we cannot promise or
              guarantee that hackers, cybercriminals, or other unauthorized
              third parties will not be able to defeat our security and
              improperly collect, access, steal, or modify your information.
              Click</span
            ></span
          >
          <a data-custom-class="link" href="#infosafe"
            ><span style="font-size: 15px"
              ><span data-custom-class="body_text">here</span></span
            ></a
          >
          <span style="font-size: 15px"
            ><span data-custom-class="body_text">to learn more.</span></span
          >
        </div>
        <div style="line-height: 1.5">
          <br />
        </div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px"
            ><span data-custom-class="body_text"
              ><strong>What are your rights?</strong> Depending on where you are
              located geographically, the applicable privacy law may mean you
              have certain rights regarding your personal information.
              Click</span
            ></span
          >
          <a data-custom-class="link" href="#privacyrights"
            ><span style="font-size: 15px"
              ><span data-custom-class="body_text">here</span></span
            ></a
          >
          <span style="font-size: 15px"
            ><span data-custom-class="body_text">to learn more.</span></span
          >
        </div>
        <div style="line-height: 1.5">
          <br />
        </div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px"
            ><span data-custom-class="body_text"
              ><strong>How do you exercise your rights?</strong> The easiest way
              to exercise your rights is by filling out our data subject request
              form available</span
            ></span
          >
          <a
            data-custom-class="link"
            href="https://app.termly.io/notify/61c647ad-c734-4aa5-8cf7-d56348139149"
            rel="noopener noreferrer"
            target="_blank"
            ><span style="font-size: 15px"
              ><span data-custom-class="body_text">here</span></span
            ></a
          >
          <span style="font-size: 15px"
            ><span data-custom-class="body_text"
              >, or by contacting us. We will consider and act upon any request
              in accordance with applicable data protection laws.</span
            ></span
          >
        </div>
        <div style="line-height: 1.5">
          <br />
        </div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px"
            ><span data-custom-class="body_text"
              >Want to learn more about what GoAround.me does with any
              information we collect? Click</span
            ></span
          >
          <a data-custom-class="link" href="#toc"
            ><span style="font-size: 15px"
              ><span data-custom-class="body_text">here</span></span
            ></a
          >
          <span style="font-size: 15px"
            ><span data-custom-class="body_text"
              >to review the notice in full.</span
            ></span
          >
        </div>
        <div style="line-height: 1.5">
          <br />
        </div>
        <div style="line-height: 1.5">
          <br />
        </div>
        <div id="toc" style="line-height: 1.5">
          <span style="font-size: 15px"
            ><span style="color: rgb(127, 127, 127)"
              ><span style="color: rgb(0, 0, 0)"
                ><strong
                  ><span data-custom-class="heading_1"
                    >TABLE OF CONTENTS</span
                  ></strong
                ></span
              ></span
            ></span
          >
        </div>
        <div style="line-height: 1.5">
          <br />
        </div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px"
            ><a data-custom-class="link" href="#infocollect"
              ><span style="color: rgb(89, 89, 89)"
                >1. WHAT INFORMATION DO WE COLLECT?</span
              ></a
            ></span
          >
        </div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px"
            ><a data-custom-class="link" href="#infouse"
              ><span style="color: rgb(89, 89, 89)"
                >2. HOW DO WE PROCESS YOUR INFORMATION?</span
              ></a
            ></span
          >
        </div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px"
            ><a data-custom-class="link" href="#legalbases"
              ><span style="color: rgb(89, 89, 89)"
                >3.
                <span style="font-size: 15px"
                  ><span style="color: rgb(89, 89, 89)"
                    >WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                    INFORMATION?</span
                  ></span
                ></span
              ></a
            ></span
          >
        </div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px"
            ><span style="color: rgb(89, 89, 89)"
              ><a data-custom-class="link" href="#whoshare"
                >4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a
              ></span
            ></span
          >
        </div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px"
            ><a data-custom-class="link" href="#cookies"
              ><span style="color: rgb(89, 89, 89)"
                >5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span
              ></a
            >
          </span>
        </div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px"
            ><a data-custom-class="link" href="#sociallogins"
              ><span style="color: rgb(89, 89, 89)"
                ><span style="color: rgb(89, 89, 89)"
                  ><span style="color: rgb(89, 89, 89)"
                    >6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</span
                  ></span
                ></span
              ></a
            >
          </span>
        </div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px"
            ><a data-custom-class="link" href="#intltransfers"
              ><span style="color: rgb(89, 89, 89)"
                >7. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</span
              ></a
            ></span
          >
        </div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px"
            ><a data-custom-class="link" href="#inforetain"
              ><span style="color: rgb(89, 89, 89)"
                >8. HOW LONG DO WE KEEP YOUR INFORMATION?</span
              ></a
            ></span
          >
        </div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px"
            ><a data-custom-class="link" href="#infosafe"
              ><span style="color: rgb(89, 89, 89)"
                >9. HOW DO WE KEEP YOUR INFORMATION SAFE?</span
              ></a
            >
          </span>
        </div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px"
            ><a data-custom-class="link" href="#infominors"
              ><span style="color: rgb(89, 89, 89)"
                >10. DO WE COLLECT INFORMATION FROM MINORS?</span
              ></a
            ></span
          >
        </div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px"
            ><span style="color: rgb(89, 89, 89)"
              ><a data-custom-class="link" href="#privacyrights"
                >11. WHAT ARE YOUR PRIVACY RIGHTS?</a
              ></span
            ></span
          >
        </div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px"
            ><a data-custom-class="link" href="#DNT"
              ><span style="color: rgb(89, 89, 89)"
                >12. CONTROLS FOR DO-NOT-TRACK FEATURES</span
              ></a
            ></span
          >
        </div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px"
            ><a data-custom-class="link" href="#caresidents"
              ><span style="color: rgb(89, 89, 89)"
                >13. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span
              ></a
            ></span
          >
        </div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px"
            ><a data-custom-class="link" href="#policyupdates"
              ><span style="color: rgb(89, 89, 89)"
                >14. DO WE MAKE UPDATES TO THIS NOTICE?</span
              ></a
            ></span
          >
        </div>
        <div style="line-height: 1.5">
          <a data-custom-class="link" href="#contact"
            ><span style="color: rgb(89, 89, 89); font-size: 15px"
              >15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span
            ></a
          >
        </div>
        <div style="line-height: 1.5">
          <a data-custom-class="link" href="#request"
            ><span style="color: rgb(89, 89, 89)"
              >16. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
              FROM YOU?</span
            ></a
          >
        </div>
        <div style="line-height: 1.5">
          <br />
        </div>
        <div id="infocollect" style="line-height: 1.5">
          <span style="color: rgb(127, 127, 127)"
            ><span style="color: rgb(89, 89, 89); font-size: 15px"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span id="control" style="color: rgb(0, 0, 0)"
                    ><strong
                      ><span data-custom-class="heading_1"
                        >1. WHAT INFORMATION DO WE COLLECT?</span
                      ></strong
                    ></span
                  ></span
                ></span
              ></span
            ></span
          >
        </div>
        <div style="line-height: 1.5">
          <br />
        </div>
        <div id="personalinfo" style="line-height: 1.5">
          <span data-custom-class="heading_2" style="color: rgb(0, 0, 0)"
            ><span style="font-size: 15px"
              ><strong>Personal information you disclose to us</strong></span
            ></span
          >
        </div>
        <div>
          <div>
            <br />
          </div>
          <div style="line-height: 1.5">
            <span style="color: rgb(127, 127, 127)"
              ><span style="color: rgb(89, 89, 89); font-size: 15px"
                ><span data-custom-class="body_text"
                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                      ><span data-custom-class="body_text"
                        ><strong><em>In Short:</em></strong></span
                      ></span
                    ></span
                  ></span
                >
                <span data-custom-class="body_text"
                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                      ><span data-custom-class="body_text"
                        ><em
                          >We collect personal information that you provide to
                          us.</em
                        ></span
                      ></span
                    ></span
                  ></span
                ></span
              ></span
            >
          </div>
        </div>
        <div style="line-height: 1.5">
          <br />
        </div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span data-custom-class="body_text"
                >We collect personal information that you voluntarily provide to
                us when you register on the Services,</span
              >
              <span style="font-size: 15px"
                ><span data-custom-class="body_text"
                  >express an interest in obtaining information about us or our
                  products and Services, when you participate in activities on
                  the Services, or otherwise when you contact us.</span
                ></span
              ></span
            ></span
          >
        </div>
        <div style="line-height: 1.5">
          <br />
        </div>
        <div style="line-height: 1.5"></div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span data-custom-class="body_text"
                ><strong>Personal Information Provided by You.</strong> The
                personal information that we collect depends on the context of
                your interactions with us and the Services, the choices you
                make, and the products and features you use. The personal
                information we collect may include the following:</span
              ></span
            ></span
          >
        </div>
        <ul>
          <li style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  ><span style="font-size: 15px"
                    ><span data-custom-class="body_text"
                      >email addresses</span
                    ></span
                  ></span
                ></span
              ></span
            >
          </li>
        </ul>
        <div style="line-height: 1.5"></div>
        <ul>
          <li style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  ><span style="font-size: 15px"
                    ><span data-custom-class="body_text">names</span></span
                  ></span
                ></span
              ></span
            >
          </li>
        </ul>
        <div style="line-height: 1.5"></div>
        <ul>
          <li style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  ><span style="font-size: 15px"
                    ><span data-custom-class="body_text"
                      >phone numbers</span
                    ></span
                  ></span
                ></span
              ></span
            >
          </li>
        </ul>
        <div style="line-height: 1.5"></div>
        <ul>
          <li style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  ><span style="font-size: 15px"
                    ><span data-custom-class="body_text"
                      >mailing addresses</span
                    ></span
                  ></span
                ></span
              ></span
            >
          </li>
        </ul>
        <div style="line-height: 1.5"></div>
        <ul>
          <li style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  ><span style="font-size: 15px"
                    ><span data-custom-class="body_text">job titles</span></span
                  ></span
                ></span
              ></span
            >
          </li>
        </ul>
        <div style="line-height: 1.5"></div>
        <ul>
          <li style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  ><span style="font-size: 15px"
                    ><span data-custom-class="body_text">usernames</span></span
                  ></span
                ></span
              ></span
            >
          </li>
        </ul>
        <div style="line-height: 1.5"></div>
        <ul>
          <li style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  ><span style="font-size: 15px"
                    ><span data-custom-class="body_text">passwords</span></span
                  ></span
                ></span
              ></span
            >
          </li>
        </ul>
        <div style="line-height: 1.5"></div>
        <ul>
          <li style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  ><span style="font-size: 15px"
                    ><span data-custom-class="body_text"
                      >contact preferences</span
                    ></span
                  ></span
                ></span
              ></span
            >
          </li>
        </ul>
        <div style="line-height: 1.5"></div>
        <ul>
          <li style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  ><span style="font-size: 15px"
                    ><span data-custom-class="body_text"
                      >contact or authentication data</span
                    ></span
                  ></span
                ></span
              ></span
            >
          </li>
        </ul>
        <div style="line-height: 1.5"></div>
        <ul>
          <li style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  ><span style="font-size: 15px"
                    ><span data-custom-class="body_text"
                      >billing addresses</span
                    ></span
                  ></span
                ></span
              ></span
            >
          </li>
        </ul>
        <div style="line-height: 1.5"></div>
        <ul>
          <li style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  ><span style="font-size: 15px"
                    ><span data-custom-class="body_text"
                      >debit/credit card numbers</span
                    ></span
                  ></span
                ></span
              ></span
            >
          </li>
        </ul>
        <div style="line-height: 1.5"></div>
        <ul>
          <li style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  ><span style="font-size: 15px"
                    ><span data-custom-class="body_text"
                      >personal skills</span
                    ></span
                  ></span
                ></span
              ></span
            >
          </li>
        </ul>
        <div style="line-height: 1.5"></div>
        <ul>
          <li style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  ><span style="font-size: 15px"
                    ><span data-custom-class="body_text"
                      >professional skills</span
                    ></span
                  ></span
                ></span
              ></span
            >
          </li>
        </ul>
        <div style="line-height: 1.5"></div>
        <ul>
          <li style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  ><span style="font-size: 15px"
                    ><span data-custom-class="body_text"
                      >personal interests</span
                    ></span
                  ></span
                ></span
              ></span
            >
          </li>
        </ul>
        <div style="line-height: 1.5"></div>
        <div id="sensitiveinfo" style="line-height: 1.5">
          <span style="font-size: 15px"
            ><span data-custom-class="body_text"
              ><strong>Sensitive Information.</strong> We do not process
              sensitive information.</span
            ></span
          >
        </div>
        <div style="line-height: 1.5">
          <br />
        </div>
        <div style="line-height: 1.5"></div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span data-custom-class="body_text"
                ><strong>Social Media Login Data.</strong> We may provide you
                with the option to register with us using your existing social
                media account details, like your Facebook, Twitter, or other
                social media account. If you choose to register in this way, we
                will collect the information described in the section called
                "<span style="font-size: 15px"
                  ><span data-custom-class="body_text"
                    ><span style="font-size: 15px"
                      ><span style="color: rgb(89, 89, 89)"
                        ><a data-custom-class="link" href="#sociallogins"
                          >HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a
                        ></span
                      ></span
                    ></span
                  ></span
                >
                " below.</span
              ></span
            ></span
          >
        </div>
        <div style="line-height: 1.5">
          <br />
        </div>
        <div style="line-height: 1.5"></div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span data-custom-class="body_text"
                >All personal information that you provide to us must be true,
                complete, and accurate, and you must notify us of any changes to
                such personal information.</span
              ></span
            ></span
          >
        </div>
        <div style="line-height: 1.5">
          <br />
        </div>
        <div style="line-height: 1.5"></div>
        <div style="line-height: 1.5">
          <span data-custom-class="heading_2" style="color: rgb(0, 0, 0)"
            ><span style="font-size: 15px"
              ><strong>Information automatically collected</strong></span
            ></span
          >
        </div>
        <div>
          <div>
            <br />
          </div>
          <div style="line-height: 1.5">
            <span style="color: rgb(127, 127, 127)"
              ><span style="color: rgb(89, 89, 89); font-size: 15px"
                ><span data-custom-class="body_text"
                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                      ><span data-custom-class="body_text"
                        ><strong><em>In Short:</em></strong></span
                      ></span
                    ></span
                  ></span
                >
                <span data-custom-class="body_text"
                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                      ><span data-custom-class="body_text"
                        ><em
                          >Some information — such as your Internet Protocol
                          (IP) address and/or browser and device characteristics
                          — is collected automatically when you visit our
                          Services.</em
                        ></span
                      ></span
                    ></span
                  ></span
                ></span
              ></span
            >
          </div>
        </div>
        <div style="line-height: 1.5">
          <br />
        </div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span data-custom-class="body_text"
                >We automatically collect certain information when you visit,
                use, or navigate the Services. This information does not reveal
                your specific identity (like your name or contact information)
                but may include device and usage information, such as your IP
                address, browser and device characteristics, operating system,
                language preferences, referring URLs, device name, country,
                location, information about how and when you use our Services,
                and other technical information. This information is primarily
                needed to maintain the security and operation of our Services,
                and for our internal analytics and reporting purposes.</span
              ></span
            ></span
          >
        </div>
        <div style="line-height: 1.5">
          <br />
        </div>
        <div style="line-height: 1.5"></div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span data-custom-class="body_text"
                >Like many businesses, we also collect information through
                cookies and similar technologies.</span
              ></span
            ></span
          >
        </div>
        <div style="line-height: 1.5">
          <br />
        </div>
        <div style="line-height: 1.5"></div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span data-custom-class="body_text"
                >The information we collect includes:</span
              ></span
            ></span
          >
        </div>
        <ul>
          <li style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  ><em>Log and Usage Data.</em> Log and usage data is
                  service-related, diagnostic, usage, and performance
                  information our servers automatically collect when you access
                  or use our Services and which we record in log files.
                  Depending on how you interact with us, this log data may
                  include your IP address, device information, browser type, and
                  settings and information about your activity in the Services
                  (such as the date/time stamps associated with your usage,
                  pages and files viewed, searches, and other actions you take
                  such as which features you use), device event information
                  (such as system activity, error reports (sometimes called
                  "crash dumps" ), and hardware settings).</span
                ></span
              ></span
            >
          </li>
        </ul>
        <div style="line-height: 1.5"></div>
        <ul>
          <li style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  ><em>Device Data.</em> We collect device data such as
                  information about your computer, phone, tablet, or other
                  device you use to access the Services. Depending on the device
                  used, this device data may include information such as your IP
                  address (or proxy server), device and application
                  identification numbers, location, browser type, hardware
                  model, Internet service provider and/or mobile carrier,
                  operating system, and system configuration information.</span
                ></span
              ></span
            >
          </li>
        </ul>
        <div style="line-height: 1.5"></div>
        <ul>
          <li style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  ><em>Location Data.</em> We collect location data such as
                  information about your device's location, which can be either
                  precise or imprecise. How much information we collect depends
                  on the type and settings of the device you use to access the
                  Services. For example, we may use GPS and other technologies
                  to collect geolocation data that tells us your current
                  location (based on your IP address). You can opt out of
                  allowing us to collect this information either by refusing
                  access to the information or by disabling your Location
                  setting on your device. However, if you choose to opt out, you
                  may not be able to use certain aspects of the Services.</span
                ></span
              ></span
            >
          </li>
        </ul>
        <div></div>
        <div id="othersources" style="line-height: 1.5">
          <span data-custom-class="heading_2" style="color: rgb(0, 0, 0)"
            ><span style="font-size: 15px"
              ><strong>Information collected from other sources</strong></span
            ></span
          >
        </div>
        <div>
          <div>
            <br />
          </div>
          <div style="line-height: 1.5">
            <span style="color: rgb(127, 127, 127)"
              ><span style="color: rgb(89, 89, 89); font-size: 15px"
                ><span data-custom-class="body_text"
                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                      ><span data-custom-class="body_text"
                        ><strong><em>In Short:</em></strong>
                        <em
                          >We may collect limited data from public databases,
                          marketing partners,
                          <span style="color: rgb(89, 89, 89)"
                            ><span style="font-size: 15px"
                              ><em
                                ><span data-custom-class="body_text"
                                  >social media platforms,</span
                                ></em
                              ></span
                            ></span
                          >
                          and other outside sources.</em
                        ></span
                      ></span
                    ></span
                  ></span
                ></span
              ></span
            >
          </div>
        </div>
        <div style="line-height: 1.5">
          <br />
        </div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span data-custom-class="body_text"
                >In order to enhance our ability to provide relevant marketing,
                offers, and services to you and update our records, we may
                obtain information about you from other sources, such as public
                databases, joint marketing partners, affiliate programs, data
                providers,
                <span style="color: rgb(89, 89, 89)"
                  ><span style="font-size: 15px"
                    ><span data-custom-class="body_text"
                      >social media platforms,</span
                    ></span
                  ></span
                >
                and from other third parties. This information includes mailing
                addresses, job titles, email addresses, phone numbers, intent
                data (or user behavior data), Internet Protocol (IP) addresses,
                social media profiles, social media URLs, and custom profiles,
                for purposes of targeted advertising and event promotion.
                <span style="color: rgb(89, 89, 89)"
                  ><span style="font-size: 15px"
                    ><span data-custom-class="body_text"
                      >If you interact with us on a social media platform using
                      your social media account (e.g., Facebook or Twitter), we
                      receive personal information about you such as your name,
                      email address, and gender. Any personal information that
                      we collect from your social media account depends on your
                      social media account's privacy settings.</span
                    ></span
                  ></span
                ></span
              ></span
            ></span
          >
        </div>
        <div style="line-height: 1.5">
          <br />
        </div>
        <div style="line-height: 1.5"></div>
        <div id="infouse" style="line-height: 1.5">
          <span style="color: rgb(127, 127, 127)"
            ><span style="color: rgb(89, 89, 89); font-size: 15px"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span id="control" style="color: rgb(0, 0, 0)"
                    ><strong
                      ><span data-custom-class="heading_1"
                        >2. HOW DO WE PROCESS YOUR INFORMATION?</span
                      ></strong
                    ></span
                  ></span
                ></span
              ></span
            ></span
          >
        </div>
        <div>
          <div style="line-height: 1.5">
            <br />
          </div>
          <div style="line-height: 1.5">
            <span style="color: rgb(127, 127, 127)"
              ><span style="color: rgb(89, 89, 89); font-size: 15px"
                ><span data-custom-class="body_text"
                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                      ><span data-custom-class="body_text"
                        ><strong><em>In Short:</em></strong>
                        <em
                          >We process your information to provide, improve, and
                          administer our Services, communicate with you, for
                          security and fraud prevention, and to comply with law.
                          We may also process your information for other
                          purposes with your consent.</em
                        ></span
                      ></span
                    ></span
                  ></span
                ></span
              ></span
            >
          </div>
        </div>
        <div style="line-height: 1.5">
          <br />
        </div>
        <div style="line-height: 1.5">
          <span style="font-size: 15px; color: rgb(89, 89, 89)"
            ><span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span data-custom-class="body_text"
                ><strong
                  >We process your personal information for a variety of
                  reasons, depending on how you interact with our Services,
                  including:</strong
                ></span
              ></span
            ></span
          >
        </div>
        <ul>
          <li style="line-height: 1.5">
            <span style="font-size: 15px; color: rgb(89, 89, 89)"
              ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span data-custom-class="body_text"
                  ><strong
                    >To facilitate account creation and authentication and
                    otherwise manage user accounts.</strong
                  >
                  We may process your information so you can create and log in
                  to your account, as well as keep your account in working
                  order.</span
                ></span
              ></span
            >
          </li>
        </ul>
        <div style="line-height: 1.5">
          <div style="line-height: 1.5"></div>
          <ul>
            <li style="line-height: 1.5">
              <span style="font-size: 15px; color: rgb(89, 89, 89)"
                ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span data-custom-class="body_text"
                    ><strong
                      >To deliver and facilitate delivery of services to the
                      user.</strong
                    >
                    We may process your information to provide you with the
                    requested service.
                  </span></span
                ></span
              >
            </li>
          </ul>
          <div style="line-height: 1.5">
            <div style="line-height: 1.5"></div>
            <ul>
              <li style="line-height: 1.5">
                <span style="font-size: 15px; color: rgb(89, 89, 89)"
                  ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                    ><span data-custom-class="body_text"
                      ><strong
                        >To respond to user inquiries/offer support to
                        users.</strong
                      >
                      We may process your information to respond to your
                      inquiries and solve any potential issues you might have
                      with the requested service.</span
                    ></span
                  ></span
                >
              </li>
            </ul>
            <div style="line-height: 1.5">
              <div style="line-height: 1.5"></div>
              <ul>
                <li style="line-height: 1.5">
                  <span style="font-size: 15px; color: rgb(89, 89, 89)"
                    ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                      ><span data-custom-class="body_text"
                        ><strong
                          >To send administrative information to you.</strong
                        >
                        We may process your information to send you details
                        about our products and services, changes to our terms
                        and policies, and other similar information.</span
                      ></span
                    ></span
                  >
                </li>
              </ul>
              <div style="line-height: 1.5">
                <div style="line-height: 1.5"></div>
                <ul>
                  <li style="line-height: 1.5">
                    <span data-custom-class="body_text"
                      ><span style="font-size: 15px"
                        ><strong>To fulfill and manage your orders.</strong> We
                        may process your information to fulfill and manage your
                        orders, payments, returns, and exchanges made through
                        the Services.</span
                      ></span
                    >
                  </li>
                </ul>
                <p style="font-size: 15px; line-height: 1.5"></p>
                <p style="font-size: 15px; line-height: 1.5"></p>
                <ul>
                  <li style="line-height: 1.5">
                    <span style="font-size: 15px"
                      ><span style="color: rgb(89, 89, 89)"
                        ><span style="color: rgb(89, 89, 89)"
                          ><span data-custom-class="body_text"
                            ><strong
                              >To enable user-to-user communications.</strong
                            >
                            We may process your information if you choose to use
                            any of our offerings that allow for communication
                            with another user.
                          </span></span
                        ></span
                      ></span
                    >
                  </li>
                </ul>
                <p style="font-size: 15px; line-height: 1.5"></p>
                <p style="font-size: 15px; line-height: 1.5"></p>
                <ul>
                  <li style="line-height: 1.5">
                    <span style="font-size: 15px"
                      ><span style="color: rgb(89, 89, 89)"
                        ><span style="color: rgb(89, 89, 89)"
                          ><span data-custom-class="body_text"
                            ><strong>To request feedback.</strong> We may
                            process your information when necessary to request
                            feedback and to contact you about your use of our
                            Services.
                          </span></span
                        ></span
                      ></span
                    >
                  </li>
                </ul>
                <div style="line-height: 1.5">
                  <div style="line-height: 1.5"></div>
                  <ul>
                    <li style="line-height: 1.5">
                      <span style="font-size: 15px; color: rgb(89, 89, 89)"
                        ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                          ><span data-custom-class="body_text"
                            ><strong
                              >To send you marketing and promotional
                              communications.</strong
                            >
                            We may process the personal information you send to
                            us for our marketing purposes, if this is in
                            accordance with your marketing preferences. You can
                            opt out of our marketing emails at any time. For
                            more information, see "</span
                          ></span
                        ></span
                      ><a data-custom-class="link" href="#privacyrights"
                        ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                          ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                            ><span data-custom-class="body_text"
                              >WHAT ARE YOUR PRIVACY RIGHTS?</span
                            ></span
                          ></span
                        ></a
                      >
                      <span style="font-size: 15px; color: rgb(89, 89, 89)"
                        ><span style="font-size: 15px; color: rgb(89, 89, 89)"
                          ><span data-custom-class="body_text"
                            >" below).</span
                          ></span
                        ></span
                      >
                    </li>
                  </ul>
                  <div style="line-height: 1.5">
                    <div style="line-height: 1.5"></div>
                    <ul>
                      <li style="line-height: 1.5">
                        <span style="font-size: 15px"
                          ><span data-custom-class="body_text"
                            ><strong
                              >To deliver targeted advertising to you.</strong
                            >
                            We may process your information to develop and
                            display personalized content and advertising
                            tailored to your interests, location, and
                            more.</span
                          ></span
                        >
                      </li>
                    </ul>
                    <div style="line-height: 1.5">
                      <div style="line-height: 1.5">
                        <div style="line-height: 1.5"></div>
                        <ul>
                          <li style="line-height: 1.5">
                            <span data-custom-class="body_text"
                              ><span style="font-size: 15px"
                                ><strong>To protect our Services.</strong> We
                                may process your information as part of our
                                efforts to keep our Services safe and secure,
                                including fraud monitoring and prevention.</span
                              ></span
                            >
                          </li>
                        </ul>
                        <div style="line-height: 1.5">
                          <div style="line-height: 1.5">
                            <div style="line-height: 1.5">
                              <div style="line-height: 1.5"></div>
                              <ul>
                                <li style="line-height: 1.5">
                                  <span data-custom-class="body_text"
                                    ><span style="font-size: 15px"
                                      ><strong
                                        >To identify usage trends.</strong
                                      >
                                      We may process information about how you
                                      use our Services to better understand how
                                      they are being used so we can improve
                                      them.</span
                                    ></span
                                  >
                                </li>
                              </ul>
                              <div style="line-height: 1.5">
                                <div style="line-height: 1.5"></div>
                                <ul>
                                  <li style="line-height: 1.5">
                                    <span style="font-size: 15px"
                                      ><span data-custom-class="body_text"
                                        ><strong
                                          >To determine the effectiveness of our
                                          marketing and promotional
                                          campaigns.</strong
                                        >
                                        We may process your information to
                                        better understand how to provide
                                        marketing and promotional campaigns that
                                        are most relevant to you.</span
                                      ></span
                                    >
                                  </li>
                                </ul>
                                <div style="line-height: 1.5">
                                  <div style="line-height: 1.5"></div>
                                  <ul>
                                    <li style="line-height: 1.5">
                                      <span data-custom-class="body_text"
                                        ><span style="font-size: 15px"
                                          ><strong
                                            >To save or protect an individual's
                                            vital interest.</strong
                                          >
                                          We may process your information when
                                          necessary to save or protect an
                                          individual’s vital interest, such as
                                          to prevent harm.</span
                                        ></span
                                      >
                                    </li>
                                  </ul>
                                  <div style="line-height: 1.5"></div>
                                  <div style="line-height: 1.5">
                                    <br />
                                  </div>
                                  <div id="legalbases" style="line-height: 1.5">
                                    <strong
                                      ><span style="font-size: 15px"
                                        ><span data-custom-class="heading_1"
                                          >3. WHAT LEGAL BASES DO WE RELY ON TO
                                          PROCESS YOUR INFORMATION?</span
                                        ></span
                                      ></strong
                                    >
                                  </div>
                                  <div style="line-height: 1.5">
                                    <br />
                                  </div>
                                  <div style="line-height: 1.5">
                                    <em
                                      ><span style="font-size: 15px"
                                        ><span data-custom-class="body_text"
                                          ><strong>In Short:</strong> We only
                                          process your personal information when
                                          we believe it is necessary and we have
                                          a valid legal reason (i.e., legal
                                          basis) to do so under applicable law,
                                          like with your consent, to comply with
                                          laws, to provide you with services to
                                          enter into or fulfill our contractual
                                          obligations, to protect your rights,
                                          or to fulfill our legitimate business
                                          interests.</span
                                        ></span
                                      ></em
                                    >
                                  </div>
                                  <div style="line-height: 1.5">
                                    <br />
                                  </div>
                                  <div style="line-height: 1.5">
                                    <em
                                      ><span style="font-size: 15px"
                                        ><span data-custom-class="body_text"
                                          ><strong
                                            ><u
                                              >If you are located in the EU or
                                              UK, this section applies to
                                              you.</u
                                            ></strong
                                          ></span
                                        ></span
                                      ></em
                                    >
                                  </div>
                                  <div style="line-height: 1.5">
                                    <br />
                                  </div>
                                  <div style="line-height: 1.5">
                                    <span style="font-size: 15px"
                                      ><span data-custom-class="body_text"
                                        >The General Data Protection Regulation
                                        (GDPR) and UK GDPR require us to explain
                                        the valid legal bases we rely on in
                                        order to process your personal
                                        information. As such, we may rely on the
                                        following legal bases to process your
                                        personal information:</span
                                      ></span
                                    >
                                  </div>
                                  <ul>
                                    <li style="line-height: 1.5">
                                      <span style="font-size: 15px"
                                        ><span data-custom-class="body_text"
                                          ><strong>Consent.</strong> We may
                                          process your information if you have
                                          given us permission (i.e. , consent)
                                          to use your personal information for a
                                          specific purpose. You can withdraw
                                          your consent at any time. Click</span
                                        ></span
                                      >
                                      <a
                                        data-custom-class="link"
                                        href="#withdrawconsent"
                                        ><span style="font-size: 15px"
                                          ><span data-custom-class="body_text"
                                            >here</span
                                          ></span
                                        ></a
                                      >
                                      <span style="font-size: 15px"
                                        ><span data-custom-class="body_text"
                                          >to learn more.</span
                                        ></span
                                      >
                                    </li>
                                  </ul>
                                  <div style="line-height: 1.5"></div>
                                  <ul>
                                    <li style="line-height: 1.5">
                                      <span data-custom-class="body_text"
                                        ><span style="font-size: 15px"
                                          ><strong
                                            >Performance of a Contract.</strong
                                          >
                                          We may process your personal
                                          information when we believe it is
                                          necessary to fulfill our contractual
                                          obligations to you, including
                                          providing our Services or at your
                                          request prior to entering into a
                                          contract with you.</span
                                        ></span
                                      >
                                    </li>
                                  </ul>
                                  <div style="line-height: 1.5"></div>
                                  <ul>
                                    <li style="line-height: 1.5">
                                      <span data-custom-class="body_text"
                                        ><span style="font-size: 15px"
                                          ><strong
                                            >Legitimate Interests.</strong
                                          >
                                          We may process your information when
                                          we believe it is reasonably necessary
                                          to achieve our legitimate business
                                          interests and those interests do not
                                          outweigh your interests and
                                          fundamental rights and freedoms. For
                                          example, we may process your personal
                                          information for some of the purposes
                                          described in order to:</span
                                        ></span
                                      >
                                    </li>
                                  </ul>
                                  <div style="line-height: 1.5"></div>
                                  <ul style="margin-left: 40px">
                                    <li style="line-height: 1.5">
                                      <span data-custom-class="body_text"
                                        ><span style="font-size: 15px"
                                          >Send users information about special
                                          offers and discounts on our products
                                          and services</span
                                        ></span
                                      >
                                    </li>
                                  </ul>
                                  <div style="line-height: 1.5"></div>
                                  <ul style="margin-left: 40px">
                                    <li style="line-height: 1.5">
                                      <span data-custom-class="body_text"
                                        ><span style="font-size: 15px"
                                          >Develop and display personalized and
                                          relevant advertising content for our
                                          users</span
                                        ></span
                                      >
                                    </li>
                                  </ul>
                                  <div style="line-height: 1.5"></div>
                                  <ul style="margin-left: 40px">
                                    <li style="line-height: 1.5">
                                      <span data-custom-class="body_text"
                                        ><span style="font-size: 15px"
                                          >Analyze how our Services are used so
                                          we can improve them to engage and
                                          retain users</span
                                        ></span
                                      >
                                    </li>
                                  </ul>
                                  <div style="line-height: 1.5"></div>
                                  <ul style="margin-left: 40px">
                                    <li style="line-height: 1.5">
                                      <span data-custom-class="body_text"
                                        ><span style="font-size: 15px"
                                          >Support our marketing
                                          activities</span
                                        ></span
                                      >
                                    </li>
                                  </ul>
                                  <div style="line-height: 1.5"></div>
                                  <ul style="margin-left: 40px">
                                    <li style="line-height: 1.5">
                                      <span data-custom-class="body_text"
                                        ><span style="font-size: 15px"
                                          >Diagnose problems and/or prevent
                                          fraudulent activities</span
                                        ></span
                                      >
                                    </li>
                                  </ul>
                                  <div style="line-height: 1.5"></div>
                                  <ul style="margin-left: 40px">
                                    <li style="line-height: 1.5">
                                      <span data-custom-class="body_text"
                                        ><span style="font-size: 15px"
                                          >Understand how our users use our
                                          products and services so we can
                                          improve user experience</span
                                        ></span
                                      >
                                    </li>
                                  </ul>
                                  <div style="line-height: 1.5"></div>
                                  <ul>
                                    <li style="line-height: 1.5">
                                      <span data-custom-class="body_text"
                                        ><span style="font-size: 15px"
                                          ><strong>Legal Obligations.</strong>
                                          We may process your information where
                                          we believe it is necessary for
                                          compliance with our legal obligations,
                                          such as to cooperate with a law
                                          enforcement body or regulatory agency,
                                          exercise or defend our legal rights,
                                          or disclose your information as
                                          evidence in litigation in which we are
                                          involved.<br /></span
                                      ></span>
                                    </li>
                                  </ul>
                                  <div style="line-height: 1.5"></div>
                                  <ul>
                                    <li style="line-height: 1.5">
                                      <span data-custom-class="body_text"
                                        ><span style="font-size: 15px"
                                          ><strong>Vital Interests.</strong> We
                                          may process your information where we
                                          believe it is necessary to protect
                                          your vital interests or the vital
                                          interests of a third party, such as
                                          situations involving potential threats
                                          to the safety of any person.</span
                                        ></span
                                      >
                                    </li>
                                  </ul>
                                  <div style="line-height: 1.5"></div>
                                  <div style="line-height: 1.5">
                                    <br />
                                  </div>
                                  <div style="line-height: 1.5">
                                    <span data-custom-class="body_text"
                                      ><span style="font-size: 15px"
                                        ><strong
                                          ><u
                                            ><em
                                              >If you are located in Canada,
                                              this section applies to you.</em
                                            ></u
                                          ></strong
                                        ></span
                                      ></span
                                    >
                                  </div>
                                  <div style="line-height: 1.5">
                                    <br />
                                  </div>
                                  <div style="line-height: 1.5">
                                    <span data-custom-class="body_text"
                                      ><span style="font-size: 15px"
                                        >We may process your information if you
                                        have given us specific permission (i.e.,
                                        express consent) to use your personal
                                        information for a specific purpose, or
                                        in situations where your permission can
                                        be inferred (i.e. , implied consent).
                                        You can withdraw your consent at any
                                        time. Click</span
                                      ></span
                                    >
                                    <a
                                      data-custom-class="link"
                                      href="#withdrawconsent"
                                      ><span data-custom-class="body_text"
                                        ><span style="font-size: 15px"
                                          >here</span
                                        ></span
                                      ></a
                                    >
                                    <span data-custom-class="body_text"
                                      ><span style="font-size: 15px"
                                        >to learn more.</span
                                      ></span
                                    >
                                  </div>
                                  <div style="line-height: 1.5">
                                    <br />
                                  </div>
                                  <div style="line-height: 1.5">
                                    <span data-custom-class="body_text"
                                      ><span style="font-size: 15px"
                                        >In some exceptional cases, we may be
                                        legally permitted under applicable law
                                        to process your information without your
                                        consent, including, for example:</span
                                      ></span
                                    >
                                  </div>
                                  <ul>
                                    <li style="line-height: 1.5">
                                      <span data-custom-class="body_text"
                                        ><span style="font-size: 15px"
                                          >If collection is clearly in the
                                          interests of an individual and consent
                                          cannot be obtained in a timely
                                          way</span
                                        ></span
                                      >
                                    </li>
                                  </ul>
                                  <div style="line-height: 1.5"></div>
                                  <ul>
                                    <li style="line-height: 1.5">
                                      <span data-custom-class="body_text"
                                        ><span style="font-size: 15px"
                                          >For investigations and fraud
                                          detection and prevention</span
                                        ></span
                                      >
                                    </li>
                                  </ul>
                                  <div style="line-height: 1.5"></div>
                                  <ul>
                                    <li style="line-height: 1.5">
                                      <span data-custom-class="body_text"
                                        ><span style="font-size: 15px"
                                          >For business transactions provided
                                          certain conditions are met</span
                                        ></span
                                      >
                                    </li>
                                  </ul>
                                  <div style="line-height: 1.5"></div>
                                  <ul>
                                    <li style="line-height: 1.5">
                                      <span data-custom-class="body_text"
                                        ><span style="font-size: 15px"
                                          >If it is contained in a witness
                                          statement and the collection is
                                          necessary to assess, process, or
                                          settle an insurance claim</span
                                        ></span
                                      >
                                    </li>
                                  </ul>
                                  <div style="line-height: 1.5"></div>
                                  <ul>
                                    <li style="line-height: 1.5">
                                      <span data-custom-class="body_text"
                                        ><span style="font-size: 15px"
                                          >For identifying injured, ill, or
                                          deceased persons and communicating
                                          with next of kin</span
                                        ></span
                                      >
                                    </li>
                                  </ul>
                                  <div style="line-height: 1.5"></div>
                                  <ul>
                                    <li style="line-height: 1.5">
                                      <span data-custom-class="body_text"
                                        ><span style="font-size: 15px"
                                          >If we have reasonable grounds to
                                          believe an individual has been, is, or
                                          may be victim of financial abuse</span
                                        ></span
                                      >
                                    </li>
                                  </ul>
                                  <div style="line-height: 1.5"></div>
                                  <ul>
                                    <li style="line-height: 1.5">
                                      <span data-custom-class="body_text"
                                        ><span style="font-size: 15px"
                                          >If it is reasonable to expect
                                          collection and use with consent would
                                          compromise the availability or the
                                          accuracy of the information and the
                                          collection is reasonable for purposes
                                          related to investigating a breach of
                                          an agreement or a contravention of the
                                          laws of Canada or a province</span
                                        ></span
                                      >
                                    </li>
                                  </ul>
                                  <div style="line-height: 1.5"></div>
                                  <ul>
                                    <li style="line-height: 1.5">
                                      <span data-custom-class="body_text"
                                        ><span style="font-size: 15px"
                                          >If disclosure is required to comply
                                          with a subpoena, warrant, court order,
                                          or rules of the court relating to the
                                          production of records</span
                                        ></span
                                      >
                                    </li>
                                  </ul>
                                  <div style="line-height: 1.5"></div>
                                  <ul>
                                    <li style="line-height: 1.5">
                                      <span style="font-size: 15px"
                                        ><span data-custom-class="body_text"
                                          >If it was produced by an individual
                                          in the course of their employment,
                                          business, or profession and the
                                          collection is consistent with the
                                          purposes for which the information was
                                          produced</span
                                        ></span
                                      >
                                    </li>
                                  </ul>
                                  <div style="line-height: 1.5"></div>
                                  <ul>
                                    <li style="line-height: 1.5">
                                      <span style="font-size: 15px"
                                        ><span data-custom-class="body_text"
                                          >If the collection is solely for
                                          journalistic, artistic, or literary
                                          purposes</span
                                        ></span
                                      >
                                    </li>
                                  </ul>
                                  <div style="line-height: 1.5"></div>
                                  <ul>
                                    <li style="line-height: 1.5">
                                      <span style="font-size: 15px"
                                        ><span data-custom-class="body_text"
                                          >If the information is publicly
                                          available and is specified by the
                                          regulations</span
                                        ></span
                                      >
                                    </li>
                                  </ul>
                                  <div style="line-height: 1.5"></div>
                                  <div style="line-height: 1.5">
                                    <br />
                                  </div>
                                  <div id="whoshare" style="line-height: 1.5">
                                    <span style="color: rgb(127, 127, 127)"
                                      ><span
                                        style="
                                          color: rgb(89, 89, 89);
                                          font-size: 15px;
                                        "
                                        ><span
                                          style="
                                            font-size: 15px;
                                            color: rgb(89, 89, 89);
                                          "
                                          ><span
                                            style="
                                              font-size: 15px;
                                              color: rgb(89, 89, 89);
                                            "
                                            ><span
                                              id="control"
                                              style="color: rgb(0, 0, 0)"
                                              ><strong
                                                ><span
                                                  data-custom-class="heading_1"
                                                  >4. WHEN AND WITH WHOM DO WE
                                                  SHARE YOUR PERSONAL
                                                  INFORMATION?</span
                                                ></strong
                                              ></span
                                            ></span
                                          ></span
                                        ></span
                                      ></span
                                    >
                                  </div>
                                  <div style="line-height: 1.5">
                                    <br />
                                  </div>
                                  <div style="line-height: 1.5">
                                    <span
                                      style="
                                        font-size: 15px;
                                        color: rgb(89, 89, 89);
                                      "
                                      ><span
                                        style="
                                          font-size: 15px;
                                          color: rgb(89, 89, 89);
                                        "
                                        ><span data-custom-class="body_text"
                                          ><strong><em>In Short:</em></strong>
                                          <em
                                            >We may share information in
                                            specific situations described in
                                            this section and/or with the
                                            following third parties.</em
                                          ></span
                                        ></span
                                      ></span
                                    >
                                  </div>
                                  <div style="line-height: 1.5"></div>
                                  <div style="line-height: 1.5">
                                    <br />
                                  </div>
                                  <div style="line-height: 1.5">
                                    <span style="font-size: 15px"
                                      ><span data-custom-class="body_text"
                                        >We may need to share your personal
                                        information in the following
                                        situations:</span
                                      ></span
                                    >
                                  </div>
                                  <ul>
                                    <li style="line-height: 1.5">
                                      <span style="font-size: 15px"
                                        ><span data-custom-class="body_text"
                                          ><strong>Business Transfers.</strong>
                                          We may share or transfer your
                                          information in connection with, or
                                          during negotiations of, any merger,
                                          sale of company assets, financing, or
                                          acquisition of all or a portion of our
                                          business to another company.</span
                                        ></span
                                      >
                                    </li>
                                  </ul>
                                  <div style="line-height: 1.5"></div>
                                  <ul>
                                    <li style="line-height: 1.5">
                                      <span style="font-size: 15px"
                                        ><span data-custom-class="body_text"
                                          ><strong
                                            >When we use Google Maps Platform
                                            APIs.</strong
                                          >
                                          We may share your information with
                                          certain Google Maps Platform APIs
                                          (e.g., Google Maps API, Places API).
                                          To find out more about Google’s
                                          Privacy Policy, please refer to
                                          this</span
                                        ></span
                                      >
                                      <a
                                        data-custom-class="link"
                                        href="https://policies.google.com/privacy"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        ><span style="font-size: 15px"
                                          ><span data-custom-class="body_text"
                                            >link</span
                                          ></span
                                        ></a
                                      ><span style="font-size: 15px"
                                        ><span data-custom-class="body_text"
                                          >. We obtain and store on your device
                                          ( "cache" ) your location. You may
                                          revoke your consent anytime by
                                          contacting us at the contact details
                                          provided at the end of this
                                          document.</span
                                        ></span
                                      >
                                    </li>
                                  </ul>
                                  <div style="line-height: 1.5">
                                    <div style="line-height: 1.5">
                                      <div style="line-height: 1.5">
                                        <div style="line-height: 1.5">
                                          <div style="line-height: 1.5"></div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div
                                            id="cookies"
                                            style="line-height: 1.5"
                                          >
                                            <span
                                              style="color: rgb(127, 127, 127)"
                                              ><span
                                                style="
                                                  color: rgb(89, 89, 89);
                                                  font-size: 15px;
                                                "
                                                ><span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      id="control"
                                                      style="
                                                        color: rgb(0, 0, 0);
                                                      "
                                                      ><strong
                                                        ><span
                                                          data-custom-class="heading_1"
                                                          >5. DO WE USE COOKIES
                                                          AND OTHER TRACKING
                                                          TECHNOLOGIES?</span
                                                        ></strong
                                                      ></span
                                                    ></span
                                                  ></span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  ><strong
                                                    ><em>In Short:</em></strong
                                                  >
                                                  <em
                                                    >We may use cookies and
                                                    other tracking technologies
                                                    to collect and store your
                                                    information.</em
                                                  ></span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  >We may use cookies and
                                                  similar tracking technologies
                                                  (like web beacons and pixels)
                                                  to access or store
                                                  information. Specific
                                                  information about how we use
                                                  such technologies and how you
                                                  can refuse certain cookies is
                                                  set out in our Cookie Notice
                                                  <span
                                                    style="
                                                      color: rgb(89, 89, 89);
                                                      font-size: 15px;
                                                    "
                                                    ><span
                                                      data-custom-class="body_text"
                                                      >.</span
                                                    >
                                                  </span></span
                                                >
                                              </span></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div
                                            id="sociallogins"
                                            style="line-height: 1.5"
                                          >
                                            <span
                                              style="color: rgb(127, 127, 127)"
                                              ><span
                                                style="
                                                  color: rgb(89, 89, 89);
                                                  font-size: 15px;
                                                "
                                                ><span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      id="control"
                                                      style="
                                                        color: rgb(0, 0, 0);
                                                      "
                                                      ><strong
                                                        ><span
                                                          data-custom-class="heading_1"
                                                          >6. HOW DO WE HANDLE
                                                          YOUR SOCIAL
                                                          LOGINS?</span
                                                        ></strong
                                                      ></span
                                                    ></span
                                                  ></span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  ><strong
                                                    ><em>In Short:</em></strong
                                                  >
                                                  <em
                                                    >If you choose to register
                                                    or log in to our Services
                                                    using a social media
                                                    account, we may have access
                                                    to certain information about
                                                    you.</em
                                                  ></span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  >Our Services offer you the
                                                  ability to register and log in
                                                  using your third-party social
                                                  media account details (like
                                                  your Facebook or Twitter
                                                  logins). Where you choose to
                                                  do this, we will receive
                                                  certain profile information
                                                  about you from your social
                                                  media provider. The profile
                                                  information we receive may
                                                  vary depending on the social
                                                  media provider concerned, but
                                                  will often include your name,
                                                  email address, friends list,
                                                  and profile picture, as well
                                                  as other information you
                                                  choose to make public on such
                                                  a social media platform.
                                                </span></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  >We will use the information
                                                  we receive only for the
                                                  purposes that are described in
                                                  this privacy notice or that
                                                  are otherwise made clear to
                                                  you on the relevant Services.
                                                  Please note that we do not
                                                  control, and are not
                                                  responsible for, other uses of
                                                  your personal information by
                                                  your third-party social media
                                                  provider. We recommend that
                                                  you review their privacy
                                                  notice to understand how they
                                                  collect, use, and share your
                                                  personal information, and how
                                                  you can set your privacy
                                                  preferences on their sites and
                                                  apps.
                                                  <span
                                                    style="
                                                      color: rgb(89, 89, 89);
                                                      font-size: 15px;
                                                    "
                                                    ><span
                                                      style="
                                                        color: rgb(89, 89, 89);
                                                        font-size: 15px;
                                                      "
                                                      ><span
                                                        style="
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                          font-size: 15px;
                                                        "
                                                        ><span
                                                          style="
                                                            color: rgb(
                                                              89,
                                                              89,
                                                              89
                                                            );
                                                            font-size: 15px;
                                                          "
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                            "
                                                            ><span
                                                              style="
                                                                color: rgb(
                                                                  89,
                                                                  89,
                                                                  89
                                                                );
                                                              "
                                                              ><span
                                                                style="
                                                                  font-size: 15px;
                                                                "
                                                                ><span
                                                                  style="
                                                                    color: rgb(
                                                                      89,
                                                                      89,
                                                                      89
                                                                    );
                                                                  "
                                                                >
                                                                </span></span></span></span></span></span></span></span></span></span
                                            ></span>
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div
                                            id="intltransfers"
                                            style="line-height: 1.5"
                                          >
                                            <span
                                              style="color: rgb(127, 127, 127)"
                                              ><span
                                                style="
                                                  color: rgb(89, 89, 89);
                                                  font-size: 15px;
                                                "
                                                ><span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      id="control"
                                                      style="
                                                        color: rgb(0, 0, 0);
                                                      "
                                                      ><strong
                                                        ><span
                                                          data-custom-class="heading_1"
                                                          >7. IS YOUR
                                                          INFORMATION
                                                          TRANSFERRED
                                                          INTERNATIONALLY?</span
                                                        ></strong
                                                      ></span
                                                    ></span
                                                  ></span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  ><strong
                                                    ><em>In Short:</em></strong
                                                  >
                                                  <em
                                                    >We may transfer, store, and
                                                    process your information in
                                                    countries other than your
                                                    own.</em
                                                  ></span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  >Our servers are located in
                                                  <span style="font-size: 15px"
                                                    ><span
                                                      style="
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        data-custom-class="body_text"
                                                        ><span
                                                          style="
                                                            font-size: 15px;
                                                            color: rgb(
                                                              89,
                                                              89,
                                                              89
                                                            );
                                                          "
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              ><span
                                                                style="
                                                                  font-size: 15px;
                                                                "
                                                                ><span
                                                                  style="
                                                                    color: rgb(
                                                                      89,
                                                                      89,
                                                                      89
                                                                    );
                                                                  "
                                                                  ><span
                                                                    data-custom-class="body_text"
                                                                    >India
                                                                  </span></span
                                                                ></span
                                                              ></span
                                                            ></span
                                                          ></span
                                                        >
                                                        <span
                                                          style="
                                                            font-size: 15px;
                                                            color: rgb(
                                                              89,
                                                              89,
                                                              89
                                                            );
                                                          "
                                                          ><span
                                                            style="
                                                              font-size: 15px;
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              ><span
                                                                style="
                                                                  font-size: 15px;
                                                                "
                                                                ><span
                                                                  style="
                                                                    color: rgb(
                                                                      89,
                                                                      89,
                                                                      89
                                                                    );
                                                                  "
                                                                  ><span
                                                                    data-custom-class="body_text"
                                                                  >
                                                                    , and United
                                                                    States
                                                                  </span></span
                                                                ></span
                                                              ></span
                                                            ></span
                                                          ></span
                                                        ></span
                                                      ></span
                                                    ></span
                                                  >
                                                  <span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        data-custom-class="body_text"
                                                        >. If you are accessing
                                                        our Services from
                                                        outside</span
                                                      >
                                                      <span
                                                        data-custom-class="body_text"
                                                        ><span
                                                          style="
                                                            font-size: 15px;
                                                          "
                                                          ><span
                                                            style="
                                                              color: rgb(
                                                                89,
                                                                89,
                                                                89
                                                              );
                                                            "
                                                            ><span
                                                              data-custom-class="body_text"
                                                              ><span
                                                                style="
                                                                  font-size: 15px;
                                                                  color: rgb(
                                                                    89,
                                                                    89,
                                                                    89
                                                                  );
                                                                "
                                                                ><span
                                                                  style="
                                                                    font-size: 15px;
                                                                    color: rgb(
                                                                      89,
                                                                      89,
                                                                      89
                                                                    );
                                                                  "
                                                                  ><span
                                                                    data-custom-class="body_text"
                                                                    ><span
                                                                      style="
                                                                        font-size: 15px;
                                                                      "
                                                                      ><span
                                                                        style="
                                                                          color: rgb(
                                                                            89,
                                                                            89,
                                                                            89
                                                                          );
                                                                        "
                                                                        ><span
                                                                          data-custom-class="body_text"
                                                                          >India
                                                                        </span></span
                                                                      ></span
                                                                    ></span
                                                                  ></span
                                                                ></span
                                                              >
                                                              <span
                                                                style="
                                                                  font-size: 15px;
                                                                  color: rgb(
                                                                    89,
                                                                    89,
                                                                    89
                                                                  );
                                                                "
                                                                ><span
                                                                  style="
                                                                    font-size: 15px;
                                                                    color: rgb(
                                                                      89,
                                                                      89,
                                                                      89
                                                                    );
                                                                  "
                                                                  ><span
                                                                    data-custom-class="body_text"
                                                                    ><span
                                                                      style="
                                                                        font-size: 15px;
                                                                      "
                                                                      ><span
                                                                        style="
                                                                          color: rgb(
                                                                            89,
                                                                            89,
                                                                            89
                                                                          );
                                                                        "
                                                                        ><span
                                                                          data-custom-class="body_text"
                                                                        >
                                                                          , and
                                                                          United
                                                                          States
                                                                        </span></span
                                                                      ></span
                                                                    ></span
                                                                  ></span
                                                                ></span
                                                              ></span
                                                            ></span
                                                          ></span
                                                        ></span
                                                      >
                                                      <span
                                                        data-custom-class="body_text"
                                                        >, please be aware that
                                                        your information may be
                                                        transferred to, stored,
                                                        and processed by us in
                                                        our facilities and by
                                                        those third parties with
                                                        whom we may share your
                                                        personal information
                                                        (see "</span
                                                      ></span
                                                    ></span
                                                  >
                                                  <a
                                                    data-custom-class="link"
                                                    href="#whoshare"
                                                    ><span
                                                      style="font-size: 15px"
                                                      ><span
                                                        style="
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        >WHEN AND WITH WHOM DO
                                                        WE SHARE YOUR PERSONAL
                                                        INFORMATION?</span
                                                      ></span
                                                    ></a
                                                  >
                                                  <span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        data-custom-class="body_text"
                                                        >" above), in</span
                                                      ></span
                                                    ></span
                                                  >
                                                  <span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        data-custom-class="body_text"
                                                        >and other
                                                        countries.</span
                                                      ></span
                                                    ></span
                                                  ></span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  >If you are a resident in the
                                                  European Economic Area (EEA)
                                                  or United Kingdom (UK), then
                                                  these countries may not
                                                  necessarily have data
                                                  protection laws or other
                                                  similar laws as comprehensive
                                                  as those in your country.
                                                  However, we will take all
                                                  necessary measures to protect
                                                  your personal information in
                                                  accordance with this privacy
                                                  notice and applicable law.
                                                </span></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div
                                            id="inforetain"
                                            style="line-height: 1.5"
                                          >
                                            <span
                                              style="color: rgb(127, 127, 127)"
                                              ><span
                                                style="
                                                  color: rgb(89, 89, 89);
                                                  font-size: 15px;
                                                "
                                                ><span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      id="control"
                                                      style="
                                                        color: rgb(0, 0, 0);
                                                      "
                                                      ><strong
                                                        ><span
                                                          data-custom-class="heading_1"
                                                          >8. HOW LONG DO WE
                                                          KEEP YOUR
                                                          INFORMATION?</span
                                                        ></strong
                                                      ></span
                                                    ></span
                                                  ></span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  ><strong
                                                    ><em>In Short:</em></strong
                                                  >
                                                  <em
                                                    >We keep your information
                                                    for as long as necessary to
                                                    fulfill the purposes
                                                    outlined in this privacy
                                                    notice unless otherwise
                                                    required by law.</em
                                                  ></span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  >We will only keep your
                                                  personal information for as
                                                  long as it is necessary for
                                                  the purposes set out in this
                                                  privacy notice, unless a
                                                  longer retention period is
                                                  required or permitted by law
                                                  (such as tax, accounting, or
                                                  other legal requirements). No
                                                  purpose in this notice will
                                                  require us keeping your
                                                  personal information for
                                                  longer than thirty six (36)
                                                  months past the termination of
                                                  the user's account .</span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  >When we have no ongoing
                                                  legitimate business need to
                                                  process your personal
                                                  information, we will either
                                                  delete or anonymize such
                                                  information, or, if this is
                                                  not possible (for example,
                                                  because your personal
                                                  information has been stored in
                                                  backup archives), then we will
                                                  securely store your personal
                                                  information and isolate it
                                                  from any further processing
                                                  until deletion is possible.
                                                </span></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div
                                            id="infosafe"
                                            style="line-height: 1.5"
                                          >
                                            <span
                                              style="color: rgb(127, 127, 127)"
                                              ><span
                                                style="
                                                  color: rgb(89, 89, 89);
                                                  font-size: 15px;
                                                "
                                                ><span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      id="control"
                                                      style="
                                                        color: rgb(0, 0, 0);
                                                      "
                                                      ><strong
                                                        ><span
                                                          data-custom-class="heading_1"
                                                          >9. HOW DO WE KEEP
                                                          YOUR INFORMATION
                                                          SAFE?</span
                                                        ></strong
                                                      ></span
                                                    ></span
                                                  ></span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  ><strong
                                                    ><em>In Short:</em></strong
                                                  >
                                                  <em
                                                    >We aim to protect your
                                                    personal information through
                                                    a system of organizational
                                                    and technical security
                                                    measures.</em
                                                  ></span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  >We have implemented
                                                  appropriate and reasonable
                                                  technical and organizational
                                                  security measures designed to
                                                  protect the security of any
                                                  personal information we
                                                  process. However, despite our
                                                  safeguards and efforts to
                                                  secure your information, no
                                                  electronic transmission over
                                                  the Internet or information
                                                  storage technology can be
                                                  guaranteed to be 100% secure,
                                                  so we cannot promise or
                                                  guarantee that hackers,
                                                  cybercriminals, or other
                                                  unauthorized third parties
                                                  will not be able to defeat our
                                                  security and improperly
                                                  collect, access, steal, or
                                                  modify your information.
                                                  Although we will do our best
                                                  to protect your personal
                                                  information, transmission of
                                                  personal information to and
                                                  from our Services is at your
                                                  own risk. You should only
                                                  access the Services within a
                                                  secure environment.
                                                </span></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div
                                            id="infominors"
                                            style="line-height: 1.5"
                                          >
                                            <span
                                              style="color: rgb(127, 127, 127)"
                                              ><span
                                                style="
                                                  color: rgb(89, 89, 89);
                                                  font-size: 15px;
                                                "
                                                ><span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      id="control"
                                                      style="
                                                        color: rgb(0, 0, 0);
                                                      "
                                                      ><strong
                                                        ><span
                                                          data-custom-class="heading_1"
                                                          >10. DO WE COLLECT
                                                          INFORMATION FROM
                                                          MINORS?</span
                                                        ></strong
                                                      ></span
                                                    ></span
                                                  ></span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  ><strong
                                                    ><em>In Short:</em></strong
                                                  >
                                                  <em
                                                    >We do not knowingly collect
                                                    data from or market to
                                                    children under 18 years of
                                                    age.</em
                                                  ></span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  >We do not knowingly solicit
                                                  data from or market to
                                                  children under 18 years of
                                                  age. By using the Services,
                                                  you represent that you are at
                                                  least 18 or that you are the
                                                  parent or guardian of such a
                                                  minor and consent to such
                                                  minor dependent’s use of the
                                                  Services. If we learn that
                                                  personal information from
                                                  users less than 18 years of
                                                  age has been collected, we
                                                  will deactivate the account
                                                  and take reasonable measures
                                                  to promptly delete such data
                                                  from our records. If you
                                                  become aware of any data we
                                                  may have collected from
                                                  children under age 18, please
                                                  contact us at
                                                  <span
                                                    style="
                                                      color: rgb(89, 89, 89);
                                                      font-size: 15px;
                                                    "
                                                    ><span
                                                      data-custom-class="body_text"
                                                      >hello@goaround.me</span
                                                    ></span
                                                  >
                                                  .
                                                </span></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div
                                            id="privacyrights"
                                            style="line-height: 1.5"
                                          >
                                            <span
                                              style="color: rgb(127, 127, 127)"
                                              ><span
                                                style="
                                                  color: rgb(89, 89, 89);
                                                  font-size: 15px;
                                                "
                                                ><span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      id="control"
                                                      style="
                                                        color: rgb(0, 0, 0);
                                                      "
                                                      ><strong
                                                        ><span
                                                          data-custom-class="heading_1"
                                                          >11. WHAT ARE YOUR
                                                          PRIVACY RIGHTS?</span
                                                        ></strong
                                                      ></span
                                                    ></span
                                                  ></span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  ><strong
                                                    ><em>In Short:</em></strong
                                                  >
                                                  <em>
                                                    In some regions, such as the
                                                    European Economic Area
                                                    (EEA), United Kingdom (UK),
                                                    and Canada , you have rights
                                                    that allow you greater
                                                    access to and control over
                                                    your personal information.
                                                    You may review, change, or
                                                    terminate your account at
                                                    any time.</em
                                                  >
                                                </span></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  >In some regions (like the
                                                  EEA, UK, and Canada ), you
                                                  have certain rights under
                                                  applicable data protection
                                                  laws. These may include the
                                                  right (i) to request access
                                                  and obtain a copy of your
                                                  personal information, (ii) to
                                                  request rectification or
                                                  erasure; (iii) to restrict the
                                                  processing of your personal
                                                  information; and (iv) if
                                                  applicable, to data
                                                  portability. In certain
                                                  circumstances, you may also
                                                  have the right to object to
                                                  the processing of your
                                                  personal information. You can
                                                  make such a request by
                                                  contacting us by using the
                                                  contact details provided in
                                                  the section "</span
                                                ></span
                                              ></span
                                            >
                                            <a
                                              data-custom-class="link"
                                              href="#contact"
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    data-custom-class="body_text"
                                                    >HOW CAN YOU CONTACT US
                                                    ABOUT THIS NOTICE?</span
                                                  ></span
                                                ></span
                                              ></a
                                            >
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  >" below.</span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  >We will consider and act upon
                                                  any request in accordance with
                                                  applicable data protection
                                                  laws.</span
                                                >
                                              </span></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5"></div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  >If you are located in the EEA
                                                  or UK and you believe we are
                                                  unlawfully processing your
                                                  personal information, you also
                                                  have the right to complain to
                                                  your local data protection
                                                  supervisory authority. You can
                                                  find their contact details
                                                  here:
                                                  <span style="font-size: 15px"
                                                    ><span
                                                      style="
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        data-custom-class="body_text"
                                                        ><span
                                                          style="
                                                            color: rgb(
                                                              48,
                                                              48,
                                                              241
                                                            );
                                                          "
                                                          ><span
                                                            data-custom-class="body_text"
                                                            ><a
                                                              data-custom-class="link"
                                                              href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                                              rel="noopener noreferrer"
                                                              target="_blank"
                                                              ><span
                                                                style="
                                                                  font-size: 15px;
                                                                "
                                                                >https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</span
                                                              ></a
                                                            ></span
                                                          ></span
                                                        ></span
                                                      ></span
                                                    ></span
                                                  >.</span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  >If you are located in
                                                  Switzerland, the contact
                                                  details for the data
                                                  protection authorities are
                                                  available here:
                                                  <span style="font-size: 15px"
                                                    ><span
                                                      style="
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        data-custom-class="body_text"
                                                        ><span
                                                          style="
                                                            color: rgb(
                                                              48,
                                                              48,
                                                              241
                                                            );
                                                          "
                                                          ><span
                                                            data-custom-class="body_text"
                                                            ><span
                                                              style="
                                                                font-size: 15px;
                                                              "
                                                              ><a
                                                                data-custom-class="link"
                                                                href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                                                                rel="noopener noreferrer"
                                                                target="_blank"
                                                                >https://www.edoeb.admin.ch/edoeb/en/home.html</a
                                                              ></span
                                                            ></span
                                                          ></span
                                                        ></span
                                                      ></span
                                                    ></span
                                                  >.</span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div
                                            id="withdrawconsent"
                                            style="line-height: 1.5"
                                          >
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  ><strong
                                                    ><u
                                                      >Withdrawing your
                                                      consent:</u
                                                    ></strong
                                                  >
                                                  If we are relying on your
                                                  consent to process your
                                                  personal information, which
                                                  may be express and/or implied
                                                  consent depending on the
                                                  applicable law, you have the
                                                  right to withdraw your consent
                                                  at any time. You can withdraw
                                                  your consent at any time by
                                                  contacting us by using the
                                                  contact details provided in
                                                  the section "</span
                                                ></span
                                              ></span
                                            >
                                            <a
                                              data-custom-class="link"
                                              href="#contact"
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    data-custom-class="body_text"
                                                    >HOW CAN YOU CONTACT US
                                                    ABOUT THIS NOTICE?</span
                                                  ></span
                                                ></span
                                              ></a
                                            >
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  >" below or updating your
                                                  preferences .</span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span style="font-size: 15px"
                                              ><span
                                                data-custom-class="body_text"
                                                >However, please note that this
                                                will not affect the lawfulness
                                                of the processing before its
                                                withdrawal nor, when applicable
                                                law allows, will it affect the
                                                processing of your personal
                                                information conducted in
                                                reliance on lawful processing
                                                grounds other than
                                                consent.</span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span style="font-size: 15px"
                                              ><span
                                                data-custom-class="body_text"
                                                ><strong
                                                  ><u
                                                    >Opting out of marketing and
                                                    promotional
                                                    communications:</u
                                                  ></strong
                                                >
                                                You can unsubscribe from our
                                                marketing and promotional
                                                communications at any time by
                                                clicking on the unsubscribe link
                                                in the emails that we send, or
                                                by contacting us using the
                                                details provided in the section
                                                "</span
                                              ></span
                                            >
                                            <a
                                              data-custom-class="link"
                                              href="#contact"
                                              ><span style="font-size: 15px"
                                                ><span
                                                  data-custom-class="body_text"
                                                  >HOW CAN YOU CONTACT US ABOUT
                                                  THIS NOTICE?</span
                                                ></span
                                              ></a
                                            >
                                            <span style="font-size: 15px"
                                              ><span
                                                data-custom-class="body_text"
                                                >" below. You will then be
                                                removed from the marketing
                                                lists. However, we may still
                                                communicate with you — for
                                                example, to send you
                                                service-related messages that
                                                are necessary for the
                                                administration and use of your
                                                account, to respond to service
                                                requests, or for other
                                                non-marketing purposes.</span
                                              >
                                            </span>
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span style="font-size: 15px"
                                              ><span
                                                data-custom-class="heading_2"
                                                ><strong
                                                  >Account Information</strong
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span data-custom-class="body_text"
                                              ><span style="font-size: 15px"
                                                >If you would at any time like
                                                to review or change the
                                                information in your account or
                                                terminate your account, you
                                                can:</span
                                              ></span
                                            >
                                          </div>
                                          <ul>
                                            <li style="line-height: 1.5">
                                              <span
                                                data-custom-class="body_text"
                                                ><span style="font-size: 15px"
                                                  >Log in to your account
                                                  settings and update your user
                                                  account.</span
                                                ></span
                                              >
                                            </li>
                                          </ul>
                                          <div style="line-height: 1.5"></div>
                                          <ul>
                                            <li style="line-height: 1.5">
                                              <span
                                                data-custom-class="body_text"
                                                ><span style="font-size: 15px"
                                                  >Contact us using the contact
                                                  information provided.</span
                                                ></span
                                              >
                                            </li>
                                          </ul>
                                          <div style="line-height: 1.5"></div>
                                          <div style="line-height: 1.5">
                                            <span style="font-size: 15px"
                                              ><span
                                                data-custom-class="body_text"
                                                >Upon your request to terminate
                                                your account, we will deactivate
                                                or delete your account and
                                                information from our active
                                                databases. However, we may
                                                retain some information in our
                                                files to prevent fraud,
                                                troubleshoot problems, assist
                                                with any investigations, enforce
                                                our legal terms and/or comply
                                                with applicable legal
                                                requirements.</span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  ><strong
                                                    ><u
                                                      >Cookies and similar
                                                      technologies:</u
                                                    ></strong
                                                  >
                                                  Most Web browsers are set to
                                                  accept cookies by default. If
                                                  you prefer, you can usually
                                                  choose to set your browser to
                                                  remove cookies and to reject
                                                  cookies. If you choose to
                                                  remove cookies or reject
                                                  cookies, this could affect
                                                  certain features or services
                                                  of our Services. To opt out of
                                                  interest-based advertising by
                                                  advertisers on our Services
                                                  visit
                                                  <span
                                                    style="
                                                      color: rgb(48, 48, 241);
                                                    "
                                                    ><span
                                                      data-custom-class="body_text"
                                                      ><a
                                                        data-custom-class="link"
                                                        href="http://www.aboutads.info/choices/"
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                        ><span
                                                          style="
                                                            font-size: 15px;
                                                          "
                                                          >http://www.aboutads.info/choices/</span
                                                        ></a
                                                      ></span
                                                    ></span
                                                  >.
                                                </span></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span data-custom-class="body_text"
                                              ><span style="font-size: 15px"
                                                >If you have questions or
                                                comments about your privacy
                                                rights, you may email us at
                                                hello@goaround.me .</span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div
                                            id="DNT"
                                            style="line-height: 1.5"
                                          >
                                            <span
                                              style="color: rgb(127, 127, 127)"
                                              ><span
                                                style="
                                                  color: rgb(89, 89, 89);
                                                  font-size: 15px;
                                                "
                                                ><span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      id="control"
                                                      style="
                                                        color: rgb(0, 0, 0);
                                                      "
                                                      ><strong
                                                        ><span
                                                          data-custom-class="heading_1"
                                                          >12. CONTROLS FOR
                                                          DO-NOT-TRACK
                                                          FEATURES</span
                                                        ></strong
                                                      ></span
                                                    ></span
                                                  ></span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  >Most web browsers and some
                                                  mobile operating systems and
                                                  mobile applications include a
                                                  Do-Not-Track ( "DNT" ) feature
                                                  or setting you can activate to
                                                  signal your privacy preference
                                                  not to have data about your
                                                  online browsing activities
                                                  monitored and collected. At
                                                  this stage no uniform
                                                  technology standard for
                                                  recognizing and implementing
                                                  DNT signals has been finalized
                                                  . As such, we do not currently
                                                  respond to DNT browser signals
                                                  or any other mechanism that
                                                  automatically communicates
                                                  your choice not to be tracked
                                                  online. If a standard for
                                                  online tracking is adopted
                                                  that we must follow in the
                                                  future, we will inform you
                                                  about that practice in a
                                                  revised version of this
                                                  privacy notice.</span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div
                                            id="caresidents"
                                            style="line-height: 1.5"
                                          >
                                            <span
                                              style="color: rgb(127, 127, 127)"
                                              ><span
                                                style="
                                                  color: rgb(89, 89, 89);
                                                  font-size: 15px;
                                                "
                                                ><span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      id="control"
                                                      style="
                                                        color: rgb(0, 0, 0);
                                                      "
                                                      ><strong
                                                        ><span
                                                          data-custom-class="heading_1"
                                                          >13. DO CALIFORNIA
                                                          RESIDENTS HAVE
                                                          SPECIFIC PRIVACY
                                                          RIGHTS?</span
                                                        ></strong
                                                      ></span
                                                    ></span
                                                  ></span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  ><strong
                                                    ><em>In Short:</em></strong
                                                  >
                                                  <em
                                                    >Yes, if you are a resident
                                                    of California, you are
                                                    granted specific rights
                                                    regarding access to your
                                                    personal information.</em
                                                  ></span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  >California Civil Code Section
                                                  1798.83, also known as the
                                                  "Shine The Light" law, permits
                                                  our users who are California
                                                  residents to request and
                                                  obtain from us, once a year
                                                  and free of charge,
                                                  information about categories
                                                  of personal information (if
                                                  any) we disclosed to third
                                                  parties for direct marketing
                                                  purposes and the names and
                                                  addresses of all third parties
                                                  with which we shared personal
                                                  information in the immediately
                                                  preceding calendar year. If
                                                  you are a California resident
                                                  and would like to make such a
                                                  request, please submit your
                                                  request in writing to us using
                                                  the contact information
                                                  provided below.</span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  >If you are under 18 years of
                                                  age, reside in California, and
                                                  have a registered account with
                                                  Services, you have the right
                                                  to request removal of unwanted
                                                  data that you publicly post on
                                                  the Services. To request
                                                  removal of such data, please
                                                  contact us using the contact
                                                  information provided below and
                                                  include the email address
                                                  associated with your account
                                                  and a statement that you
                                                  reside in California. We will
                                                  make sure the data is not
                                                  publicly displayed on the
                                                  Services, but please be aware
                                                  that the data may not be
                                                  completely or comprehensively
                                                  removed from all our systems
                                                  (e.g. , backups, etc.).
                                                </span></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              data-custom-class="heading_2"
                                              style="color: rgb(0, 0, 0)"
                                              ><span style="font-size: 15px"
                                                ><strong
                                                  >CCPA Privacy Notice</strong
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div>
                                            <div>
                                              <br />
                                            </div>
                                            <div style="line-height: 1.5">
                                              <span
                                                style="
                                                  color: rgb(127, 127, 127);
                                                "
                                                ><span
                                                  style="
                                                    color: rgb(89, 89, 89);
                                                    font-size: 15px;
                                                  "
                                                  ><span
                                                    data-custom-class="body_text"
                                                    ><span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          data-custom-class="body_text"
                                                          >The California Code
                                                          of Regulations defines
                                                          a "resident" as:</span
                                                        ></span
                                                      ></span
                                                    ></span
                                                  ></span
                                                ></span
                                              >
                                            </div>
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div
                                            style="
                                              line-height: 1.5;
                                              margin-left: 20px;
                                            "
                                          >
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  >(1) every individual who is
                                                  in the State of California for
                                                  other than a temporary or
                                                  transitory purpose and</span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div
                                            style="
                                              line-height: 1.5;
                                              margin-left: 20px;
                                            "
                                          >
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  >(2) every individual who is
                                                  domiciled in the State of
                                                  California who is outside the
                                                  State of California for a
                                                  temporary or transitory
                                                  purpose</span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  >All other individuals are
                                                  defined as
                                                  "non-residents."</span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  >If this definition of
                                                  "resident" applies to you, we
                                                  must adhere to certain rights
                                                  and obligations regarding your
                                                  personal information.</span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  ><strong
                                                    >What categories of personal
                                                    information do we
                                                    collect?</strong
                                                  ></span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  >We have collected the
                                                  following categories of
                                                  personal information in the
                                                  past twelve (12) months:
                                                </span></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <table style="width: 100%">
                                            <tbody>
                                              <tr>
                                                <td
                                                  style="
                                                    width: 33.8274%;
                                                    border-left: 1px solid black;
                                                    border-right: 1px solid
                                                      black;
                                                    border-top: 1px solid black;
                                                  "
                                                >
                                                  <span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        data-custom-class="body_text"
                                                        ><strong
                                                          >Category</strong
                                                        ></span
                                                      ></span
                                                    ></span
                                                  >
                                                </td>
                                                <td
                                                  style="
                                                    width: 51.4385%;
                                                    border-top: 1px solid black;
                                                    border-right: 1px solid
                                                      black;
                                                  "
                                                >
                                                  <span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        data-custom-class="body_text"
                                                        ><strong
                                                          >Examples</strong
                                                        ></span
                                                      ></span
                                                    ></span
                                                  >
                                                </td>
                                                <td
                                                  style="
                                                    width: 14.9084%;
                                                    border-right: 1px solid
                                                      black;
                                                    border-top: 1px solid black;
                                                    text-align: center;
                                                  "
                                                >
                                                  <span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        data-custom-class="body_text"
                                                        ><strong
                                                          >Collected</strong
                                                        ></span
                                                      ></span
                                                    ></span
                                                  >
                                                </td>
                                              </tr>
                                              <tr>
                                                <td
                                                  style="
                                                    width: 33.8274%;
                                                    border-left: 1px solid black;
                                                    border-right: 1px solid
                                                      black;
                                                    border-top: 1px solid black;
                                                  "
                                                >
                                                  <div style="line-height: 1.5">
                                                    <span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          data-custom-class="body_text"
                                                          >A. Identifiers</span
                                                        ></span
                                                      ></span
                                                    >
                                                  </div>
                                                </td>
                                                <td
                                                  style="
                                                    width: 51.4385%;
                                                    border-top: 1px solid black;
                                                    border-right: 1px solid
                                                      black;
                                                  "
                                                >
                                                  <div style="line-height: 1.5">
                                                    <span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          data-custom-class="body_text"
                                                          >Contact details, such
                                                          as real name, alias,
                                                          postal address,
                                                          telephone or mobile
                                                          contact number, unique
                                                          personal identifier,
                                                          online identifier,
                                                          Internet Protocol
                                                          address, email
                                                          address, and account
                                                          name</span
                                                        ></span
                                                      ></span
                                                    >
                                                  </div>
                                                </td>
                                                <td
                                                  style="
                                                    width: 14.9084%;
                                                    text-align: center;
                                                    vertical-align: middle;
                                                    border-right: 1px solid
                                                      black;
                                                    border-top: 1px solid black;
                                                  "
                                                >
                                                  <div style="line-height: 1.5">
                                                    <br />
                                                  </div>
                                                  <div style="line-height: 1.5">
                                                    <span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          data-custom-class="body_text"
                                                          >YES</span
                                                        ></span
                                                      ></span
                                                    >
                                                  </div>
                                                  <div style="line-height: 1.5">
                                                    <br />
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td
                                                  style="
                                                    width: 33.8274%;
                                                    border-left: 1px solid black;
                                                    border-right: 1px solid
                                                      black;
                                                    border-top: 1px solid black;
                                                  "
                                                >
                                                  <div style="line-height: 1.5">
                                                    <span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          data-custom-class="body_text"
                                                          >B. Personal
                                                          information categories
                                                          listed in the
                                                          California Customer
                                                          Records statute</span
                                                        ></span
                                                      ></span
                                                    >
                                                  </div>
                                                </td>
                                                <td
                                                  style="
                                                    width: 51.4385%;
                                                    border-right: 1px solid
                                                      black;
                                                    border-top: 1px solid black;
                                                  "
                                                >
                                                  <div style="line-height: 1.5">
                                                    <span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          data-custom-class="body_text"
                                                          >Name, contact
                                                          information,
                                                          education, employment,
                                                          employment history,
                                                          and financial
                                                          information</span
                                                        ></span
                                                      ></span
                                                    >
                                                  </div>
                                                </td>
                                                <td
                                                  style="
                                                    width: 14.9084%;
                                                    text-align: center;
                                                    border-right: 1px solid
                                                      black;
                                                    border-top: 1px solid black;
                                                  "
                                                >
                                                  <div style="line-height: 1.5">
                                                    <br />
                                                  </div>
                                                  <div style="line-height: 1.5">
                                                    <span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          data-custom-class="body_text"
                                                          >NO</span
                                                        ></span
                                                      ></span
                                                    >
                                                  </div>
                                                  <div style="line-height: 1.5">
                                                    <br />
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td
                                                  style="
                                                    width: 33.8274%;
                                                    border-left: 1px solid black;
                                                    border-right: 1px solid
                                                      black;
                                                    border-top: 1px solid black;
                                                  "
                                                >
                                                  <div style="line-height: 1.5">
                                                    <span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          data-custom-class="body_text"
                                                          >C. Protected
                                                          classification
                                                          characteristics under
                                                          California or federal
                                                          law</span
                                                        ></span
                                                      ></span
                                                    >
                                                  </div>
                                                </td>
                                                <td
                                                  style="
                                                    width: 51.4385%;
                                                    border-right: 1px solid
                                                      black;
                                                    border-top: 1px solid black;
                                                  "
                                                >
                                                  <div style="line-height: 1.5">
                                                    <span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          data-custom-class="body_text"
                                                          >Gender and date of
                                                          birth</span
                                                        ></span
                                                      ></span
                                                    >
                                                  </div>
                                                </td>
                                                <td
                                                  style="
                                                    width: 14.9084%;
                                                    text-align: center;
                                                    border-right: 1px solid
                                                      black;
                                                    border-top: 1px solid black;
                                                  "
                                                >
                                                  <div style="line-height: 1.5">
                                                    <br />
                                                  </div>
                                                  <div style="line-height: 1.5">
                                                    <span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          data-custom-class="body_text"
                                                          >NO</span
                                                        ></span
                                                      ></span
                                                    >
                                                  </div>
                                                  <div style="line-height: 1.5">
                                                    <br />
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td
                                                  style="
                                                    width: 33.8274%;
                                                    border-left: 1px solid black;
                                                    border-right: 1px solid
                                                      black;
                                                    border-top: 1px solid black;
                                                  "
                                                >
                                                  <div style="line-height: 1.5">
                                                    <span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          data-custom-class="body_text"
                                                          >D. Commercial
                                                          information</span
                                                        ></span
                                                      ></span
                                                    >
                                                  </div>
                                                </td>
                                                <td
                                                  style="
                                                    width: 51.4385%;
                                                    border-right: 1px solid
                                                      black;
                                                    border-top: 1px solid black;
                                                  "
                                                >
                                                  <div style="line-height: 1.5">
                                                    <span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          data-custom-class="body_text"
                                                          >Transaction
                                                          information, purchase
                                                          history, financial
                                                          details, and payment
                                                          information</span
                                                        ></span
                                                      ></span
                                                    >
                                                  </div>
                                                </td>
                                                <td
                                                  style="
                                                    width: 14.9084%;
                                                    text-align: center;
                                                    border-right: 1px solid
                                                      black;
                                                    border-top: 1px solid black;
                                                  "
                                                >
                                                  <div style="line-height: 1.5">
                                                    <br />
                                                  </div>
                                                  <div style="line-height: 1.5">
                                                    <span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          data-custom-class="body_text"
                                                          >NO</span
                                                        ></span
                                                      ></span
                                                    >
                                                  </div>
                                                  <div style="line-height: 1.5">
                                                    <br />
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td
                                                  style="
                                                    width: 33.8274%;
                                                    border-left: 1px solid black;
                                                    border-right: 1px solid
                                                      black;
                                                    border-top: 1px solid black;
                                                  "
                                                >
                                                  <div style="line-height: 1.5">
                                                    <span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          data-custom-class="body_text"
                                                          >E. Biometric
                                                          information</span
                                                        ></span
                                                      ></span
                                                    >
                                                  </div>
                                                </td>
                                                <td
                                                  style="
                                                    width: 51.4385%;
                                                    border-right: 1px solid
                                                      black;
                                                    border-top: 1px solid black;
                                                  "
                                                >
                                                  <div style="line-height: 1.5">
                                                    <span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          data-custom-class="body_text"
                                                          >Fingerprints and
                                                          voiceprints</span
                                                        ></span
                                                      ></span
                                                    >
                                                  </div>
                                                </td>
                                                <td
                                                  style="
                                                    width: 14.9084%;
                                                    text-align: center;
                                                    border-right: 1px solid
                                                      black;
                                                    border-top: 1px solid black;
                                                  "
                                                >
                                                  <div style="line-height: 1.5">
                                                    <br />
                                                  </div>
                                                  <div style="line-height: 1.5">
                                                    <span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          data-custom-class="body_text"
                                                          >NO</span
                                                        ></span
                                                      ></span
                                                    >
                                                  </div>
                                                  <div style="line-height: 1.5">
                                                    <br />
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td
                                                  style="
                                                    width: 33.8274%;
                                                    border-left: 1px solid black;
                                                    border-right: 1px solid
                                                      black;
                                                    border-top: 1px solid black;
                                                  "
                                                >
                                                  <div style="line-height: 1.5">
                                                    <span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          data-custom-class="body_text"
                                                          >F. Internet or other
                                                          similar network
                                                          activity</span
                                                        ></span
                                                      ></span
                                                    >
                                                  </div>
                                                </td>
                                                <td
                                                  style="
                                                    width: 51.4385%;
                                                    border-right: 1px solid
                                                      black;
                                                    border-top: 1px solid black;
                                                  "
                                                >
                                                  <div style="line-height: 1.5">
                                                    <span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          data-custom-class="body_text"
                                                          >Browsing history,
                                                          search history, online
                                                          behavior , interest
                                                          data, and interactions
                                                          with our and other
                                                          websites,
                                                          applications, systems,
                                                          and
                                                          advertisements</span
                                                        ></span
                                                      ></span
                                                    >
                                                  </div>
                                                </td>
                                                <td
                                                  style="
                                                    width: 14.9084%;
                                                    text-align: center;
                                                    border-right: 1px solid
                                                      black;
                                                    border-top: 1px solid black;
                                                  "
                                                >
                                                  <div style="line-height: 1.5">
                                                    <br />
                                                  </div>
                                                  <div style="line-height: 1.5">
                                                    <span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          data-custom-class="body_text"
                                                          >NO</span
                                                        ></span
                                                      ></span
                                                    >
                                                  </div>
                                                  <div style="line-height: 1.5">
                                                    <br />
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td
                                                  style="
                                                    width: 33.8274%;
                                                    border-left: 1px solid black;
                                                    border-right: 1px solid
                                                      black;
                                                    border-top: 1px solid black;
                                                  "
                                                >
                                                  <div style="line-height: 1.5">
                                                    <span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          data-custom-class="body_text"
                                                          >G. Geolocation
                                                          data</span
                                                        ></span
                                                      ></span
                                                    >
                                                  </div>
                                                </td>
                                                <td
                                                  style="
                                                    width: 51.4385%;
                                                    border-right: 1px solid
                                                      black;
                                                    border-top: 1px solid black;
                                                  "
                                                >
                                                  <div style="line-height: 1.5">
                                                    <span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          data-custom-class="body_text"
                                                          >Device location</span
                                                        ></span
                                                      ></span
                                                    >
                                                  </div>
                                                </td>
                                                <td
                                                  style="
                                                    width: 14.9084%;
                                                    text-align: center;
                                                    border-right: 1px solid
                                                      black;
                                                    border-top: 1px solid black;
                                                  "
                                                >
                                                  <div style="line-height: 1.5">
                                                    <br />
                                                  </div>
                                                  <div style="line-height: 1.5">
                                                    <span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          data-custom-class="body_text"
                                                          >NO</span
                                                        ></span
                                                      ></span
                                                    >
                                                  </div>
                                                  <div style="line-height: 1.5">
                                                    <br />
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td
                                                  style="
                                                    width: 33.8274%;
                                                    border-left: 1px solid black;
                                                    border-right: 1px solid
                                                      black;
                                                    border-top: 1px solid black;
                                                  "
                                                >
                                                  <div style="line-height: 1.5">
                                                    <span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          data-custom-class="body_text"
                                                          >H. Audio, electronic,
                                                          visual, thermal,
                                                          olfactory, or similar
                                                          information</span
                                                        ></span
                                                      ></span
                                                    >
                                                  </div>
                                                </td>
                                                <td
                                                  style="
                                                    width: 51.4385%;
                                                    border-right: 1px solid
                                                      black;
                                                    border-top: 1px solid black;
                                                  "
                                                >
                                                  <div style="line-height: 1.5">
                                                    <span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          data-custom-class="body_text"
                                                          >Images and audio,
                                                          video or call
                                                          recordings created in
                                                          connection with our
                                                          business
                                                          activities</span
                                                        ></span
                                                      ></span
                                                    >
                                                  </div>
                                                </td>
                                                <td
                                                  style="
                                                    width: 14.9084%;
                                                    text-align: center;
                                                    border-right: 1px solid
                                                      black;
                                                    border-top: 1px solid black;
                                                  "
                                                >
                                                  <div style="line-height: 1.5">
                                                    <br />
                                                  </div>
                                                  <div style="line-height: 1.5">
                                                    <span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          data-custom-class="body_text"
                                                          >NO</span
                                                        ></span
                                                      ></span
                                                    >
                                                  </div>
                                                  <div style="line-height: 1.5">
                                                    <br />
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td
                                                  style="
                                                    width: 33.8274%;
                                                    border-left: 1px solid black;
                                                    border-right: 1px solid
                                                      black;
                                                    border-top: 1px solid black;
                                                  "
                                                >
                                                  <div style="line-height: 1.5">
                                                    <span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          data-custom-class="body_text"
                                                          >I. Professional or
                                                          employment-related
                                                          information</span
                                                        ></span
                                                      ></span
                                                    >
                                                  </div>
                                                </td>
                                                <td
                                                  style="
                                                    width: 51.4385%;
                                                    border-right: 1px solid
                                                      black;
                                                    border-top: 1px solid black;
                                                  "
                                                >
                                                  <div style="line-height: 1.5">
                                                    <span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          data-custom-class="body_text"
                                                          >Business contact
                                                          details in order to
                                                          provide you our
                                                          Services at a business
                                                          level or job title,
                                                          work history, and
                                                          professional
                                                          qualifications if you
                                                          apply for a job with
                                                          us</span
                                                        ></span
                                                      ></span
                                                    >
                                                  </div>
                                                </td>
                                                <td
                                                  style="
                                                    width: 14.9084%;
                                                    text-align: center;
                                                    border-right: 1px solid
                                                      black;
                                                    border-top: 1px solid black;
                                                  "
                                                >
                                                  <div style="line-height: 1.5">
                                                    <br />
                                                  </div>
                                                  <div style="line-height: 1.5">
                                                    <span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          data-custom-class="body_text"
                                                          >NO</span
                                                        ></span
                                                      ></span
                                                    >
                                                  </div>
                                                  <div style="line-height: 1.5">
                                                    <br />
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td
                                                  style="
                                                    border-left: 1px solid black;
                                                    border-right: 1px solid
                                                      black;
                                                    border-top: 1px solid black;
                                                    width: 33.8274%;
                                                  "
                                                >
                                                  <div style="line-height: 1.5">
                                                    <span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          data-custom-class="body_text"
                                                          >J. Education
                                                          Information</span
                                                        ></span
                                                      ></span
                                                    >
                                                  </div>
                                                </td>
                                                <td
                                                  style="
                                                    border-right: 1px solid
                                                      black;
                                                    border-top: 1px solid black;
                                                    width: 51.4385%;
                                                  "
                                                >
                                                  <div style="line-height: 1.5">
                                                    <span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          data-custom-class="body_text"
                                                          >Student records and
                                                          directory
                                                          information</span
                                                        ></span
                                                      ></span
                                                    >
                                                  </div>
                                                </td>
                                                <td
                                                  style="
                                                    text-align: center;
                                                    border-right: 1px solid
                                                      black;
                                                    border-top: 1px solid black;
                                                    width: 14.9084%;
                                                  "
                                                >
                                                  <div style="line-height: 1.5">
                                                    <br />
                                                  </div>
                                                  <div style="line-height: 1.5">
                                                    <span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          data-custom-class="body_text"
                                                          >NO</span
                                                        ></span
                                                      ></span
                                                    >
                                                  </div>
                                                  <div style="line-height: 1.5">
                                                    <br />
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td
                                                  style="
                                                    border-width: 1px;
                                                    border-color: black;
                                                    border-style: solid;
                                                    width: 33.8274%;
                                                  "
                                                >
                                                  <div style="line-height: 1.5">
                                                    <span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          data-custom-class="body_text"
                                                          >K. Inferences drawn
                                                          from other personal
                                                          information</span
                                                        ></span
                                                      ></span
                                                    >
                                                  </div>
                                                </td>
                                                <td
                                                  style="
                                                    border-bottom: 1px solid
                                                      black;
                                                    border-top: 1px solid black;
                                                    border-right: 1px solid
                                                      black;
                                                    width: 51.4385%;
                                                  "
                                                >
                                                  <div style="line-height: 1.5">
                                                    <span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          data-custom-class="body_text"
                                                          >Inferences drawn from
                                                          any of the collected
                                                          personal information
                                                          listed above to create
                                                          a profile or summary
                                                          about, for example, an
                                                          individual’s
                                                          preferences and
                                                          characteristics</span
                                                        ></span
                                                      ></span
                                                    >
                                                  </div>
                                                </td>
                                                <td
                                                  style="
                                                    text-align: center;
                                                    border-right: 1px solid
                                                      black;
                                                    border-bottom: 1px solid
                                                      black;
                                                    border-top: 1px solid black;
                                                    width: 14.9084%;
                                                  "
                                                >
                                                  <div style="line-height: 1.5">
                                                    <br />
                                                  </div>
                                                  <div style="line-height: 1.5">
                                                    <span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        style="
                                                          font-size: 15px;
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          data-custom-class="body_text"
                                                          >NO</span
                                                        ></span
                                                      ></span
                                                    >
                                                  </div>
                                                  <div style="line-height: 1.5">
                                                    <br />
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          <div style="line-height: 1.5"></div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  >We may also collect other
                                                  personal information outside
                                                  of these categories through
                                                  instances where you interact
                                                  with us in person, online, or
                                                  by phone or mail in the
                                                  context of:
                                                </span></span
                                              ></span
                                            >
                                          </div>
                                          <ul>
                                            <li style="line-height: 1.5">
                                              <span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    data-custom-class="body_text"
                                                    >Receiving help through our
                                                    customer support
                                                    channels;</span
                                                  >
                                                </span></span
                                              >
                                            </li>
                                          </ul>
                                          <ul>
                                            <li style="line-height: 1.5">
                                              <span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    data-custom-class="body_text"
                                                    >Participation in customer
                                                    surveys or contests;
                                                    and</span
                                                  >
                                                </span></span
                                              >
                                            </li>
                                          </ul>
                                          <ul>
                                            <li style="line-height: 1.5">
                                              <span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    data-custom-class="body_text"
                                                    >Facilitation in the
                                                    delivery of our Services and
                                                    to respond to your
                                                    inquiries.</span
                                                  >
                                                </span></span
                                              >
                                            </li>
                                          </ul>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  ><strong
                                                    >How do we use and share
                                                    your personal
                                                    information?</strong
                                                  ></span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  ><span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        data-custom-class="body_text"
                                                        >More information about
                                                        our data collection and
                                                        sharing practices can be
                                                        found in this privacy
                                                        notice .</span
                                                      ></span
                                                    ></span
                                                  ></span
                                                >
                                              </span></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  >You may contact us by email
                                                  at</span
                                                >
                                                <span
                                                  style="
                                                    color: rgb(89, 89, 89);
                                                    font-size: 15px;
                                                  "
                                                  ><span
                                                    data-custom-class="body_text"
                                                    >hello@goaround.me ,</span
                                                  >
                                                </span></span
                                              ></span
                                            >
                                            <span data-custom-class="body_text"
                                              >or by referring to the contact
                                              details at the bottom of this
                                              document.</span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  >If you are using an
                                                  authorized agent to exercise
                                                  your right to opt out we may
                                                  deny a request if the
                                                  authorized agent does not
                                                  submit proof that they have
                                                  been validly authorized to act
                                                  on your behalf.</span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  ><strong
                                                    >Will your information be
                                                    shared with anyone
                                                    else?</strong
                                                  ></span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  >We may disclose your personal
                                                  information with our service
                                                  providers pursuant to a
                                                  written contract between us
                                                  and each service provider.
                                                  Each service provider is a
                                                  for-profit entity that
                                                  processes the information on
                                                  our behalf.</span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  >We may use your personal
                                                  information for our own
                                                  business purposes, such as for
                                                  undertaking internal research
                                                  for technological development
                                                  and demonstration. This is not
                                                  considered to be "selling" of
                                                  your personal information.
                                                </span></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  >GoAround.me</span
                                                >
                                                <span
                                                  data-custom-class="body_text"
                                                  >has not disclosed or sold any
                                                  personal information to third
                                                  parties for a business or
                                                  commercial purpose in the
                                                  preceding twelve (12) months.
                                                  <span
                                                    style="
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      style="
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        data-custom-class="body_text"
                                                        >GoAround.me</span
                                                      ></span
                                                    ></span
                                                  >
                                                  will not sell personal
                                                  information in the future
                                                  belonging to website visitors,
                                                  users, and other consumers.
                                                </span></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  ><strong
                                                    >Your rights with respect to
                                                    your personal data</strong
                                                  ></span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  ><u
                                                    >Right to request deletion
                                                    of the data — Request to
                                                    delete</u
                                                  ></span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  >You can ask for the deletion
                                                  of your personal information.
                                                  If you ask us to delete your
                                                  personal information, we will
                                                  respect your request and
                                                  delete your personal
                                                  information, subject to
                                                  certain exceptions provided by
                                                  law, such as (but not limited
                                                  to) the exercise by another
                                                  consumer of his or her right
                                                  to free speech, our compliance
                                                  requirements resulting from a
                                                  legal obligation, or any
                                                  processing that may be
                                                  required to protect against
                                                  illegal activities.</span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  ><u
                                                    >Right to be informed —
                                                    Request to know</u
                                                  ></span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  >Depending on the
                                                  circumstances, you have a
                                                  right to know:
                                                </span></span
                                              ></span
                                            >
                                          </div>
                                          <ul>
                                            <li style="line-height: 1.5">
                                              <span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    data-custom-class="body_text"
                                                    >whether we collect and use
                                                    your personal information;
                                                  </span></span
                                                ></span
                                              >
                                            </li>
                                          </ul>
                                          <ul>
                                            <li style="line-height: 1.5">
                                              <span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    data-custom-class="body_text"
                                                    >the categories of personal
                                                    information that we collect;
                                                  </span></span
                                                ></span
                                              >
                                            </li>
                                          </ul>
                                          <ul>
                                            <li style="line-height: 1.5">
                                              <span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    data-custom-class="body_text"
                                                    >the purposes for which the
                                                    collected personal
                                                    information is used;
                                                  </span></span
                                                ></span
                                              >
                                            </li>
                                          </ul>
                                          <ul>
                                            <li style="line-height: 1.5">
                                              <span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    data-custom-class="body_text"
                                                    >whether we sell your
                                                    personal information to
                                                    third parties;
                                                  </span></span
                                                ></span
                                              >
                                            </li>
                                          </ul>
                                          <ul>
                                            <li style="line-height: 1.5">
                                              <span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    data-custom-class="body_text"
                                                    >the categories of personal
                                                    information that we sold or
                                                    disclosed for a business
                                                    purpose;
                                                  </span></span
                                                ></span
                                              >
                                            </li>
                                          </ul>
                                          <ul>
                                            <li style="line-height: 1.5">
                                              <span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    data-custom-class="body_text"
                                                    >the categories of third
                                                    parties to whom the personal
                                                    information was sold or
                                                    disclosed for a business
                                                    purpose; and
                                                  </span></span
                                                ></span
                                              >
                                            </li>
                                          </ul>
                                          <ul>
                                            <li style="line-height: 1.5">
                                              <span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    data-custom-class="body_text"
                                                    >the business or commercial
                                                    purpose for collecting or
                                                    selling personal
                                                    information.
                                                  </span></span
                                                ></span
                                              >
                                            </li>
                                          </ul>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  >In accordance with applicable
                                                  law, we are not obligated to
                                                  provide or delete consumer
                                                  information that is
                                                  de-identified in response to a
                                                  consumer request or to
                                                  re-identify individual data to
                                                  verify a consumer
                                                  request.</span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  ><u
                                                    >Right to Non-Discrimination
                                                    for the Exercise of a
                                                    Consumer’s Privacy Rights</u
                                                  ></span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  >We will not discriminate
                                                  against you if you exercise
                                                  your privacy rights.</span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  ><u
                                                    >Verification process</u
                                                  ></span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  >Upon receiving your request,
                                                  we will need to verify your
                                                  identity to determine you are
                                                  the same person about whom we
                                                  have the information in our
                                                  system. These verification
                                                  efforts require us to ask you
                                                  to provide information so that
                                                  we can match it with
                                                  information you have
                                                  previously provided us. For
                                                  instance, depending on the
                                                  type of request you submit, we
                                                  may ask you to provide certain
                                                  information so that we can
                                                  match the information you
                                                  provide with the information
                                                  we already have on file, or we
                                                  may contact you through a
                                                  communication method (e.g. ,
                                                  phone or email) that you have
                                                  previously provided to us. We
                                                  may also use other
                                                  verification methods as the
                                                  circumstances dictate.</span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  >We will only use personal
                                                  information provided in your
                                                  request to verify your
                                                  identity or authority to make
                                                  the request. To the extent
                                                  possible, we will avoid
                                                  requesting additional
                                                  information from you for the
                                                  purposes of verification.
                                                  However, if we cannot verify
                                                  your identity from the
                                                  information already maintained
                                                  by us, we may request that you
                                                  provide additional information
                                                  for the purposes of verifying
                                                  your identity and for security
                                                  or fraud-prevention purposes.
                                                  We will delete such
                                                  additionally provided
                                                  information as soon as we
                                                  finish verifying you.</span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  ><u
                                                    >Other privacy rights</u
                                                  ></span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <ul>
                                            <li style="line-height: 1.5">
                                              <span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    data-custom-class="body_text"
                                                    >You may object to the
                                                    processing of your personal
                                                    information.
                                                  </span></span
                                                ></span
                                              >
                                            </li>
                                          </ul>
                                          <ul>
                                            <li style="line-height: 1.5">
                                              <span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    data-custom-class="body_text"
                                                    >You may request correction
                                                    of your personal data if it
                                                    is incorrect or no longer
                                                    relevant, or ask to restrict
                                                    the processing of the
                                                    information.
                                                  </span></span
                                                ></span
                                              >
                                            </li>
                                          </ul>
                                          <ul>
                                            <li style="line-height: 1.5">
                                              <span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    data-custom-class="body_text"
                                                    >You can designate an
                                                    authorized agent to make a
                                                    request under the CCPA on
                                                    your behalf. We may deny a
                                                    request from an authorized
                                                    agent that does not submit
                                                    proof that they have been
                                                    validly authorized to act on
                                                    your behalf in accordance
                                                    with the CCPA.
                                                  </span></span
                                                ></span
                                              >
                                            </li>
                                          </ul>
                                          <ul>
                                            <li style="line-height: 1.5">
                                              <span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    data-custom-class="body_text"
                                                    >You may request to opt out
                                                    from future selling of your
                                                    personal information to
                                                    third parties. Upon
                                                    receiving an opt-out
                                                    request, we will act upon
                                                    the request as soon as
                                                    feasibly possible, but no
                                                    later than fifteen (15) days
                                                    from the date of the request
                                                    submission.
                                                  </span></span
                                                ></span
                                              >
                                            </li>
                                          </ul>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  >To exercise these rights, you
                                                  can contact us</span
                                                >
                                                <span
                                                  style="
                                                    color: rgb(89, 89, 89);
                                                    font-size: 15px;
                                                  "
                                                  ><span
                                                    data-custom-class="body_text"
                                                    ><span
                                                      style="
                                                        color: rgb(89, 89, 89);
                                                        font-size: 15px;
                                                      "
                                                      ><span
                                                        data-custom-class="body_text"
                                                        >by email at
                                                        hello@goaround.me
                                                        ,</span
                                                      >
                                                    </span></span
                                                  ></span
                                                ></span
                                              ></span
                                            >
                                            <span data-custom-class="body_text"
                                              >or by referring to the contact
                                              details at the bottom of this
                                              document. If you have a complaint
                                              about how we handle your data, we
                                              would like to hear from you.</span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div
                                            id="policyupdates"
                                            style="line-height: 1.5"
                                          >
                                            <span
                                              style="color: rgb(127, 127, 127)"
                                              ><span
                                                style="
                                                  color: rgb(89, 89, 89);
                                                  font-size: 15px;
                                                "
                                                ><span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      id="control"
                                                      style="
                                                        color: rgb(0, 0, 0);
                                                      "
                                                      ><strong
                                                        ><span
                                                          data-custom-class="heading_1"
                                                          >14. DO WE MAKE
                                                          UPDATES TO THIS
                                                          NOTICE?</span
                                                        ></strong
                                                      ></span
                                                    ></span
                                                  ></span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  ><em
                                                    ><strong>In Short:</strong>
                                                    Yes, we will update this
                                                    notice as necessary to stay
                                                    compliant with relevant
                                                    laws.</em
                                                  ></span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  >We may update this privacy
                                                  notice from time to time. The
                                                  updated version will be
                                                  indicated by an updated
                                                  "Revised" date and the updated
                                                  version will be effective as
                                                  soon as it is accessible. If
                                                  we make material changes to
                                                  this privacy notice, we may
                                                  notify you either by
                                                  prominently posting a notice
                                                  of such changes or by directly
                                                  sending you a notification. We
                                                  encourage you to review this
                                                  privacy notice frequently to
                                                  be informed of how we are
                                                  protecting your
                                                  information.</span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div
                                            id="contact"
                                            style="line-height: 1.5"
                                          >
                                            <span
                                              style="color: rgb(127, 127, 127)"
                                              ><span
                                                style="
                                                  color: rgb(89, 89, 89);
                                                  font-size: 15px;
                                                "
                                                ><span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      id="control"
                                                      style="
                                                        color: rgb(0, 0, 0);
                                                      "
                                                      ><strong
                                                        ><span
                                                          data-custom-class="heading_1"
                                                          >15. HOW CAN YOU
                                                          CONTACT US ABOUT THIS
                                                          NOTICE?</span
                                                        ></strong
                                                      ></span
                                                    ></span
                                                  ></span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  >If you have questions or
                                                  comments about this notice,
                                                  you may
                                                  <span
                                                    style="
                                                      color: rgb(89, 89, 89);
                                                      font-size: 15px;
                                                    "
                                                    ><span
                                                      data-custom-class="body_text"
                                                      >email us at
                                                      hello@goaround.me</span
                                                    ></span
                                                  >
                                                  <span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      style="
                                                        font-size: 15px;
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        data-custom-class="body_text"
                                                        >or by post to:</span
                                                      ></span
                                                    ></span
                                                  ></span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  ><span style="font-size: 15px"
                                                    ><span
                                                      style="
                                                        color: rgb(89, 89, 89);
                                                      "
                                                      ><span
                                                        style="
                                                          color: rgb(
                                                            89,
                                                            89,
                                                            89
                                                          );
                                                        "
                                                        ><span
                                                          data-custom-class="body_text"
                                                          >GoAround.me</span
                                                        ></span
                                                      ></span
                                                    ></span
                                                  ></span
                                                >
                                              </span></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span style="font-size: 15px"
                                              ><span
                                                data-custom-class="body_text"
                                                >Office No 1, Prabhavee Tech
                                                Park
                                              </span></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              data-custom-class="body_text"
                                              style="font-size: 15px"
                                              >Baner
                                            </span>
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span style="font-size: 15px"
                                              ><span
                                                data-custom-class="body_text"
                                                >Pune
                                                <span
                                                  style="color: rgb(89, 89, 89)"
                                                  ><span style="font-size: 15px"
                                                    >, Maharashtra 411057</span
                                                  ></span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span style="font-size: 15px"
                                              ><span
                                                data-custom-class="body_text"
                                              >
                                                India
                                                <span style="font-size: 15px"
                                                  ><span
                                                    data-custom-class="body_text"
                                                    ><span
                                                      style="
                                                        color: rgb(89, 89, 89);
                                                      "
                                                    >
                                                    </span></span></span
                                              ></span>
                                              <span
                                                data-custom-class="body_text"
                                              >
                                              </span
                                            ></span>
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div
                                            id="request"
                                            style="line-height: 1.5"
                                          >
                                            <span
                                              style="color: rgb(127, 127, 127)"
                                              ><span
                                                style="
                                                  color: rgb(89, 89, 89);
                                                  font-size: 15px;
                                                "
                                                ><span
                                                  style="
                                                    font-size: 15px;
                                                    color: rgb(89, 89, 89);
                                                  "
                                                  ><span
                                                    style="
                                                      font-size: 15px;
                                                      color: rgb(89, 89, 89);
                                                    "
                                                    ><span
                                                      id="control"
                                                      style="
                                                        color: rgb(0, 0, 0);
                                                      "
                                                      ><strong
                                                        ><span
                                                          data-custom-class="heading_1"
                                                          >16. HOW CAN YOU
                                                          REVIEW, UPDATE, OR
                                                          DELETE THE DATA WE
                                                          COLLECT FROM
                                                          YOU?</span
                                                        ></strong
                                                      ></span
                                                    ></span
                                                  ></span
                                                ></span
                                              ></span
                                            >
                                          </div>
                                          <div style="line-height: 1.5">
                                            <br />
                                          </div>
                                          <div style="line-height: 1.5">
                                            <span
                                              style="
                                                font-size: 15px;
                                                color: rgb(89, 89, 89);
                                              "
                                              ><span
                                                style="
                                                  font-size: 15px;
                                                  color: rgb(89, 89, 89);
                                                "
                                                ><span
                                                  data-custom-class="body_text"
                                                  >You have the right to request
                                                  access to the personal
                                                  information we collect from
                                                  you, change that information,
                                                  or delete it. To request to
                                                  review, update, or delete your
                                                  personal information, please
                                                  submit a request form by
                                                  clicking</span
                                                >
                                                <span
                                                  style="
                                                    color: rgb(48, 48, 241);
                                                  "
                                                  ><span
                                                    data-custom-class="body_text"
                                                    ><span
                                                      style="font-size: 15px"
                                                      ><a
                                                        data-custom-class="link"
                                                        href="https://app.termly.io/notify/61c647ad-c734-4aa5-8cf7-d56348139149"
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                        >here</a
                                                      ></span
                                                    ></span
                                                  ></span
                                                ></span
                                              >
                                              <span
                                                data-custom-class="body_text"
                                                >.</span
                                              ></span
                                            >
                                          </div>
                                        </div>
                                        <div
                                          style="
                                            color: #595959;
                                            font-size: 14px;
                                            font-family: Arial;
                                            padding-top: 16px;
                                          "
                                        >
                                          This privacy policy was created using
                                          Termly's
                                          <a
                                            style="
                                              color: rgb(
                                                48,
                                                48,
                                                241
                                              ) !important;
                                            "
                                            href="https://termly.io/products/privacy-policy-generator/"
                                            >Privacy Policy Generator</a
                                          >.
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-layout>
</template>

<script>
import AppLayout from "@/components/ui/AppLayout.vue";
export default {
  components: {
    AppLayout,
  },
};
</script>
<style>
[data-custom-class="body"],
[data-custom-class="body"] * {
  background: transparent !important;
}

[data-custom-class="title"],
[data-custom-class="title"] * {
  font-family: Arial !important;
  font-size: 26px !important;
  color: #000000 !important;
}

[data-custom-class="subtitle"],
[data-custom-class="subtitle"] * {
  font-family: Arial !important;
  color: #595959 !important;
  font-size: 14px !important;
}

[data-custom-class="heading_1"],
[data-custom-class="heading_1"] * {
  font-family: Arial !important;
  font-size: 19px !important;
  color: #000000 !important;
}

[data-custom-class="heading_2"],
[data-custom-class="heading_2"] * {
  font-family: Arial !important;
  font-size: 17px !important;
  color: #000000 !important;
}

[data-custom-class="body_text"],
[data-custom-class="body_text"] * {
  color: #595959 !important;
  font-size: 14px !important;
  font-family: Arial !important;
}

[data-custom-class="link"],
[data-custom-class="link"] * {
  color: #3030f1 !important;
  font-size: 14px !important;
  font-family: Arial !important;
  word-break: break-word !important;
}

ul {
  margin-left: 30px;
}
</style>
<style>
ul {
  list-style-type: square;
}

ul > li > ul {
  list-style-type: circle;
}

ul > li > ul > li > ul {
  list-style-type: square;
}

ol li {
  font-family: Arial;
}
</style>
