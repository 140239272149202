<template>
  <div class="people-slide">
    <v-skeleton-loader v-if="loading" type="list-item-three-line" />
    <template v-else>
      <div v-if="interests.length">
        <div class="caption" v-if="$route.name != 'interests'">Interests</div>
        <v-chip-group active-class="primary" v-model="form.interests">
          <v-chip
            @click="onInterestClick('all')"
            class="my-1"
            value="all"
            small
            :outlined="form.interests != 'all'"
          >
            All
          </v-chip>
          <v-chip
            class="my-1"
            @click="onInterestClick(tag.value)"
            v-for="(tag, i) in interests"
            :value="tag.value"
            :key="i"
            small
            :outlined="tag.value != form.interests"
          >
            {{ tag.name }}
          </v-chip>
        </v-chip-group>
      </div>
      <div v-if="skills.length">
        <div class="caption pr-5" v-if="$route.name != 'skills'">Skills</div>
        <v-chip-group
          class="flex-grow-1"
          active-class="primary"
          v-model="form.professionalskills"
        >
          <v-chip
            class="my-1"
            value="all"
            @click="onSkillClick('all')"
            small
            :outlined="form.professionalskills != 'all'"
          >
            All
          </v-chip>
          <v-chip
            v-for="(tag, i) in skills"
            :value="tag.value"
            @click="onSkillClick(tag.value)"
            :key="i"
            small
            class="my-1"
            :outlined="tag.value != form.professionalskills"
          >
            {{ tag.name }}
          </v-chip>
        </v-chip-group>
      </div>

      <v-text-field
        prepend-inner-icon="mdi-magnify"
        outlined
        class="my-3"
        type="search"
        hide-details
        @keyup.enter="submit()"
        dense
        v-model="form.search"
        clearable
        placeholder="Search"
      />
    </template>
  </div>
</template>

<script>
import { fetchAlgoliaFacets } from "@/utils/algolia.utils";
import { mapFacetSelect } from "@/utils/common.utils";
export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  name: "PeopleFilter",
  data() {
    return {
      dialog: false,
      interests: [],
      loading: false,
      skills: [],
      form: {
        interests: "all",
        professionalskills: "all",
        search: "",
      },
    };
  },
  methods: {
    onInterestClick(val) {
      console.log("clicked");
      this.form.interests = val;
      this.form.professionalskills = "all";
      this.$emit("onFilter", this.form);
    },
    onSkillClick(val) {
      console.log("clicked");
      this.form.professionalskills = val;
      this.form.interests = "all";
      this.$emit("onFilter", this.form);
    },
    submit() {
      console.log("from people filter");
      this.$emit("onFilter", this.form);
    },
    async fetchFacets() {
      const vm = this;
      try {
        vm.loading = true;
        let requestBody = {
          page: 0,
          hitsPerPage: 0,
          query: "*",
          index: "users",
          facets: ["professionalskills", "interests"],
          ...vm.globalFilter,
        };
        let facetRes = await fetchAlgoliaFacets(requestBody);
        let { interests, professionalskills } = facetRes.facets;
        vm.interests = mapFacetSelect(interests || {});
        vm.skills = mapFacetSelect(professionalskills || {});
        vm.loading = false;
      } catch (error) {
        vm.loading = false;
        console.error(error);
      }
    },
  },
  beforeMount() {
    this.form = this.value;
    console.log("people filter....", this.value);
    this.fetchFacets();
  },
};
</script>

<style></style>
