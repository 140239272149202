<template>
  <div class="video-container">
    <video
      style="width: 100% !important; height: 100% !important"
      ref="video"
      controls
      @volumechange="handleVolumeChange"
      playsinline
      preload="auto"
    >
      <source :src="videoSrc" type="video/mp4" />
    </video>
  </div>
</template>

<script>
export default {
  props: {
    videoSrc: String,
  },
  computed: {
    muted() {
      return this.$store.getters.muted;
    },
  },
  mounted() {
    this.observeVideo();
    this.$refs.video.muted = this.muted;
  },
  methods: {
    handleVolumeChange() {
      let muted = this.$refs.video.muted;
      let videos = document.querySelectorAll("video");
      Array.from(videos).forEach((i) => {
        i.muted = muted;
      });
      this.$store.commit("SET_MUTED", muted);
    },
    observeVideo() {
      const options = {
        root: null, // use the viewport as the root
        rootMargin: "0px",
        threshold: 1, // 50% of the video should be in the viewport
      };

      const observer = new IntersectionObserver(
        this.handleIntersection,
        options
      );
      observer.observe(this.$refs.video);
    },
    handleIntersection(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          let videoElements = document.querySelectorAll("video");
          for (let i = 0; i < videoElements.length; i++) {
            videoElements[i].pause(); // or videojs(videoElements[i]).pause()
          }
          setTimeout(() => {
            if (this.$refs.video) this.$refs.video.play();
          }, 100);
        } else {
          let videoElements = document.querySelectorAll("video");
          for (let i = 0; i < videoElements.length; i++) {
            videoElements[i].pause(); // or videojs(videoElements[i]).pause()
          }
        }
      });
    },
  },
};
</script>
<style scoped>
.video-container {
  width: 100%;
  max-width: 800px;
  height: 100%;
  margin: 0 auto;
}
</style>
