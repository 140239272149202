<template>
  <div>
    <div class="empty-groups" v-if="isEmpty">
      <div>No groups subscribed yet?</div>
      <v-btn to="/groups/nearby" class="secondary mt-3" depressed>
        Join a group
      </v-btn>
    </div>
    <div v-else>
      <group-item
        v-for="(item, i) in cohorts"
        :item="item"
        class="mb-4"
        :key="i"
      ></group-item>
      <infinite-loading
        ref="loader"
        @infinite="infiniteHandler"
        :distance="430"
      >
        <v-skeleton-loader slot="spinner" type="list-item-avatar-three-line" />
      </infinite-loading>
    </div>
  </div>
</template>

<script>
import GroupItem from "@/components/groups/GroupItem.vue";
import InfiniteLoading from "vue-infinite-loading";
import { documentId, getDocs, query, where } from "firebase/firestore";
import { colCohorts } from "@/utils/firebase.utils";

export default {
  components: { GroupItem, InfiniteLoading },
  data() {
    return {
      cohorts: [],
      isLoading: true,
      page: 0,
      previoursCohorts: [],
    };
  },
  computed: {
    isEmpty() {
      let cohorts = this.authUser.cohorts || [];
      return !this.isAuth || cohorts.length == 0;
    },
  },
  methods: {
    joinGroup() {
      const vm = this;
      if (!vm.confirmLogin()) return;
      vm.$router.push("/join-group");
    },
    async infiniteHandler($state) {
      const vm = this;
      let cohorts = [...(vm.authUser.cohorts || [])].reverse();
      if (!this.isAuth || cohorts.length == 0) {
        console.log("cohorts empty...");
        $state.complete();
        return;
      }
      try {
        let start = vm.page * 10;
        let end = start + 10;
        let cohortIds = cohorts.map((i) => vm.getDocPath(i));
        let ids = [];
        for (let i = start; i < end; i++) {
          if (cohortIds[i]) ids.push(cohortIds[i]);
        }
        if (ids.length != 0) {
          let cohortQuery = query(colCohorts, where(documentId(), "in", ids));
          let cohortDocs = (await getDocs(cohortQuery)).docs;
          cohortDocs = cohortDocs.map((i) => ({ ...i.data(), id: i.id }));
          vm.cohorts = vm.cohorts.concat(cohortDocs);
        }
        let totalPage = Math.round(cohorts.length / 10) + 1;
        if (vm.page >= totalPage) {
          $state.complete();
        } else {
          $state.loaded();
        }
        vm.page++;
      } catch (error) {
        $state.complete();
        vm.handleError(error);
      }
    },
  },
  activated() {
    if (this.isAuth) {
      let list = this.authUser.cohorts || [];
      let previours = this.previoursCohorts;
      if (JSON.stringify(list) != JSON.stringify(previours)) {
        this.page = 0;
        this.$refs.loader.stateChanger.reset();
        this.cohorts = [];
        this.previoursCohorts = list;
      }
    }
  },
};
</script>

<style>
.empty-groups {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 200px);
}
</style>
