<template>
  <app-layout title="Nearby Groups"> </app-layout>
</template>

<script>
import AppLayout from "@/components/ui/AppLayout.vue";
export default {
  components: {
    AppLayout,
  },
};
</script>

<style></style>
