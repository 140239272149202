<template>
  <div>
    <label class="input-label" v-if="label">{{ label }}</label>
    <v-combobox
      v-model="input"
      :items="options"
      label=""
      :dense="dense"
      :loading="isLoading"
      :return-object="false"
      item-value="value"
      :class="label ? 'mt-1' : ''"
      :search-input.sync="search"
      item-text="name"
      outlined
      @input="$emit('input', input)"
      v-bind="$attrs"
      multiple
      small-chips
      :menu-props="{
        closeOnClick: true,
        closeOnContentClick: true,
      }"
      :rules="getRules"
    >
      <template v-slot:selection="{ attrs, item, parent, selected }">
        <v-chip
          v-bind="attrs"
          :input-value="selected"
          label
          small
          color="green lighten-3"
        >
          <span class="pr-2">
            {{ item }}
          </span>
          <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
        </v-chip>
      </template>
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              "<strong>{{ search }}</strong
              >". Press <kbd>enter</kbd> to create a new one
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-combobox>
  </div>
</template>

<script>
import inputMixin from "@/mixins/input.mixin.js";
export default {
  props: {
    fetch: {
      type: Function,
      default: null,
    },
  },
  mixins: [inputMixin],
  data() {
    return {
      search: "",
      isLoading: false,
      options: [],
    };
  },
  watch: {
    search(val) {
      if (this.isLoading) return;
      if (!val || val.length < 3) return;

      if (this.fetch) {
        this.isLoading = true;
        this.options = [];
        this.fetch(val)
          .then((res) => {
            this.options = res;
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
          });
      }
    },
  },
};
</script>

<style></style>
