<template>
  <div>
    <div v-if="!isAuth" class="d-flex justify-center py-10">
      <img width="150px" src="../assets/img/logo.png" alt="" />
    </div>
    <v-app-bar app height="64px" class="white app-header" flat v-else>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <div class="title">
        {{ title }}
      </div>
      <v-spacer></v-spacer>
      <div class="subtitle-2 font-weight-medium">{{ name }}</div>
      <v-btn icon @click="logout()" color="red" tile class="ml-5">
        <v-icon color="red">mdi-lock-open</v-icon>
      </v-btn>
    </v-app-bar>
    <app-drawer v-model="drawer" v-if="isAuth"></app-drawer>
  </div>
</template>

<script>
import { auth } from "@/utils/firebase.utils";
import headerConstants from "../assets/constants/header.constants.js";
import AppDrawer from "./AppDrawer.vue";
import { signOut } from "firebase/auth";
export default {
  components: {
    AppDrawer,
  },
  data() {
    return {
      drawer: true,
    };
  },
  computed: {
    name() {
      if (this.user?.firstName) {
        return `${this.user.firstName} ${this.user.lastName}`;
      }
      return "";
    },
    title() {
      return headerConstants[this.$route.name] || "Around Fly";
    },
  },
  methods: {
    logout() {
      signOut(auth);
    },
  },
};
</script>
