<template>
  <v-container style="max-width: 1000px">
    <div class="d-flex align-center">
      <v-tabs color="primary" v-model="tab">
        <v-tab class="text-none">Images</v-tab>
        <v-tab class="text-none">Videos</v-tab>
      </v-tabs>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        :loading="isImageLoading"
        class="secondary"
        :icon="isMobile"
        @click="$refs.image.click()"
        color="white"
      >
        <v-icon :left="!isMobile">mdi-image</v-icon>
        <span class="d-none d-md-block"> Add Image </span>
      </v-btn>
      <v-btn
        depressed
        :icon="isMobile"
        class="secondary ml-3"
        :loading="isVideoLoading"
        color="white"
        @click="$refs.video.click()"
      >
        <v-icon :left="!isMobile">mdi-video</v-icon>
        <span class="d-none d-md-block"> Add Video </span>
      </v-btn>
      <input
        accept="image/*"
        type="file"
        class="d-none"
        ref="image"
        @change="onImageChange"
      />
      <input
        accept="video/*"
        type="file"
        class="d-none"
        ref="video"
        @change="onVideoChange"
      />
    </div>
    <please-wait class="mt-4" v-if="isLoading"></please-wait>

    <div class="mt-4">
      <v-row>
        <v-col
          v-for="n in items"
          :key="n.id"
          class="d-flex child-flex"
          cols="6"
          md="3"
        >
          <a :href="getEditorHref(n)">
            <v-img :src="n.thumbnail" aspect-ratio="1" class="grey lighten-2">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </a>
        </v-col>
      </v-row>
    </div>
    <video
      id="video"
      type="video/mp4"
      style="visibility: hidden"
      controls
    ></video>
  </v-container>
</template>

<script>
import { colGallery, storage } from "@/utils/firebase.utils";
import { getDate } from "@/utils/common.utils";
import {
  getDownloadURL,
  ref,
  uploadBytes,
  uploadString,
} from "firebase/storage";
import {
  doc,
  getDocs,
  orderBy,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import PleaseWait from "@/components/ui/PleaseWait.vue";

export default {
  components: { PleaseWait },
  data() {
    return {
      isImageLoading: false,
      isVideoLoading: false,
      isLoading: false,
      list: [],
      tab: 0,
    };
  },
  computed: {
    items() {
      let type = this.tab == 0 ? "image" : "video";
      return [...this.list].filter((i) => i.type == type);
    },
  },
  methods: {
    getEditorHref({ id, type }) {
      if (type == "image") return `/update-gallery-image/${id}`;
      return `/update-gallery-video/${id}`;
    },
    async onVideoChange(e) {
      const vm = this;
      let video = e.target.files[0];
      vm.$refs.video.value = null;
      if (video) {
        try {
          vm.isVideoLoading = true;
          let thumbnail = await vm.createVideoThumbnail(video);
          let id = new Date().getTime().toString();
          let data = {
            created_at: getDate(),
            updated_at: getDate(),
            type: "video",
            uid: vm.uid,
            json: {},
          };
          let videoRef = ref(storage, `gallery/${this.uid}/${id}.mp4`);
          let thumbnailRef = ref(
            storage,
            `gallery/${this.uid}/${id}_thumb.jpeg`
          );
          await uploadBytes(videoRef, video);
          await uploadString(thumbnailRef, thumbnail, "data_url");
          data.url = await getDownloadURL(videoRef);
          data.thumbnail = await getDownloadURL(thumbnailRef);
          await setDoc(doc(colGallery, id), data);
          vm.fetchGallery();
          vm.isVideoLoading = false;
        } catch (error) {
          vm.isVideoLoading = false;
          vm.handleError(error);
        }
      }
    },
    async createVideoThumbnail(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = function (e) {
          const videoUrl = e.target.result;
          const video = document.getElementById("video");
          video.src = videoUrl;
          video.crossOrigin = "anonymous";
          video.addEventListener("loadedmetadata", function () {
            setTimeout(() => {
              const canvas = document.createElement("canvas");
              canvas.width = video.videoWidth;
              canvas.height = video.videoHeight;
              const context = canvas.getContext("2d");
              context.drawImage(video, 0, 0, canvas.width, canvas.height);
              const thumbnailUrl = canvas.toDataURL("image/jpeg");
              resolve(thumbnailUrl);
            }, 1000);
          });
        };
        // Read the selected video file as data URL
        reader.readAsDataURL(file);
      });
    },
    async onImageChange(e) {
      const vm = this;
      let image = e.target.files[0];
      vm.$refs.image.value = null;
      if (image) {
        try {
          vm.isImageLoading = true;
          let id = new Date().getTime().toString();
          let data = {
            created_at: getDate(),
            updated_at: getDate(),
            type: "image",
            uid: vm.uid,
            json: {},
          };
          let imageRef = ref(storage, `gallery/${this.uid}/${id}.png`);
          await uploadBytes(imageRef, image);
          data.url = await getDownloadURL(imageRef);
          data.thumbnail = data.url;
          await setDoc(doc(colGallery, id), data);
          vm.fetchGallery();
          vm.isImageLoading = false;
        } catch (error) {
          vm.isImageLoading = false;
          vm.handleError(error);
        }
      }
    },
    async fetchGallery() {
      const vm = this;
      try {
        vm.isLoading = true;
        let q = query(
          colGallery,
          orderBy("updated_at", "desc"),
          where("uid", "==", vm.uid)
        );
        vm.list = (await getDocs(q)).docs.map((i) => ({
          ...i.data(),
          id: i.id,
        }));
        vm.isLoading = false;
      } catch (error) {
        vm.handleError(error);
      }
    },
  },
  mounted() {
    this.fetchGallery();
  },
};
</script>

<style></style>
