import rulesConstants from "@/assets/constants/rules.constants";
import Vue from "vue";

const moment = require("moment");
export const getDate = () => {
  return moment().format("YYYY-MM-DDTHH:mm:ss");
};

export function getRules(rules) {
  if (!rules) return [];
  rules = rules.split("|");
  let list = [];
  rules.forEach((rule) => {
    if (rulesConstants[rule]) list.push(rulesConstants[rule]);
  });
  return list;
}

export const mapDocs = (docs) => {
  return docs.map((i) => {
    return { ...i.data(), id: i.id };
  });
};
export const mapFacets = (item) => {
  return Object.keys(item).map((facet) => {
    let value = item[facet];
    let name = facet;
    if (!name.startsWith("#")) name = `#${name}`;
    return { name, value, facet };
  });
};
export const mapFacetSelect = (item) => {
  return Object.keys(item).map((name) => {
    let formatedName = name?.charAt(0).toUpperCase() + name?.slice(1);
    return { name: `${formatedName} (${item[name]})`, value: name };
  });
};

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
      reject(error);
    };
  });
}
export const eventBus = new Vue();

const zeroPad = (num, pad) => {
  return String(num).padStart(pad, "0");
};

const isSameDay = (d1, d2) => {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
};

export const parseTimestamp = (timestamp, format = "") => {
  if (!timestamp) return;

  const date = timestamp.seconds
    ? new Date(timestamp.seconds * 1000)
    : timestamp;

  if (format === "HH:mm") {
    return `${zeroPad(date.getHours(), 2)}:${zeroPad(date.getMinutes(), 2)}`;
  } else if (format === "DD MMMM YYYY") {
    const options = { month: "long", year: "numeric", day: "numeric" };
    return `${new Intl.DateTimeFormat("en-GB", options).format(date)}`;
  } else if (format === "DD/MM/YY") {
    const options = { month: "numeric", year: "numeric", day: "numeric" };
    return `${new Intl.DateTimeFormat("en-GB", options).format(date)}`;
  } else if (format === "DD MMMM, HH:mm") {
    const options = { month: "long", day: "numeric" };
    return `${new Intl.DateTimeFormat("en-GB", options).format(
      date
    )}, ${zeroPad(date.getHours(), 2)}:${zeroPad(date.getMinutes(), 2)}`;
  }

  return date;
};

export const formatTimestamp = (date, timestamp) => {
  const timestampFormat = isSameDay(date, new Date()) ? "HH:mm" : "DD/MM/YY";
  const result = parseTimestamp(timestamp, timestampFormat);
  return timestampFormat === "HH:mm" ? `Today, ${result}` : result;
};
