<template>
  <app-layout>
    <v-skeleton-loader
      v-if="loading"
      slot="spinner"
      type="list-item-avatar-three-line, image, article"
    />
    <post-view-card v-else @onPost="onPost" :post="post"></post-view-card>
  </app-layout>
</template>

<script>
import { doc, getDoc } from "firebase/firestore";
import { colPosts } from "@/utils/firebase.utils";
import PostViewCard from "@/components/public/PostViewCard.vue";
import AppLayout from "@/components/ui/AppLayout.vue";
export default {
  name: "PostView",
  components: { PostViewCard, AppLayout },
  data() {
    return {
      loading: true,
      post: {},
    };
  },
  methods: {
    async fetchPost() {
      const vm = this;
      try {
        vm.loading = true;
        let id = vm.$route.params.id;
        id = id.split("{{1}}").join("");
        let data = (await getDoc(doc(colPosts, id))).data();
        let user = (await getDoc(data.userref)).data();
        vm.post = { ...data, id, user };
        vm.loading = false;
      } catch (error) {
        vm.handleError(error);
      }
    },
    onPost(post) {
      this.post = {
        ...this.post,
        ...post,
      };
    },
  },
  mounted() {
    this.fetchPost();
  },
};
</script>

<style lang="scss">
.post-view {
  max-width: 100p;
}
</style>
