<template>
  <v-dialog v-model="dialog" max-width="400px" persistent>
    <v-card class="pa-5">
      <v-card-title class="pa-0 justify-center"> Welcome onboard!</v-card-title>
      <v-card-text class="pa-0 my-2 text-center">
        Add around to your home screen for quick access.
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="green"
          class="text-none mx-auto"
          @click="install()"
          block
          dark
          depressed
        >
          Add to home screen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: true,
    };
  },
  methods: {
    install() {
      this.$emit("install");
      this.dialog = false;
    },
  },
};
</script>

<style></style>
