import Vue from "vue";
import VueRouter from "vue-router";
import Invite from "../pages/invite/Invite.vue";
import Index from "../pages/public/Index.vue";
import People from "../pages/public/People.vue";
import Interests from "../pages/public/Interests.vue";
import Feed from "../pages/public/Feed.vue";
import InviteLogin from "../pages/invite/InviteLogin.vue";
import SignupComplete from "../pages/invite/SignupComplete.vue";
import PostView from "../pages/public/PostView.vue";
import Post from "../pages/public/Post.vue";
import flyRoutes from "./fly.routes.js";
import { isAuth, isNotAuth } from "@/assets/helper/auth.helper";
import Login from "../pages/Login.vue";
import UpdateProfile from "../pages/UpdateProfile.vue";
import HashtagPosts from "../pages/public/HashtagPosts.vue";
import Profile from "../pages/public/Profile.vue";
import PostComments from "../pages/public/PostComments.vue";
import websiteRoutes from "./website.routes";
import Groups from "../pages/groups/Groups.vue";
import GroupJoined from "../pages/groups/GroupJoined.vue";
import GroupNearby from "../pages/groups/GroupNearby.vue";
import GroupSuggested from "../pages/groups/GroupSuggested.vue";
import JoinGroup from "../pages/groups/JoinGroup.vue";
import CreateGroup from "../pages/groups/CreateGroup.vue";
import GroupDetails from "../pages/groups/GroupDetails.vue";
import ChatRoom from "../pages/chat/ChatRoom.vue";
import ChatRooms from "../pages/chat/ChatRooms.vue";
Vue.use(VueRouter);

const routes = [
  ...flyRoutes,
  ...websiteRoutes,
  {
    path: "/",
    name: "index",
    component: Index,
  },
  {
    path: "/people",
    name: "people",
    component: People,
  },
  {
    path: "/interests",
    name: "interests",
    component: Interests,
  },
  {
    path: "/skills",
    name: "skills",
    component: Interests,
  },
  {
    path: "/feed",
    name: "feed",
    component: Feed,
  },
  {
    path: "/post",
    name: "post",
    component: Post,
    beforeEnter: isAuth,
  },
  {
    path: "/post/:id",
    name: "post-view",
    component: PostView,
  },
  {
    path: "/comments/:id",
    name: "comments",
    component: PostComments,
  },
  {
    path: "/invite/:id",
    name: "invite",
    component: Invite,
  },
  {
    path: "/invite-login/:id",
    name: "invite-login",
    component: InviteLogin,
  },
  {
    path: "/signup-complete/:id",
    name: "signup-complete",
    component: SignupComplete,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    beforeEnter: isNotAuth,
  },
  {
    path: "/signup",
    name: "signup",
    component: Login,
    beforeEnter: isNotAuth,
  },
  {
    path: "/hashtag/:hashtag",
    name: "hashtag-posts",
    component: HashtagPosts,
  },
  {
    path: "/update-profile",
    name: "update-profile",
    component: UpdateProfile,
    beforeEnter: isAuth,
  },
  {
    path: "/join-group",
    name: "join-group",
    component: JoinGroup,
    beforeEnter: isAuth,
  },
  {
    path: "/create-group",
    name: "create-group",
    component: CreateGroup,
    beforeEnter: isAuth,
  },
  {
    path: "/group/:id",
    name: "group-details",
    component: GroupDetails,
  },
  {
    path: "/groups",
    name: "groups",
    component: Groups,
    redirect: "/groups/joined",
    children: [
      {
        path: "/groups/joined",
        name: "groups-joined",
        component: GroupJoined,
      },
      {
        path: "/groups/suggested",
        name: "groups-suggested",
        component: GroupSuggested,
      },
      {
        path: "/groups/nearby",
        name: "groups-nearby",
        component: GroupNearby,
      },
    ],
  },
  {
    path: "/chat-room/:id",
    name: "chat-room",
    component: ChatRoom,
    beforeEnter: isAuth,
  },
  {
    path: "/chat-rooms",
    name: "chat-rooms",
    component: ChatRooms,
    beforeEnter: isAuth,
  },
  {
    path: "/:id",
    name: "profile",
    component: Profile,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
