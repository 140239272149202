<template>
  <div
    class="pb-md-5 mx-auto"
    :class="fluid ? 'md:max-w-4xl mx-auto' : 'app-layout'"
  >
    <v-app-bar
      flat
      color="white"
      id="app-bar"
      app
      class="app-layout-header py-2"
    >
      <div class="d-flex align-center px-4 px-md-0" style="width: 100%">
        <div>
          <div class="d-flex align-center">
            <v-btn v-if="isBack" icon small @click="$router.go(-1)"
              ><v-icon>mdi-arrow-left</v-icon></v-btn
            >
            <v-avatar class="mr-2" size="40">
              <v-icon v-if="isChatRoom">mdi-forum</v-icon>
              <v-img :src="logo" v-else></v-img>
            </v-avatar>
            <div>
              <div class="title text-truncate mt-1" style="line-height: 1.2rem">
                {{ title || "around" }}
              </div>
              <a
                class="d-flex align-center blue--text subtitle-2"
                style="line-height: 1.2rem; margin-left: 1px"
                v-if="!isLoginSignup"
                @click="openLocation"
              >
                {{ currentLocation.name || "Vasai" }}
                <v-btn icon small>
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </a>
            </div>
          </div>
        </div>
        <v-spacer></v-spacer>
        <v-btn
          icon
          color="primary lighten-1"
          tile
          @click="openNewPost()"
          v-if="false"
        >
          <v-icon>mdi-plus-circle-outline</v-icon>
        </v-btn>
        <select-location ref="location" />
        <profile-menu />
      </div>
      <template v-slot:extension v-if="extension">
        <slot name="extension"></slot>
      </template>
    </v-app-bar>
    <please-wait v-if="loading"></please-wait>
    <div :class="fullWidth ? '' : 'px-4 px-md-0'" v-else>
      <slot></slot>
    </div>
    <v-bottom-navigation app active-class="primary--text" class="d-md-none">
      <v-btn to="/" @click="onClick">
        <span>Discover</span>
        <v-icon>mdi-compass</v-icon>
      </v-btn>
      <v-btn to="/people" @click="onClick">
        <span>People</span>
        <v-icon>mdi-human-handsdown</v-icon>
      </v-btn>
      <v-btn to="/feed" @click="onClick">
        <span>Feed</span>
        <v-icon>mdi-lightning-bolt</v-icon>
      </v-btn>
      <v-btn to="/groups" @click="onClick">
        <span>Groups</span>
        <v-icon>mdi-account-group</v-icon>
      </v-btn>
      <v-btn to="/chat-rooms" @click="onClick">
        <span>Chat</span>
        <v-icon>mdi-forum</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import { signOut } from "firebase/auth";
import { auth } from "@/utils/firebase.utils";
import ProfileMenu from "../public/ProfileMenu.vue";
import SelectLocation from "@/pages/public/SelectLocation.vue";
import PleaseWait from "./PleaseWait.vue";
export default {
  components: { ProfileMenu, SelectLocation, PleaseWait },
  props: {
    fluid: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    extension: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
  },
  computed: {
    isChatRoom() {
      return this.$route.name == "chat-rooms";
    },
    logo() {
      let { iconPath } = this.currentLocation;
      if (!this.isGroupSelected) return require("@/assets/img/logo-mini.png");
      return iconPath || require("@/assets/img/group.png");
    },
    showLocation() {
      let skipRoutes = [
        "post",
        "signup",
        "login",
        "profile",
        "update-profile",
        "create-group",
      ];
      return skipRoutes.indexOf(this.$route.name) == -1;
    },
    isLoginSignup() {
      let skipRoutes = [
        "signup",
        "login",
        "chat-rooms",
        "profile",
        "update-profile",
        "create-group",
      ];
      return skipRoutes.indexOf(this.$route.name) != -1;
    },
    isBack() {
      let backRoutes = [
        "profile",
        "post-view",
        "interests",
        "skills",
        "comments",
        "update-profile",
        "create-group",
        "join-group",
        "group-details",
        "post",
      ];
      return backRoutes.indexOf(this.$route.name) != -1;
    },
    isBottomNav() {
      let skipRoutes = ["comments"];
      return skipRoutes.indexOf(this.$route.name) == -1;
    },
  },
  methods: {
    onClick() {
      window.scroll({
        top: 0,
        left: 0,
      });
    },
    openNewPost() {
      this.$router.push("/post");
    },
    openLocation() {
      if (this.$refs.location) {
        this.$refs.location.dialog = true;
      }
    },
    logout() {
      this.$store.commit("SET_AUTH", false);
      signOut(auth);
    },
  },
};
</script>

<style lang="scss">
.app-layout-header {
  .v-toolbar__content {
    padding: 0px !important;
    max-width: 56rem;
    margin: 0 auto;
  }
}
.app-layout {
  max-width: 500px;
  .v-toolbar__content {
    padding: 0px !important;
    max-width: 500px;
    padding-bottom: 10px !important;
  }
  .v-toolbar__extension {
    max-width: 500px;
    margin: 0 auto;
  }
}
</style>
