<template>
  <v-dialog max-width="1000px" v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn
        depressed
        :loading="isLoading"
        class="primary"
        v-on="on"
        :icon="isMobile"
        color="white"
      >
        <template v-if="type == 'image'">
          <v-icon :left="!isMobile">mdi-image</v-icon>
          <span class="d-none d-md-block"> Add Image </span>
        </template>
        <template v-else>
          <v-icon :left="!isMobile">mdi-video</v-icon>
          <span class="d-none d-md-block"> Add Video </span>
        </template>
      </v-btn>
    </template>

    <dialog-card :isSubmit="false" :title="title" @close="dialog = false">
      <please-wait class="mt-4" v-if="isLoading" />
      <v-row>
        <v-col
          v-for="n in list"
          :key="n.id"
          class="d-flex child-flex gallery-item"
          cols="6"
          md="3"
        >
          <v-img :src="n.thumbnail" aspect-ratio="1" class="grey lighten-2">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <v-checkbox
            v-model="selected"
            @change="$emit('input', selected)"
            :value="n"
            class="mt-0"
          ></v-checkbox>
        </v-col>
      </v-row>
    </dialog-card>
  </v-dialog>
</template>

<script>
import { getDocs, orderBy, query, where } from "firebase/firestore";
import DialogCard from "../ui/DialogCard.vue";
import { colGallery } from "@/utils/firebase.utils";
import PleaseWait from "../ui/PleaseWait.vue";
export default {
  components: { DialogCard, PleaseWait },
  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
    type: {
      type: String,
      default: "image",
    },
  },
  data() {
    return {
      dialog: false,
      list: [],
      selected: [],
      isLoading: false,
    };
  },
  computed: {
    title() {
      return this.type == "image" ? "Select Image" : "Select Video";
    },
  },
  methods: {
    async fetchGallery() {
      const vm = this;
      try {
        vm.isLoading = true;
        let q = query(
          colGallery,
          orderBy("updated_at", "desc"),
          where("uid", "==", vm.uid),
          where("type", "==", vm.type)
        );
        vm.list = (await getDocs(q)).docs.map((i) => ({
          ...i.data(),
          id: i.id,
        }));
        vm.isLoading = false;
      } catch (error) {
        vm.handleError(error);
      }
    },
  },
  mounted() {
    this.fetchGallery();
  },
};
</script>

<style lang="scss">
.gallery-item {
  position: relative;
  .v-input--checkbox {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
</style>
