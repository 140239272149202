<template>
    <app-layout fluid>
    <div class="px-6 md:px-0 md:max-w-4xl mx-auto">
        <section class="hero flex pt-10 items-center flex-col">
            <div class="site-logo text-primary font-logo">
                <img src="../../assets/img/logo.png" width="300px" >
            </div>
            <h3 class="text-lg md:text-xl text-center mt-5 !leading-tight">
                India's First Local Social Network!
            </h3>
            <h1 class="text-xl md:text-4xl text-center font-bold my-5 !leading-relaxed">
                Launching best talent contest <span class="text-purple"> <br>#TalentOfVasaiVirar </span> <br>to
                showcase <span class="text-secondary-blue"> Vasai-Virar's</span> local talent! </h1>
            <!-- <h1 class="text-xl md:text-4xl  text-center font-bold mb-5 !leading-relaxed">
                You stand to <span class="text-primary">win</span>
            </h1> -->
            <div class="md:px-14 py-6 bg-sky-100 flex items-center flex-col rounded-3xl mb-10 w-full">
                <img src="../../assets/img/colorsOfVasai/amazon-git-card.svg" alt="">
                <p class="text-xl md:text-2xl text-center font-medium  !leading-relaxed">Upload a short video of your
                    best talent and
                    win
                </p>
                <div class="text-xl md:text-2xl text-center font-medium  !leading-relaxed">
                    <div class="flex justify-center">
                        <img src="../../assets/img/colorsOfVasai/amazon.svg" width="120" class="mt-3 mr-2" alt=" amazon ">
                        Gift cards worth
                    </div>
                    <span class="font-bold text-3xl text-blue-600 font-awesome">
                        Rs 3000, Rs 2000 and Rs 1000</style>
                    </span>
                </div>
            </div>
            <h1 class="text-xl md:text-4xl text-center font-bold my-5 !leading-relaxed">Am I eligible to participate?
            </h1>
            <p class="text-md md:text-xl text-center text-paragraph mb-14">If from Vasai-Virar you
                are eligible to participate, you can participate individually or as a group too! You can post on behalf
                of your child if s/he is under 13. For any clarifications please reach out to hello@goaround.me or on chat here on the website!</p>

            <h1 class="text-xl md:text-4xl text-center font-bold my-5 !leading-relaxed">What do I share?
            </h1>
            <p class="text-md md:text-xl text-center text-paragraph mb-14">Be it your singing, dancing, art / painting,
                an instrument you rock with, your band, a bike stunt or your best cricket/sports moment - submit a short
                video of your talent to win big & make a name for yourself in your area! 😎</p>  
                
                
                <!-- <h1 class="text-xl md:text-4xl   text-center font-bold my-5 !leading-relaxed">How can I win? </h1>
            <div class="w-full mb-8 pl-4">
                <div class="flex items-center">
                    <div class="flex items-center text-blue-600 relative">
                        <div
                            class="rounded-full text-center h-8 w-8 md:h-12 md:w-12 flex justify-center items-center font-bold bg-blue-100">
                            1 </div>
                        <div
                            class="absolute top-0 text-center -ml-12 md:-ml-10 mt-12 md:mt-16 w-32 font-bold text-black text-md md:text-lg">
                            Sign Up</div>
                    </div>
                    <div class="flex-auto border-dashed border-t md:border-t-2 border-blue-500  mx-3"></div>
                    <div class="flex items-center text-blue-600 relative">
                        <div
                            class="rounded-full text-center h-8 w-8 md:h-12 md:w-12 flex justify-center items-center font-bold bg-blue-100">
                            2 </div>
                        <div
                            class="absolute top-0  text-center -ml-12 md:-ml-10 mt-12 md:mt-16 w-32 font-bold text-black text-md md:text-lg">
                            Post</div>
                    </div>
                    <div class="flex-auto mx-3 border-dashed border-t md:border-t-2 border-blue-500"></div>
                    <div class="flex items-center text-blue-600 relative">
                        <div
                            class="rounded-full text-center h-8 w-8 md:h-12 md:w-12 flex justify-center items-center font-bold bg-blue-100">
                            3 </div>
                        <div
                            class="absolute top-0 text-center -ml-12 md:-ml-10 mt-12 md:mt-16 w-32 font-bold text-black text-md md:text-lg">
                            Share & get likes</div>
                    </div>
                </div>
            </div> -->
        </section>
        <div class="d-flex flex-column flex-md-row  justify-center mb-10">
                <v-btn large class="primary !rounded-3xl" @click="openCreatePost()" depressed>
                {{ isAuth? "Submit Your Entry": "Signup To Participate" }}</v-btn>
                <v-btn @click="openEntries()" outlined large class="my-4 my-md-0 mx-md-4 !rounded-3xl">View Submitted Entries</v-btn>
                <v-btn text large class="!rounded-3xl" color="primary" @click="copyLink">
                    <v-icon left>mdi-share-variant-outline</v-icon>
                    Share This Contest</v-btn>
            </div>        

        <!-- <h3 class="text-lg md:text-xl text-green-900 font-medium">Process </h3> -->
        <!-- <ol class="relative text-gray-500  ml-3 mt-5">
            <li class="mb-10 ml-6 flex"><span
                    class="absolute flex items-center justify-center w-8 h-8 bg-green-100 rounded-full -left-4 text-green-800 font-medium text-sm">01
                </span>
                <h3 class="text-sm md:text-lg font-medium leading-tight">Download the "around" app and sign up now</h3>
            </li>
            <li class="mb-10 ml-6 flex "><span
                    class="absolute flex items-center justify-center w-8 h-8 bg-green-100 rounded-full -left-4 text-green-800 font-medium text-sm">02
                </span>
                <h3 class="text-sm md:text-lg font-medium leading-tight">Upload a short video showcasing your talent!
                </h3>
            </li>
            <li class="mb-10 ml-6 flex"><span
                    class="absolute flex items-center justify-center w-8 h-8 bg-green-100 rounded-full -left-4 text-green-800 font-medium text-sm">03
                </span>
                <h3 class="text-sm md:text-lg font-medium leading-tight">Add the hashtag #TalentOfVasaiVirar to your
                    post </h3>
            </li>
            <li class="mb-10 ml-6 flex"><span
                    class="absolute flex items-center justify-center w-8 h-8 bg-green-100 rounded-full -left-4 text-green-800 font-medium text-sm">04
                </span>
                <h3 class="text-sm md:text-lg font-medium leading-tight">Share your "around" post on your
                    Instagram/Facebook handle!</h3>
            </li>
            <li class="mb-10 ml-6 flex"><span
                    class="absolute flex items-center justify-center w-8 h-8 bg-green-100 rounded-full -left-4 text-green-800 font-medium text-sm">04
                </span>
                <h3 class="text-sm md:text-lg font-medium leading-tight">Get your post maximum hearts ❤️ from your
                    neighbours,
                    friends and family!</h3>
            </li>
        </ol> -->
        <!-- <h1 class="text-xl md:text-4xl   text-center font-bold mb-5 mt-16 !leading-relaxed">Categories </h1>
        <p class="text-sm md:text-lg text-paragraph text-center mb-8">Let us unleash Vasai's flavour in the following
            categories </p>
        <div class="grid gap-y-3 md:gap-12 grid-cols-1 md:grid-cols-2">
            <div class="flex flex-col items-center mb-8 md:mb-0"><img src="../../assets/img/TalentOfVasaiVirar/nature.png"
                    class="w-full" alt="" style="object-fit:cover">
                <h2 class="text-lg md:text-2xl text-center font-medium mt-3 mb-1 !leading-relaxed">#Nature </h2>
                <p class="text-sm md:text-md text-paragraph text-center">Vasai is a picturesque place,
                    let the beauty of Vasai come to fore with your posts under this hashtag </p>
            </div>
            <div class="flex flex-col items-center mb-8 md:mb-0"><img src="../../assets/img/TalentOfVasaiVirar/beaches.png"
                    class="w-full" alt="" style="object-fit:cover">
                <h2 class="text-lg md:text-2xl text-center font-medium mt-3 mb-1 !leading-relaxed">#Beaches </h2>
                <p class="text-sm md:text-md text-paragraph text-center">Click and share your best pics from the
                    beautiful Vasai beaches </p>
            </div>
            <div class="flex flex-col items-center mb-8"><img src="../../assets/img/TalentOfVasaiVirar/culture.png" class="w-full"
                    alt="" style="object-fit:cover">
                <h2 class="text-lg md:text-2xl text-center font-medium mt-3 mb-1 !leading-relaxed">#Culture </h2>
                <p class="text-sm md:text-md text-paragraph text-center">Share the unique art and culture you want to
                    showcase from Vasai </p>
            </div>
            <div class="flex flex-col items-center mb-8"><img src="../../assets/img/TalentOfVasaiVirar/places.png" class="w-full"
                    alt="" style="object-fit:cover">
                <h2 class="text-lg md:text-2xl text-center font-medium mt-3 mb-1 !leading-relaxed">#Places </h2>
                <p class="text-sm  md:text-md text-paragraph text-center">Historic places,
                    ponds,
                    temples / churches,
                    farms,
                    any place you like ! :-) </p>
            </div>
        </div> -->
        <!-- <h1 class="text-xl md:text-4xl   text-center font-bold my-5 !leading-relaxed">Contest rules and how the winners
            are decided? </h1>
        <ol class="text-gray-500 mt-5 md:ml-4">
            <li class="mb-8 flex">
                <div><span
                        class="flex items-center justify-center w-8 h-8 bg-green-100 rounded-full text-green-800 font-medium text-sm">01
                    </span></div>
                <h3 class="text-sm  md:text-lg font-medium ml-4 leading-tight">Your post must have a video / photos of
                    your talent and hashtag
                    #TalentOfVasaiVirar </h3>
            </li>
            <li class="mb-8 flex">
                <div><span
                        class="flex items-center justify-center w-8 h-8 bg-green-100 rounded-full text-green-800 font-medium text-sm">02
                    </span></div>
                <h3 class="text-sm md:text-lg font-medium ml-4 leading-tight">You must share the link to your "around"
                    post on your social handles to ask your friends to vote for you!</h3>
            </li>
            <li class="mb-8 flex">
                <div><span
                        class="flex items-center justify-center w-8 h-8 bg-green-100 rounded-full text-green-800 font-medium text-sm">03
                    </span></div>
                <h3 class="text-sm md:text-lg font-medium ml-4 leading-tight">You need to have minimum 10 hearts ❤️ to
                    your entry to qualify to win </h3>
            </li>
            <li class="mb-8 flex">
                <div><span
                        class="flex items-center justify-center w-8 h-8 bg-green-100 rounded-full text-green-800 font-medium text-sm">04
                    </span></div>
                <h3 class="text-sm md:text-lg font-medium ml-4 leading-tight">The winners are decided by a combination
                    of most likes (❤️) on the post and our panel </h3>
            </li>
            <li class="mb-8 flex">
                <div><span
                        class="flex items-center justify-center w-8 h-8 bg-green-100 rounded-full text-green-800 font-medium text-sm">05
                    </span></div>
                <h3 class="text-sm md:text-lg font-medium ml-4 leading-tight">Posting obscene / vulgure content is
                    stricly prohibited! </h3>
            </li>
            <li class="mb-8 flex">
                <div><span
                        class="flex items-center justify-center w-8 h-8 bg-green-100 rounded-full text-green-800 font-medium text-sm">06
                    </span></div>
                <h3 class="text-sm md:text-lg font-medium ml-4 leading-tight">You must submit your entry by 12pm on 27th
                    August 2023 </h3>
            </li>
        </ol> -->
        <div class="px-3 md:px-14 py-6 bg-sky-100 flex items-center flex-col rounded-3xl mb-14">
            <h1 class="text-xl md:text-4xl   text-center font-bold !leading-relaxed">Prizes and distribution </h1><img
                src="../../assets/img/colorsOfVasai/prices-distribution.svg" class="my-5" alt="">
            <ol class="text-gray-500 mt-5 md:ml-4">
                <li class="mb-8 flex">
                    <div><span
                            class="flex items-center justify-center w-8 h-8 bg-green-100 rounded-full text-green-800 font-medium text-sm">01
                        </span></div>
                    <h3 class="text-sm md:text-lg font-medium ml-4 leading-tight">There will be 3 prizes of Amazon Gift
                        Cards worth <span class="text-secondary-blue">First Prize: Rs 3000 | Second Prize: Rs 2000 |
                            Third Prize: Rs 1000</span> </h3>
                </li>
                <li class="mb-8 flex">
                    <div><span
                            class="flex items-center justify-center w-8 h-8 bg-green-100 rounded-full text-green-800 font-medium text-sm">02
                        </span></div>
                    <h3 class="text-sm md:text-lg font-medium ml-4 leading-tight">Winning entries will be chosen on the basis of most likes! The winning entries will be declared on 12PM on 3rd Sep 2023 </h3>
                </li>
                <li class=" flex">
                    <div><span
                            class="flex items-center justify-center w-8 h-8 bg-green-100 rounded-full text-green-800 font-medium text-sm">03
                        </span></div>
                    <h3 class="text-sm md:text-lg font-medium ml-4 leading-tight">Your Amazon Gift card will be handed
                        to you by us by 3rd Sep 2023 in person and we'll post on
                        "around" and on your social handles! </h3>
                </li>
            </ol>
        </div>
        <!-- <h1 class="text-xl md:text-4xl text-center font-bold !leading-relaxed">Few Sample Posts/Submissions </h1>
        <div class="grid gap-y-3 md:gap-12 md:grid-cols-2 my-10">
            <div class="p-4 flex items-center flex-col rounded-3xl shadow-lg shadow-blue-100"><img
                    src="../../assets/img/TalentOfVasaiVirar/beach-post-1.png" class="w-full" alt="">
                <p class="text-sm md:text-md text-secondary-blue mt-4">#TalentOfVasaiVirar #Beaches </p>
            </div>
            <div class="p-4 flex items-center flex-col rounded-3xl shadow-lg shadow-blue-100"><img
                    src="../../assets/img/TalentOfVasaiVirar/place-post-1.png" class="w-full" alt="">
                <p class="text-sm md:text-md text-secondary-blue mt-4">#TalentOfVasaiVirar #Places </p>
            </div>
        </div> -->
        <div class="px-8 md:px-14 py-6 bg-sky-100 flex items-center flex-col rounded-3xl my-10">
            <h3 class="text-sm md:text-xl font-medium text-center md:!leading-loose">So Hurry Up ! Get your creative
                juices flowing and send your best talent at the earliest on "around" to win!
            </h3>
        </div>
        <h1 class="text-xl md:text-4xl text-center font-bold my-5 !leading-relaxed"><span
                class="text-purple">#TalentOfVasaiVirar </span><span class="text-secondary-blue">#Vasai-Virar</span>
        </h1>
        <!-- <h1 class="text-xl md:text-4xl   text-center font-bold !leading-relaxed mt-10">Download the app to join now!
        </h1>
        <div class="text-secondary-blue space-x-6 md:space-x-12 flex justify-center mt-4 mb-14"><a
                href="https://around.app.link/6GnyLH5qExb" class="mt-5 md:mt-0 md:ml-3" target="_blank"
                style=" border-radius: 13px;"><img src="../../assets/img/appstore.svg" class="w-32 md:w-44" alt=""></a><a
                href='https://around.app.link/6GnyLH5qExb' target="_blank"><img src="../../assets/img/googleplay.svg"
                    class="w-32 md:w-44" alt=""></a></div> -->
        <div class="my-10">
            <div class="d-flex flex-column flex-md-row justify-center">
                <v-btn large class="primary !rounded-3xl" @click="openCreatePost()" depressed>
                {{ isAuth? "Submit Your Entry": "Signup To Participate" }}</v-btn>
                <v-btn @click="openEntries()" outlined large class="my-4 my-md-0 mx-md-4 !rounded-3xl">View Submitted Entries</v-btn>
                <v-btn text large class="!rounded-3xl" color="primary"  @click="copyLink">
                    <v-icon left>mdi-share-variant-outline</v-icon>
                    Share This Contest</v-btn>
            </div>        
                

            <img src="../../assets/img/flyers/3.png" class="my-10"/>
            <div class="d-flex flex-column flex-md-row justify-center">
                <v-btn large class="primary !rounded-3xl" @click="openCreatePost()" depressed>
                {{ isAuth? "Submit Your Entry": "Signup To Participate" }}</v-btn>
                <v-btn @click="openEntries()" outlined large class="my-4 my-md-0 mx-md-4 !rounded-3xl">View Submitted Entries</v-btn>
                <v-btn text large class="!rounded-3xl" color="primary"  @click="copyLink">
                    <v-icon left>mdi-share-variant-outline</v-icon>
                    Share This Contest</v-btn>
            </div>        
                

        </div>
        <!-- <h1 class="text-xl md:text-4xl   text-center font-bold !leading-relaxed mt-10">Download the app to join now!
        </h1>
        <div class="text-secondary-blue space-x-6 md:space-x-12 flex justify-center mt-4 mb-14"><a
                href="https://around.app.link/6GnyLH5qExb" class="mt-5 md:mt-0 md:ml-3" target="_blank"
                style=" border-radius: 13px;"><img src="../../assets/img/appstore.svg" class="w-32 md:w-44" alt=""></a><a
                href='https://around.app.link/6GnyLH5qExb' target="_blank"><img src="../../assets/img/googleplay.svg"
                    class="w-32 md:w-44" alt=""></a></div> -->
                    <website-footer></website-footer>

    </div>
    </app-layout>
</template>

<script>
import WebsiteFooter from "@/components/website/WebsiteFooter.vue";
import AppLayout from "@/components/ui/AppLayout.vue";

export default {
  components: { WebsiteFooter,AppLayout },
  methods: {
    openEntries() {
        this.$router.push('/feed?hashtags=TalentOfVasaiVirar')
    },  
    copyLink() {
        const vm = this;
      let path = vm.$router.resolve({
        name: "TalentOfVasaiVirar",
      });
      let url = `https://${location.host}${path.href}`;
      navigator.clipboard.writeText(url);
      vm.$snackbar.show("Contest page link copied to clipboard. You can share it with your friends & family!");

    },
    openCreatePost() {
      if (!this.isAuth) {
        this.$router.push("/signup?redirect=/post?hashtag=TalentOfVasaiVirar");
      } else {
        this.$router.push({
          name: "post",
          query: {
            hashtag: "TalentOfVasaiVirar",
          },
        });
      }
    },
  },
};
</script>

<style></style>
