<template>
  <v-navigation-drawer
    app
    width="250"
    v-model="drawer"
    class="white"
    v-if="isAuth"
    @input="$emit('input', drawer)"
  >
    <div
      class="text-center pa-1 pt-2 d-flex justify-center"
      style="height: 63px"
    >
      <img
        src="../assets/img/logo.png"
        width="130"
        height="50"
        style="object-fit: contain"
      />
    </div>
    <v-divider></v-divider>
    <v-list dense nav class="pa-3">
      <template v-for="(item, i) in menus">
        <v-list-item :class="active(item)" :key="i" link @click="open(item)">
          <v-list-item-avatar size="22" tile>
            <v-icon size="20"> {{ item.icon }} </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="subtitle-1">{{
              item.title
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
export default {
  props: {
    value: Boolean,
  },
  data() {
    return {
      drawer: true,
      menus: [
        {
          name: "fly",
          title: "Posts",
          icon: "mdi-view-dashboard",
          names: ["create-post"],
        },
        {
          name: "connected-apps",
          title: "Connected Apps",
          icon: "mdi-connection",
          names: [],
        },
        {
          name: "gallery",
          title: "Gallery",
          icon: "mdi-connection",
          names: ["update-gallery-image", "update-gallery-video"],
        },
      ],
    };
  },
  watch: {
    value(val) {
      this.drawer = val;
    },
  },
  methods: {
    active(item) {
      let { names } = item;
      let { name } = this.$route;
      let isActive = name == item.name || names.indexOf(name) != -1;
      return isActive ? "secondary white--text drawer__active" : "";
    },
    open({ name }) {
      this.$router.push({ name });
    },
  },
};
</script>
<style lang="scss">
.v-navigation-drawer {
  .drawer-menu {
    height: calc(100vh - 70px);
  }
  .v-list-group__items {
    .v-list-item {
      padding-left: 30px !important;
    }
  }
}
.drawer__active i {
  color: white !important;
  // border-radius: $border-radius !important;
}
</style>
