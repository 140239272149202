<template>
  <div>
    <please-wait v-if="isLoading"></please-wait>
    <PinturaEditor
      v-else
      v-bind="editorProps"
      ref="editor"
      :style="editerStyle"
      @pintura:process="handleProcess($event)"
    />
  </div>
</template>
  <script>
import "@pqina/pintura/pintura.css";
import { PinturaEditor } from "@pqina/vue-pintura/vue-2";
import { getEditorDefaults } from "@pqina/pintura";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { colGallery, storage } from "@/utils/firebase.utils";
import PleaseWait from "@/components/ui/PleaseWait.vue";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { getDate } from "@/utils/common.utils";

export default {
  name: "App",
  components: {
    PinturaEditor,
    PleaseWait,
  },
  data() {
    return {
      editorProps: getEditorDefaults(),
      inlineResult: "",
      isLoading: false,
    };
  },
  computed: {
    editerStyle() {
      let height = window.innerHeight - 64 + "px";
      return { height };
    },
  },
  methods: {
    async handleProcess(event) {
      const vm = this;
      try {
        vm.isLoading = true;
        let id = vm.$route.params.id;
        let imageRef = ref(storage, `gallery/${this.uid}/${id}_thumb.png`);
        await uploadBytes(imageRef, event.dest);
        let thumbnail = await getDownloadURL(imageRef);
        let data = {
          thumbnail,
          json: JSON.stringify(event.imageState),
          updated_at: getDate(),
        };
        await updateDoc(doc(colGallery, id), data);
        vm.isLoading = false;
        vm.$router.replace("/gallery");
      } catch (error) {
        vm.handleError(error);
      }
    },
    async fetchImage() {
      const vm = this;
      try {
        vm.isLoading = true;
        let id = vm.$route.params.id;
        let image = (await getDoc(doc(colGallery, id))).data();
        vm.isLoading = false;
        vm.$nextTick(() => {
          let json = {};
          try {
            json = JSON.parse(image.json);
          } catch (error) {
            console.error(error);
          }
          vm.$refs.editor.editor.loadImage(image.url, json);
        });
      } catch (error) {
        vm.handleError(error);
      }
    },
  },
  mounted() {
    this.fetchImage();
  },
};
</script>