<template>
  <div>
    <div class="d-flex mt-5 justify-space-between align-center">
      <div class="subtitle-1 font-weight-medium">
        {{ title }}
      </div>
      <a
        class="subtitle-2 font-weight-medium blue--text text-decoration-underline"
        @click="$router.push('/feed')"
        v-if="posts.length"
        >Show All</a
      >
      <div
        v-else
        class="subtitle-2 font-weight-medium grey--text text-decoration-underline"
      >
        Show All
      </div>
    </div>
    <v-slide-group
      multiple
      show-arrows
      v-if="posts.length"
      :max="2"
      class="people-slide"
      style="height: 191px"
    >
      <v-slide-item v-for="(post, i) in posts" :key="i">
        <v-card
          class="mr-3 my-1"
          width="160px"
          @click="$router.push(`/post/${post.id}`)"
          outlined
          rounded="lg"
          style="overflow: hidden"
        >
          <v-img
            height="125"
            cover
            v-if="getImage(post)"
            :src="getImage(post)"
          />
          <v-card color="primary lighten-3 pa-2" flat height="125" v-else tile>
            <div class="subtitle-2 latest-post--content">
              {{ post.content }}
            </div>
          </v-card>
          <v-list-item class="px-1" dense>
            <v-list-item-avatar class="mr-1">
              <v-avatar color="secondary" size="30">
                <v-img :src="getUserProfileUrl(post.user)"></v-img>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="subtitle-2">{{
                post.user.display_name
              }}</v-list-item-title>
              <v-list-item-subtitle class="caption">{{
                getTimeAgo(post)
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-slide-item>
    </v-slide-group>
    <div
      v-else
      style="height: 170px"
      class="d-flex flex-column align-center justify-center text-center"
    >
      <div class="text-body-2 mb-2">
        Be the first one to share<br />
        with
        {{
          isGroupSelected
            ? `members of ${currentLocation.name}`
            : "people around!"
        }}
      </div>
      <v-btn small outlined @click="openCreate()" color="primary"
        >Create a post</v-btn
      >
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    posts: {
      type: Array,
      default: () => [],
    },
    search: {
      type: String,
      default: "",
    },
  },
  computed: {
    title() {
      let search = this.search.trim();
      if (search) return `Posts matching: ${search}`;
      return this.isGroupSelected ? "Latest posts" : " Latest around";
    },
  },
  methods: {
    getImage({ video_thumb, image }) {
      if (image && image[0]) return image[0];
      return video_thumb || "";
    },
    getTimeAgo(post) {
      return moment(post.timeanddateofpost.seconds * 1000).fromNow();
    },
    openCreate() {
      const vm = this;
      if (!vm.confirmLogin()) return;
      vm.$router.push("/post");
    },
  },
};
</script>

<style>
.latest-post--content {
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  overflow-wrap: break-word;
}
</style>
