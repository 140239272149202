<template>
  <div class="app-layout mx-auto">
    <v-list-item class="px-1">
      <v-list-item-avatar width="80px" class="mr-0">
        <v-btn style="margin-left: -10px" icon @click="$router.go(-1)"
          ><v-icon>mdi-arrow-left</v-icon></v-btn
        >
        <user-logo :size="40" :user="user"></user-logo>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title> {{ user.display_name }}</v-list-item-title>
        <v-list-item-subtitle v-if="user.hashtag" class="primary--text">
          {{ user.hashtag }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>
    <vue-advanced-chat
      ref="chatWindow"
      :height="chatRoomHeight + 'px'"
      :current-user-id="uid"
      :room-id="roomId"
      :styles="JSON.stringify(styles)"
      :single-room="true"
      :show-footer="true"
      :rooms="JSON.stringify(rooms)"
      :loading-rooms="loadingRooms"
      :rooms-loaded="roomsLoaded"
      :messages="JSON.stringify(messages)"
      :messages-loaded="messagesLoaded"
      :room-message="roomMessage"
      :message-actions="JSON.stringify(messageActions)"
      :room-actions="JSON.stringify(roomActions)"
      :menu-actions="JSON.stringify(menuActions)"
      :message-selection-actions="JSON.stringify(messageSelectionActions)"
      :templates-text="JSON.stringify(templatesText)"
      @fetch-messages="fetchMessages($event.detail[0])"
      @send-message="sendMessage($event.detail[0])"
      @edit-message="editMessage($event.detail[0])"
      @delete-message="deleteMessage($event.detail[0])"
      @typing-message="onTypeMessage"
      @send-message-reaction="sendMessageReaction($event.detail[0])"
    >
    </vue-advanced-chat>
  </div>
</template>

<script>
import { register } from "vue-advanced-chat";
import * as firestoreService from "@/utils/firebase.chat.firstore.js";
import * as storageService from "@/utils/firebase.chat.storage.js";
import UserLogo from "@/components/public/UserLogo.vue";
import { parseTimestamp } from "@/utils/common.utils";
register();
export default {
  components: { UserLogo },
  name: "ChatRoom",
  data() {
    return {
      user: {},
      styles: {
        container: {
          border: "none",
          borderRadius: "0px",
          boxShadow: "unset",
        },
        content: {
          background: "white",
        },
        footer: {
          background: "white",
        },
        message: {
          background: "#f6f6f4",
          backgroundMe: "#1773ea4f",
          backgroundReactionHoverMe: "red",
        },
      },
      rooms: [],
      loadingRooms: false,
      roomsLoaded: false,
      messages: [],
      messagesLoaded: false,
      roomMessage: "",
      lastLoadedMessage: null,
      previousLastLoadedMessage: null,
      roomActions: [],
      menuActions: [],
      messageActions: [
        {
          name: "replyMessage",
          title: "Reply",
        },
        {
          name: "editMessage",
          title: "Edit Message",
          onlyMe: true,
        },
        {
          name: "deleteMessage",
          title: "Delete Message",
          onlyMe: true,
        },
      ],
      templatesText: [],
      messageSelectionActions: [{ name: "deleteMessages", title: "Delete" }],
      listener: null,
      chatRoomHeight: 0,
    };
  },
  computed: {
    screenHeight() {
      return window.innerHeight - 63;
    },
    roomId() {
      return this.$route.params.id;
    },
  },
  methods: {
    async sendMessage({ content, roomId, files, replyMessage }) {
      const message = {
        sender_id: this.uid,
        content,
        timestamp: new Date(),
      };

      if (files) {
        message.files = this.formattedFiles(files);
      }

      if (replyMessage) {
        message.replyMessage = {
          _id: replyMessage._id,
          content: replyMessage.content,
          sender_id: replyMessage.senderId,
        };

        if (replyMessage.files) {
          message.replyMessage.files = replyMessage.files;
        }
      }

      const { id } = await firestoreService.addMessage(roomId, message);

      if (files) {
        for (let index = 0; index < files.length; index++) {
          await this.uploadFile({ file: files[index], messageId: id, roomId });
        }
      }

      firestoreService.updateRoom(roomId, { lastUpdated: new Date() });
    },
    formattedFiles(files) {
      const formattedFiles = [];

      files.forEach((file) => {
        const messageFile = {
          name: file.name,
          size: file.size,
          type: file.type,
          extension: file.extension || file.type,
          url: file.url || file.localUrl,
        };

        if (file.audio) {
          messageFile.audio = true;
          messageFile.duration = file.duration;
        }

        formattedFiles.push(messageFile);
      });

      return formattedFiles;
    },

    async editMessage({ messageId, newContent, roomId, files }) {
      const newMessage = { edited: new Date() };
      newMessage.content = newContent;

      if (files) {
        newMessage.files = this.formattedFiles(files);
      } else {
        newMessage.files = firestoreService.deleteDbField;
      }

      await firestoreService.updateMessage(roomId, messageId, newMessage);

      if (files) {
        for (let index = 0; index < files.length; index++) {
          if (files[index]?.blob) {
            await this.uploadFile({ file: files[index], messageId, roomId });
          }
        }
      }
    },
    async uploadFile({ file, messageId, roomId }) {
      return new Promise((resolve) => {
        let type = file.extension || file.type;
        if (type === "svg" || type === "pdf") {
          type = file.type;
        }

        storageService.listenUploadImageProgress(
          this.uid,
          messageId,
          file,
          type,
          (progress) => {
            this.updateFileProgress(messageId, file.localUrl, progress);
          },
          () => {
            resolve(false);
          },
          async (url) => {
            const message = await firestoreService.getMessage(
              roomId,
              messageId
            );

            message.files.forEach((f) => {
              if (f.url === file.localUrl) {
                f.url = url;
              }
            });

            await firestoreService.updateMessage(roomId, messageId, {
              files: message.files,
            });
            resolve(true);
          }
        );
      });
    },
    updateFileProgress(messageId, fileUrl, progress) {
      const message = this.messages.find(
        (message) => message._id === messageId
      );

      if (!message || !message.files) return;

      message.files.find((file) => file.url === fileUrl).progress = progress;
      this.messages = [...this.messages];
    },
    async fetchRoom() {
      const vm = this;
      try {
        vm.loadingRooms = true;
        let room = await firestoreService.getRoom(vm.roomId);
        let users = room.users.filter((i) => i != vm.uid);
        users = await firestoreService.getRoomUsers(users);
        if (room.chat_type == "group") {
          vm.user = {
            photo_url: room.group_icon,
            display_name: room.group_name,
            hashtag: room.hashtag,
          };
        } else {
          vm.user = users[0];
        }
        users.push({
          ...vm.authUser,
          _id: vm.uid,
          username: vm.authUser.username,
        });
        vm.rooms = [{ ...room, roomId: vm.roomId, roomName: "", users }];
        vm.loadingRooms = false;
        vm.roomsLoaded = true;
      } catch (error) {
        vm.handleError(error);
      }
    },
    resetMessages() {
      this.messages = [];
      this.messagesLoaded = false;
      this.lastLoadedMessage = null;
      this.previousLastLoadedMessage = null;
    },
    fetchMessages({ room, options = {} }) {
      const vm = this;

      if (options.reset) {
        vm.resetMessages();
      }

      if (vm.previousLastLoadedMessage && !vm.lastLoadedMessage) {
        vm.messagesLoaded = true;
        return;
      }

      firestoreService
        .getMessages(room.roomId, 20, this.lastLoadedMessage)
        .then(({ data, docs }) => {
          if (data.length === 0 || data.length < this.messagesPerPage) {
            setTimeout(() => {
              this.messagesLoaded = true;
            }, 0);
          }
          if (options.reset) this.messages = [];
          data.forEach((message) => {
            const formattedMessage = this.formatMessage(room, message);
            this.messages.unshift(formattedMessage);
          });
          if (this.lastLoadedMessage) {
            this.previousLastLoadedMessage = this.lastLoadedMessage;
          }
          this.lastLoadedMessage = docs[docs.length - 1];
          this.listenMessages(room);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    listenMessages(room) {
      this.listener = firestoreService.listenMessages(
        room.roomId,
        this.lastLoadedMessage,
        this.previousLastLoadedMessage,
        (messages) => {
          messages.forEach((message) => {
            const formattedMessage = this.formatMessage(room, message);
            const messageIndex = this.messages.findIndex(
              (m) => m._id === message.id
            );

            if (messageIndex === -1) {
              this.messages = this.messages.concat([formattedMessage]);
            } else {
              this.messages[messageIndex] = formattedMessage;
              this.messages = [...this.messages];
            }

            this.markMessagesSeen(room, message);
          });
        }
      );
    },
    markMessagesSeen(room, message) {
      if (
        message.sender_id !== this.uid &&
        (!message.seen || !message.seen[this.uid])
      ) {
        firestoreService.updateMessage(room.roomId, message.id, {
          [`seen.${this.uid}`]: new Date(),
        });
      }
    },

    formatMessage(room, message) {
      const formattedMessage = {
        ...message,
        ...{
          senderId: message.sender_id,
          _id: message.id,
          seconds: message.timestamp.seconds,
          timestamp: parseTimestamp(message.timestamp, "HH:mm"),
          date: parseTimestamp(message.timestamp, "DD MMMM YYYY"),
          username: room.users.find((user) => message.sender_id === user._id)
            ?.username,
          distributed: true,
        },
      };

      if (message.replyMessage) {
        formattedMessage.replyMessage = {
          ...message.replyMessage,
          ...{
            senderId: message.replyMessage.sender_id,
          },
        };
      }

      return formattedMessage;
    },

    async deleteMessage({ message, roomId }) {
      await firestoreService.updateMessage(roomId, message._id, {
        deleted: new Date(),
      });

      const { files } = message;

      if (files) {
        files.forEach((file) => {
          storageService.deleteFile(this.uid, message._id, file);
        });
      }
    },
    async sendMessageReaction({ reaction, remove, messageId, roomId }) {
      firestoreService.updateMessageReactions(
        roomId,
        messageId,
        this.uid,
        reaction.unicode,
        remove ? "remove" : "add"
      );
    },
    getDimensions() {
      this.chatRoomHeight = window.visualViewport.height - 63;
    },
    onTypeMessage() {
      this.getDimensions();
    },
  },
  beforeDestroy() {
    if (this.listener) this.listener();
  },
  unmounted() {
    window.removeEventListener("resize", this.getDimensions);
  },
  mounted() {
    window.addEventListener("resize", this.getDimensions);
    this.chatRoomHeight = this.screenHeight;

    this.fetchRoom();
    this.$nextTick(() => {
      const style = document.createElement("style");
      style.innerHTML =
        ".vac-room-header { display: none !important; } .messages-list {margin-top: 0px} .vac-col-messages .vac-container-scroll {margin-top: 0px}";
      this.$refs.chatWindow.shadowRoot.appendChild(style);
    });
  },
};
</script>

<style>
.chat-room--header {
  width: 100%;
  height: 60px;
}
</style>
