<template>
  <v-dialog v-model="dialog" max-width="500px">
    <!-- <template v-slot:activator="{ on }">
      <v-btn icon color="primary lighten-1" v-on="on" tile>
        <v-icon>mdi-map-marker</v-icon>
      </v-btn>
    </template> -->
    <dialog-card
      :isSubmit="false"
      :title="isAuth ? 'Location / Group' : 'Location'"
      @close="dialog = false"
    >
      <template v-if="isAuth && location">
        <v-btn
          outlined
          @click="useResidential()"
          class="px-4 py-2 text-center"
          color="primary"
          block
        >
          <v-icon size="18px" left>mdi-home</v-icon>
          {{ location }}
        </v-btn>
        <div class="text-center my-2">or</div>
      </template>
      <v-btn
        outlined
        block
        :loading="loading"
        @click="useCurrent()"
        color="primary"
        >Use Current Location</v-btn
      >
      <div class="text-center mt-1">or</div>
      <address-field
        hide-details
        @onAddress="onAddress"
        label="Type Location"
      ></address-field>
      <template v-if="isAuth">
        <div class="text-center mt-1">or</div>
        <label class="input-label">Select Group</label>
        <v-select
          :items="groups"
          outlined
          hide-details
          @change="onGroupChange"
          dense
          v-model="group"
          color="blue-grey lighten-2"
          return-object
          item-text="name"
          item-value="name"
          ><template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              @click="data.select"
            >
              <v-avatar left>
                <v-img
                  v-if="data.item.iconPath"
                  :src="data.item.iconPath"
                ></v-img>
                <v-img src="@/assets/img/group.png" v-else></v-img>
              </v-avatar>
              {{ data.item.name }}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <template v-if="typeof data.item !== 'object'">
              <v-list-item-content v-text="data.item"></v-list-item-content>
            </template>
            <template v-else>
              <v-list-item-avatar>
                <v-img
                  v-if="data.item.iconPath"
                  :src="data.item.iconPath"
                ></v-img>
                <v-img src="@/assets/img/group.png" v-else></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-html="data.item.name"></v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
        </v-select>
      </template>
      <div class="pb-5"></div>
    </dialog-card>
  </v-dialog>
</template>

<script>
import DialogCard from "@/components/ui/DialogCard.vue";
import AddressField from "@/components/ui/form/AddressField.vue";
import axios from "axios";
import { MAP_API_KEY } from "@/assets/constants/config.constants";
import { get, chunk } from "lodash";
import { colCohorts } from "@/utils/firebase.utils";
import { documentId, getDocs, query, where } from "firebase/firestore";
export default {
  components: { DialogCard, AddressField },
  data() {
    return {
      dialog: false,
      isGroupLoading: false,
      loading: false,
      groups: [],
      group: null,
    };
  },
  watch: {
    dialog(val) {
      if (val) {
        this.fetchGroups();
      }
    },
  },
  computed: {
    location() {
      let location = get(this.authUser, "residential", null);
      let name = location ? location.Address : "";
      if (name) {
        name = name.split(",")[0];
      }
      if (name.length > 21) {
        return name.slice(0, 21) + "...";
      }
      return name;
    },
  },
  methods: {
    onGroupChange() {
      this.dialog = false;
      this.$nextTick(() => {
        console.log(this.group);
        let group = JSON.parse(JSON.stringify(this.group));
        this.$store.commit("SET_CURRENT_LOCATION", {
          ...group,
          type: "group",
        });
      });
    },
    useResidential() {
      let location = get(this.authUser, "residential", null);
      this.$store.commit("SET_CURRENT_LOCATION", {
        name: this.location,
        latlong: `${location.Latlang.latitude}, ${location.Latlang.longitude}`,
        type: "location",
      });
      this.dialog = false;
    },
    async fetchGroups() {
      const vm = this;
      let cohorts = [...(vm.authUser.cohorts || [])].reverse();
      if (!this.isAuth || cohorts.length == 0) {
        return;
      }
      try {
        vm.isGroupLoading = true;
        let cohortIds = cohorts.map((i) => vm.getDocPath(i));
        let groups = [];
        for (let ids of chunk(cohortIds, 10)) {
          if (ids.length != 0) {
            let cohortQuery = query(colCohorts, where(documentId(), "in", ids));
            let cohortDocs = (await getDocs(cohortQuery)).docs;
            cohortDocs = cohortDocs.map((i) => ({
              ...i.data(),
              id: i.id,
              value: i.id,
            }));
            groups = groups.concat(cohortDocs);
          }
        }
        vm.groups = groups;
        vm.isGroupLoading = false;
      } catch (error) {
        vm.isGroupLoading = false;
        vm.handleError(error);
      }
    },
    onAddress(e) {
      if (e) {
        this.$store.commit("SET_CURRENT_LOCATION", {
          name: e.name,
          latlong: `${e.latitude}, ${e.longitude}`,
          type: "location",
        });
        this.dialog = false;
      }
    },
    useCurrent() {
      const vm = this;
      vm.loading = true;
      if ("geolocation" in navigator) {
        // Get the user's current position
        navigator.geolocation.getCurrentPosition(
          function (position) {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;

            let apiKey = MAP_API_KEY;
            const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
            axios
              .get(apiUrl)
              .then((res) => {
                let results = res.data.results;
                vm.loading = false;
                if (results.length == 0) {
                  vm.$alert("Nothing found here!");
                  return;
                }
                let subLocality = results[0].address_components.find(
                  (i) => i.types.indexOf("sublocality") != -1
                );
                let locality = results[0].address_components.find(
                  (i) => i.types.indexOf("locality") != -1
                );
                let name = "";
                if (subLocality) {
                  name = subLocality.short_name;
                } else if (locality) {
                  name = locality.short_name;
                }
                if (name) {
                  vm.$store.commit("SET_CURRENT_LOCATION", {
                    name,
                    latlong: `${latitude}, ${longitude}`,
                    type: "location",
                  });
                  vm.dialog = false;
                  return;
                }

                vm.$alert.show("Nothing found here!");
              })
              .catch((e) => {
                vm.handleError(e);
              });

            // Now you can use the latitude and longitude values as needed
          },
          function (error) {
            vm.loading = false;
            vm.$alert.show(
              "Please enable location access for goaround.me.",
              "Access Needed"
            );
            console.error("Error getting location:", error.message);
          }
        );
      } else {
        vm.loading = false;
        vm.$alert.show("Geolocation is not supported by this browser.");
        console.log("Geolocation is not supported by this browser.");
      }
    },
  },
};
</script>

<style></style>
