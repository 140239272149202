<template>
  <app-layout title="Comments">
    <div class="comments">
      <v-skeleton-loader
        slot="spinner"
        v-if="isLoading"
        type="list-item-avatar-three-line,list-item-avatar-three-line,list-item-avatar-three-line"
      />

      <div class="comments-list">
        <comment-item
          v-for="(comment, i) in commentList"
          :key="i"
          :comment="comment"
          class="mb-1"
          :postId="id"
          @onCommentDelete="onCommentDelete(i)"
        >
        </comment-item>
      </div>
      <v-divider v-if="isAuth" class="mb-3"></v-divider>
      <div
        class="comments-footer d-flex align-center"
        v-if="isAuth && authUser.username"
      >
        <v-avatar :color="logo ? '' : 'primary'" size="35" class="mr-2">
          <v-img v-if="logo" :src="logo" />
          <span v-else class="white--text font-weight-bold">{{
            logoName
          }}</span>
        </v-avatar>

        <v-textarea
          class="pt-0 mt-0 body-2"
          hide-details
          v-model="comment"
          outlined
          dense
          placeholder="Add a comment"
          no-resize
          auto-grow
          rows="1"
        >
          <template v-slot:append>
            <v-btn
              small
              v-if="comment"
              @click="postComment()"
              text
              color="primary"
              >Post</v-btn
            >
          </template>
        </v-textarea>
      </div>
    </div>
  </app-layout>
</template>

<script>
import {
  collection,
  doc,
  getDocs,
  increment,
  orderBy,
  query,
  writeBatch,
} from "firebase/firestore";
import { colPosts, colUsers, db } from "@/utils/firebase.utils";
import CommentItem from "../../components/public/CommentItem.vue";
import AppLayout from "../../components/ui/AppLayout.vue";
import { mapDocs } from "@/utils/common.utils";
export default {
  components: { CommentItem, AppLayout },
  name: "PostComments",
  data() {
    return {
      commentList: [],
      comment: "",
      loading: true,
      isLoading: true,
    };
  },
  computed: {
    isNotPostView() {
      return this.$route.name != "post-view";
    },
    id() {
      return this.$route.params.id;
    },
    logo() {
      return this.authUser.photo_url;
    },
    logoName() {
      let name = this.authUser.displayName || "";
      return name.charAt(0).toUpperCase();
    },
  },
  methods: {
    onCommentDelete(i) {
      this.commentList.splice(i, 1);
    },
    async postComment() {
      const vm = this;
      try {
        vm.loading = true;
        let batch = writeBatch(db);
        let postRef = doc(colPosts, vm.id);
        let userRef = doc(colUsers, vm.uid);
        let commentRef = collection(postRef, "comments");
        let commentId = doc(commentRef).id;
        let comment = {
          comment: vm.comment,
          created_time: new Date(),
          created_by: userRef,
          username: vm.authUser.username,
        };
        batch.update(postRef, {
          comments: increment(1),
        });
        batch.set(doc(commentRef, commentId), comment);
        await batch.commit();
        vm.commentList.push({
          ...comment,
          id: commentId,
        });
        vm.comment = "";
        vm.loading = false;
      } catch (error) {
        vm.handleError(error);
      }
    },
    async fetchComments() {
      const vm = this;
      try {
        vm.commentList = [];
        vm.isLoading = true;
        let commentQuery = query(
          collection(colPosts, vm.id, "comments"),
          orderBy("created_time", "asc")
        );
        let commentSnap = (await getDocs(commentQuery)).docs;
        vm.commentList = mapDocs(commentSnap);
        vm.isLoading = false;
      } catch (error) {
        vm.handleError(error);
      }
    },
  },
  mounted() {
    this.fetchComments();
  },
};
</script>

<style lang="scss">
.comments {
  height: calc(100vh - 138px);
  display: flex;
  flex-direction: column;
  &-list {
    flex: 1 !important;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
</style>
