<template>
  <v-app>
    <app-header v-if="isAppHeader"></app-header>
    <v-main>
      <keep-alive :exclude="excludeComponents">
        <router-view />
      </keep-alive>
    </v-main>
    <Loader></Loader>
    <Confirm></Confirm>
    <Alert></Alert>
    <SnackBar></SnackBar>
    <install-pwa-dialog
      @install="installPWA()"
      v-if="pwaDialog"
    ></install-pwa-dialog>
  </v-app>
</template>

<script>
import { onAuthStateChanged } from "firebase/auth";
import AppHeader from "./components/AppHeader.vue";
import Alert from "./components/ui/alert/Alert.vue";
import Confirm from "./components/ui/confirm/Confirm.vue";
import SnackBar from "./components/ui/snackbar/SnackBar.vue";

import { auth, colUsers, messaging, vapidKey } from "./utils/firebase.utils";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import InstallPwaDialog from "./components/public/InstallPwaDialog.vue";
import { eventBus } from "./utils/common.utils";
import Loader from "./components/ui/loader/Loader.vue";
import { getToken, onMessage } from "firebase/messaging";
export default {
  name: "App",
  components: {
    Alert,
    AppHeader,
    Confirm,
    SnackBar,
    InstallPwaDialog,
    Loader,
  },
  computed: {
    isAppHeader() {
      return this.$route.meta.isFly == true;
    },
    excludeComponents() {
      return [
        "Profile",
        "CreatePost",
        "Login",
        "CreateGroup",
        "GroupDetails",
        "PostComments",
        "PostView",
        "ChatRoom",
        "PeopleFilter",
        "Interests",
      ];
    },
  },
  data: () => ({
    unsubscribe: null,
    pwaDialog: false,
  }),
  methods: {
    captureEvent() {
      window.addEventListener("beforeinstallprompt", (e) => {
        e.preventDefault();
        this.deferredPrompt = e;
      });
    },
    installPWA() {
      this.pwaDialog = false;
      this.deferredPrompt.prompt();
      this.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          // Call another function?
        }
        this.deferredPrompt = null;
      });
    },
    fetchToken() {
      // const vm = this
      getToken(messaging, vapidKey)
        .then((token) => {
          if (token) {
            updateDoc(doc(colUsers, this.uid), {
              web_token: token,
            });
            // Send the token to your server and update the UI if necessary
            // ...
          } else {
            // Show permission request UI
            console.log(
              "No registration token available. Request permission to generate one."
            );
            // ...
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
        });
      onMessage(messaging, (payload) => {
        console.log("Message received. ", payload);
        let { title, body, icon } = payload.notification;
        var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        console.log("status", document.hidden);
        console.log(isMobile);
        if (isMobile) {
          navigator.serviceWorker.ready.then(function (registration) {
            registration.showNotification(title, {
              icon,
              body,
            });
          });
        } else {
          if (!document.hidden) {
            console.log("Showing local notificaiton...");
            new Notification(title, {
              icon,
              body,
            });
          }
        }
      });
    },
  },
  mounted() {
    this.captureEvent();
    onAuthStateChanged(auth, (user) => {
      this.$store.commit("SET_AUTH", user != null);
      if (user) {
        this.unsubscribe = onSnapshot(doc(colUsers, user.uid), (snap) => {
          this.$store.commit("SET_USER", snap.data() || {});
          if (snap.data() != null) {
            this.fetchToken();
          }
        });
      }
    });
    eventBus.$on("openPwa", () => {
      if (this.deferredPrompt) {
        this.pwaDialog = true;
      }
    });
  },
  beforeDestroy() {
    if (this.unsubscribe) this.unsubscribe();
  },
  beforeMount() {
    this.$store.dispatch("setCurrentLocation");
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/reset.scss";
.border-bottom {
  border-bottom: 1px solid #e1e3e6 !important;
}
.app-header .v-toolbar__content {
  @extend .border-bottom;
}
.vac-room-header {
  display: none !important;
}
</style>
