import { documentId, getDocs, query, where } from "firebase/firestore";
import { colPosts, colUsers } from "./firebase.utils";

const algoliaApiKey = "bf5cc3d6dbd996402c90fa0791b50868";
const algoliaAppId = "7J8Y54OD18";
const algoliasearch = require("algoliasearch/lite");
let searchClient = algoliasearch(algoliaAppId, algoliaApiKey, {});
const { chunk } = require("lodash");
export const fetchAlgoliaPosts = (doc) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      let indexName = doc.index;
      let search = doc.search;
      delete doc.index;
      delete doc.search;
      const index = searchClient.initIndex(indexName);
      let res = await index.search(search, {
        ...doc,
        cacheable: false,
      });
      let { hits, nbHits } = res;
      let list = [];
      let ids = hits.map((i) => i.objectID);
      let result = await Promise.all(
        chunk(ids, 10).map(async (chunkIds) => {
          let q = query(colPosts, where(documentId(), "in", chunkIds));
          let accounts = (await getDocs(q)).docs;
          return accounts.filter((doc) => doc.exists);
        })
      );
      result = result.flat(1);
      list = ids
        .map((id) => result.find((doc) => doc.id == id))
        .filter((i) => i != null);
      resolve({ docs: list, nbHits, res });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const fetchAlgoliaUsers = (doc) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      let indexName = doc.index;
      let search = doc.search;
      delete doc.index;
      delete doc.search;
      const index = searchClient.initIndex(indexName);
      let res = await index.search(search, {
        ...doc,
        cacheable: false,
      });
      let { hits, nbHits } = res;
      let list = [];
      let ids = hits.map((i) => i.objectID);
      let result = await Promise.all(
        chunk(ids, 10).map(async (chunkIds) => {
          let q = query(colUsers, where(documentId(), "in", chunkIds));
          let accounts = (await getDocs(q)).docs;
          return accounts.filter((doc) => doc.exists);
        })
      );
      result = result.flat(1);
      list = ids
        .map((id) => result.find((doc) => doc.id == id))
        .filter((i) => i != null);
      resolve({ docs: list, nbHits, res });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};
export const fetchAlgoliaFacets = (doc) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      let indexName = doc.index;
      delete doc.index;
      delete doc.search;
      const index = searchClient.initIndex(indexName);
      let res = await index.search("*", {
        ...doc,
        hitsPerPage: 0,
        cacheable: false,
        sortFacetValuesBy: "count",
        maxValuesPerFacet: 10000,
      });
      resolve(res);
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export { searchClient };
