import Alert from "./Alert";

const AlertModal = {
  install(Vue) {
    this.EventBus = new Vue();
    Vue.component("Alert", Alert);
    Vue.prototype.$alert = {
      show(...params) {
        AlertModal.EventBus.$emit("show", ...params);
      },
      hide() {
        AlertModal.EventBus.$emit("hide");
      },
    };
  },
};

export default AlertModal;
