<template>
  <v-container style="max-width: 1000px">
    <please-wait v-if="loading"></please-wait>
    <v-list v-else>
      <template v-for="(item, i) in list">
        <v-list-item :key="i">
          <v-list-item-avatar>
            <img width="48" height="48" :src="item.url" alt="facebook-new" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
            <v-list-item-subtitle v-if="item.connection"
              >Connected As: {{ item.connection.name }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-else
              >Connection required</v-list-item-subtitle
            >
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              depressed
              class="secondary"
              @click="initConnection(item.name)"
            >
              {{ item.connection ? "Reconnect Now" : "Connect Now" }}
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider :key="'divider-' + i"></v-divider>
      </template>
    </v-list>
  </v-container>
</template>

<script>
import connectedAppsMixins from "@/mixins/connected.apps.mixins";
import { colUsers } from "@/utils/firebase.utils.js";
import { collection, doc, onSnapshot, query } from "firebase/firestore";
import PleaseWait from "@/components/ui/PleaseWait.vue";

export default {
  components: { PleaseWait },
  data() {
    return {
      loading: true,
      unsubscribeConnection: null,
      connections: {
        facebook: null,
      },
    };
  },
  mixins: [connectedAppsMixins],
  computed: {
    list() {
      return [
        {
          name: "Facebook",
          url: "https://img.icons8.com/fluency/48/facebook-new.png",
          connection: this.connections.facebook,
        },
        {
          name: "Instagram",
          url: "https://img.icons8.com/fluency/48/instagram-new.png",
          connection: this.connections.instagram,
        },
        {
          name: "Twitter",
          url: "https://img.icons8.com/ios-filled/50/twitterx.png",
          connection: this.connections.twitter,
        },
      ];
    },
  },
  methods: {
    async fetchConnectedApps() {
      const vm = this;
      try {
        vm.loading = true;
        let q = query(collection(doc(colUsers, vm.uid), "connected-apps"));
        vm.unsubscribeConnection = onSnapshot(q, (snap) => {
          snap.docs.forEach((item) => {
            vm.connections[item.id] = { ...item.data(), id: item.id };
          });
        });
        vm.loading = false;
      } catch (error) {
        vm.loading = false;
        vm.$alert.show(error.message);
      }
    },
  },
  beforeDestroy() {
    if (this.unsubscribeConnection) {
      this.unsubscribeConnection();
    }
  },
  mounted() {
    this.fetchConnectedApps();
  },
};
</script>

<style></style>
