import SnackBar from "./SnackBar.vue"
const SnackBarModal = {
  install(Vue) {
    this.EventBus = new Vue();
    Vue.component("SnackBar", SnackBar);
    Vue.prototype.$snackbar = {
      show(params) {
        SnackBarModal.EventBus.$emit("show", params);
      },
      hide() {
        SnackBarModal.EventBus.$emit("hide");
      },
    };
  },
};

export default SnackBarModal;
