<template>
  <app-layout :title="title">
    <div v-if="isLoading">
      <v-skeleton-loader
        slot="spinner"
        type="list-item-avatar-three-line, image, article"
      />
    </div>
    <template v-else>
      <div class="text-center">
        <v-avatar size="120">
          <v-img v-if="group.iconPath" :src="group.iconPath" cover></v-img>
          <v-img src="@/assets/img/group.png" v-else></v-img>
        </v-avatar>
        <div class="title mt-1">{{ group.name }}</div>
        <div
          class="d-flex subtitle-2 align-center justify-center my-1"
          v-if="group.type"
        >
          <v-icon size="16" class="mr-1">{{ typeIcon[group.type] }}</v-icon>
          {{ group.type }}
        </div>

        <div class="subtitle-2 grey--text">
          <v-icon size="16" color="primary">mdi-map-marker</v-icon>
          {{ group.place.Address }}
        </div>
        <read-more
          more-str="read more"
          class="caption mb-1 read-more"
          :text="group.description"
          less-str="read less"
          :max-chars="240"
        ></read-more>

        <v-chip outlined class="my-2" v-if="user" @click="openProfile()">
          <user-logo left :user="user" :size="25"></user-logo>
          {{ user.display_name }}
        </v-chip>
      </div>

      <v-row class="mt-0">
        <v-col>
          <v-btn
            block
            depressed
            outlined
            color="primary"
            @click="openMembers('/people')"
          >
            {{ group.members_count || 0 }} Members</v-btn
          >
        </v-col>
        <v-col>
          <v-btn
            @click="openMembers('/feed')"
            block
            depressed
            outlined
            color="primary"
            >{{ group.posts_count || 0 }} Posts</v-btn
          >
        </v-col>
      </v-row>
      <v-btn block depressed class="mt-5 primary" @click="inviteGroup()">
        Invite People
      </v-btn>
      <v-btn
        block
        depressed
        @click="leaveGroup()"
        v-if="isJoined"
        :loading="isLeaveLoading"
        class="mt-5"
        color="error"
        outlined
      >
        Leave Group</v-btn
      >
      <v-btn
        block
        @click="joinGroup()"
        depressed
        :loading="isJoinLoading"
        v-else
        class="mt-5 secondary"
      >
        Join Now</v-btn
      >
    </template>
    <confirm-pin
      ref="confirmPin"
      :group="group"
      @onConfirm="submitJoin()"
    ></confirm-pin>
  </app-layout>
</template>

<script>
import AppLayout from "@/components/ui/AppLayout.vue";
import {
  arrayRemove,
  arrayUnion,
  doc,
  getDoc,
  getDocs,
  increment,
  query,
  where,
  writeBatch,
} from "firebase/firestore";
import {
  colCohorts,
  colCohortsUsers,
  colUsers,
  db,
} from "@/utils/firebase.utils";
import UserLogo from "@/components/public/UserLogo.vue";
import ConfirmPin from "@/components/groups/ConfirmPin.vue";
export default {
  name: "GroupDetails",
  components: { AppLayout, UserLogo, ConfirmPin },
  data() {
    return {
      isLoading: true,
      isJoinLoading: false,
      isLeaveLoading: false,
      group: {},
      user: null,
      typeIcon: {
        Private: "mdi-shield-lock-outline",
        Public: "mdi-earth",
        Protected: "mdi-form-textbox-password",
      },
    };
  },
  computed: {
    title() {
      let name = this.group.name;
      return name ? name.substring(0, 20) + "..." : "Group";
    },
    groupId() {
      return this.$route.params.id;
    },
    isJoined() {
      if (!this.isAuth) return false;
      let cohorts = (this.authUser.cohorts || []).map((i) =>
        this.getDocPath(i)
      );
      return cohorts.indexOf(this.groupId) != -1;
    },
  },
  methods: {
    inviteGroup() {
      const vm = this;
      let path = vm.$router.resolve({
        name: "group-details",
        params: { id: vm.groupId },
      });
      let url = `https://${location.host}${path.href}`;
      if (navigator.share) {
        navigator
          .share({
            title: "Group",
            text: `Hey, I'd like you to join the group ${vm.group.name} on around.  
              Please click the link below to join`,
            url,
          })
          .then(() => {
            alert("Success");
            console.log("Successful share");
          })
          .catch((error) => {
            alert(error.message);
          });
      } else {
        navigator.clipboard.writeText(url);
        vm.$snackbar.show("Group url copied to the clipboard");
      }
    },
    openMembers(path) {
      const vm = this;
      if (!vm.isJoined) {
        vm.$snackbar.show(
          "You must join the group to see the members and posts"
        );
        return;
      }
      let data = {
        ...vm.group,
        id: vm.groupId,
        type: "group",
      };
      vm.$router.push(path);

      vm.$nextTick(() => {
        vm.$store.commit(
          "SET_CURRENT_LOCATION",
          JSON.parse(JSON.stringify(data))
        );
      });
    },
    openProfile() {
      // let uid = this.getDocPath(this.group.created_by);
      this.$router.push(`/@${this.user.username}`);
    },
    async joinGroup() {
      const vm = this;
      if (!vm.confirmLogin()) return;
      if (vm.group.type == "Protected") {
        vm.$refs.confirmPin.dialog = true;
        return;
      }
      this.submitJoin();
    },
    async submitJoin() {
      const vm = this;
      try {
        vm.isJoinLoading = true;
        let batch = writeBatch(db);
        let cohortUserId = doc(colCohortsUsers).id;
        let cohortRef = doc(colCohorts, vm.groupId);
        let userRef = doc(colUsers, vm.uid);
        batch.update(cohortRef, {
          members_count: increment(1),
        });
        batch.set(doc(colCohortsUsers, cohortUserId), {
          cohort_ref: cohortRef,
          user_ref: userRef,
          joined_at: new Date(),
        });
        batch.update(userRef, {
          cohorts: arrayUnion(cohortRef),
        });
        await batch.commit();
        vm.isJoinLoading = false;
        vm.fetchGroup();
      } catch (error) {
        vm.isJoinLoading = false;
        vm.handleError(error);
      }
    },
    async leaveGroup() {
      const vm = this;
      if (!vm.confirmLogin()) return;
      vm.$confirm.show({
        message: "Are you sure?",
        onConfirm: async () => {
          try {
            vm.isLeaveLoading = true;
            let batch = writeBatch(db);
            let cohortRef = doc(colCohorts, vm.groupId);
            let userRef = doc(colUsers, vm.uid);
            let cohortUsersQuery = query(
              colCohortsUsers,
              where("user_ref", "==", userRef),
              where("cohort_ref", "==", cohortRef)
            );
            let cohortUsers = (await getDocs(cohortUsersQuery)).docs;
            cohortUsers.forEach((i) => {
              batch.delete(i.ref);
            });
            batch.update(cohortRef, {
              members_count: increment(-1),
            });
            batch.update(userRef, {
              cohorts: arrayRemove(cohortRef),
            });
            await batch.commit();
            vm.isLeaveLoading = false;
            vm.fetchGroup();
          } catch (error) {
            vm.isLeaveLoading = false;
            vm.handleError(error);
          }
        },
      });
    },
    async fetchGroup() {
      const vm = this;
      try {
        vm.isLoading = true;
        vm.group = (await getDoc(doc(colCohorts, vm.groupId))).data();
        vm.user = (await getDoc(vm.group.created_by)).data();
        vm.isLoading = false;
      } catch (error) {
        vm.$router.replace("/groups");
        vm.handleError(error);
      }
    },
  },
  mounted() {
    this.fetchGroup();
  },
};
</script>

<style></style>
