import { isAuth } from "@/assets/helper/auth.helper";
import ConnectedApps from "../pages/fly/ConnectedApps.vue";
import Gallery from "../pages/fly/Gallery.vue";
import CreatePost from "../pages/fly/CreatePost.vue";
import UpdateGalleryImage from "../pages/fly/UpdateGalleryImage.vue";
import UpdateGalleryVideo from "../pages/fly/UpdateGalleryVideo.vue";
import ConnectionSuccess from "../pages/fly/ConnectionSuccess.vue";
import Posts from "../pages/fly/Posts.vue";
let routes = [
  {
    path: "/fly",
    name: "fly",
    component: Posts,
    beforeEnter: isAuth,
  },
  {
    path: "/create-post",
    name: "create-post",
    component: CreatePost,
    beforeEnter: isAuth,
  },
  {
    path: "/gallery",
    name: "gallery",
    component: Gallery,
    beforeEnter: isAuth,
  },
  {
    path: "/update-gallery-image/:id",
    name: "update-gallery-image",
    component: UpdateGalleryImage,
    beforeEnter: isAuth,
  },
  {
    path: "/update-gallery-video/:id",
    name: "update-gallery-video",
    component: UpdateGalleryVideo,
    beforeEnter: isAuth,
  },
  {
    path: "/connected-apps",
    name: "connected-apps",
    component: ConnectedApps,
    beforeEnter: isAuth,
  },
  {
    path: "/connection-success",
    name: "connection-success",
    component: ConnectionSuccess,
    beforeEnter: isAuth,
  },
];

let flyRoutes = routes.map((i) => {
  let meta = i.meta || {};
  meta.isFly = true;
  return { ...i, meta };
});

export default flyRoutes;
