<template>
  <div v-if="content">
    <template v-if="isReadMore">
      <p
        class="mb-0 text-sm"
        :class="readMore ? '' : 'three-lines'"
        v-html="content"
      ></p>
      <a class="caption" @click="readMore = !readMore">{{
        readMore ? "Read Less" : "Read More"
      }}</a>
    </template>
    <p class="mb-0 text-sm" v-else v-html="content"></p>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      readMore: false,
    };
  },
  computed: {
    isReadMore() {
      return this.content && this.content.length > 140;
    },
  },
};
</script>

<style></style>
