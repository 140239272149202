<template>
  <v-dialog v-model="dialog" fullscreen>
    <template v-slot:activator="{ on }">
      <div v-on="on" style="width: 100%"><slot></slot></div>
    </template>
    <v-card class="image-overlay">
      <v-btn class="exit-button red" dark icon @click="dialog = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <img
        :src="url"
        contain
        @click="dialog = false"
        @wheel="zoomImage"
        ref="zoomImage"
      />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dialog: false,
      zoomLevel: 1,
    };
  },
  methods: {
    openDialog() {
      this.dialog = true;
      this.zoomLevel = 1; // Reset zoom level when opening the dialog
    },
    zoomImage(event) {
      if (this.dialog) {
        // Calculate new zoom level based on the wheel event's delta
        this.zoomLevel += event.deltaY > 0 ? -0.1 : 0.1;

        // Limit zoom level to a minimum of 1 (original size)
        this.zoomLevel = Math.max(1, this.zoomLevel);

        // Set the new transform scale based on the zoom level
        this.$refs.zoomImage.style.transform = `scale(${this.zoomLevel})`;
      }
    },
  },
};
</script>

<style>
.image-overlay {
  position: relative;
  background: black;
  width: 100%;
  display: flex;
  align-items: center;
  height: calc(100vh - 50px);
}

.exit-button {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 100000000;
}
</style>
