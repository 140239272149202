<template>
  <app-layout title="Groups" :extension="true">
    <v-tabs slot="extension" class="mb-3" height="35px" fixed-tabs>
      <v-tab class="text-none" to="/groups/joined"> Joined </v-tab>
      <!-- <v-tab class="text-none" to="/groups/suggested"> Suggested </v-tab> -->
      <v-tab class="text-none" to="/groups/nearby"> Nearby </v-tab>
    </v-tabs>

    <keep-alive>
      <router-view></router-view>
    </keep-alive>
    <v-btn
      fixed
      class="mb-13"
      bottom
      right
      fab
      to="/create-group"
      color="primary"
      width="50px"
      height="50px"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </app-layout>
</template>

<script>
import AppLayout from "../../components/ui/AppLayout.vue";
export default {
  components: { AppLayout },
};
</script>

<style></style>
