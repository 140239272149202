<template>
  <v-card outlined @click="$router.push(`/group/${item.id}`)">
    <v-list-item class="px-3" three-line>
      <v-list-item-avatar size="75" rounded="lg" class="mr-2 my-3">
        <v-img v-if="item.iconPath" :src="item.iconPath"></v-img>
        <v-img src="@/assets/img/group.png" v-else></v-img>
      </v-list-item-avatar>
      <v-list-item-content class="align-self-start align-start">
        <v-list-item-title class="font-weight-medium pl-1">{{
          item.name
        }}</v-list-item-title>
        <v-list-item-title
          class="d-flex mb-1 align-center text-subtitle-2 font-weight-regular"
        >
          <v-icon size="18">mdi-map-marker</v-icon>
          {{ item.place.Address }}
        </v-list-item-title>
        <v-list-item-subtitle class="pl-1">
          {{ item.description }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style></style>
