<template>
  <v-snackbar v-model="dialog" color="primary">
    <strong>
      {{ message }}
    </strong>
    <template v-slot:action="{ attrs }">
      <v-btn color="white" icon v-bind="attrs" @click="dialog = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import AlertModal from "./index";
export default {
  data() {
    return {
      message: "",
      dialog: false,
    };
  },
  methods: {
    show(message) {
      const vm = this;
      vm.message = message;
      vm.dialog = true;
    },
  },
  beforeMount() {
    AlertModal.EventBus.$on("show", (params) => {
      this.show(params);
    });
    AlertModal.EventBus.$on("hide", () => {
      this.dialog = false;
    });
  },
};
</script>

<style></style>
