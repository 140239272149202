<template>
  <app-layout title="Discover">
    <v-skeleton-loader
      v-if="loading"
      type="list-item-avatar-three-line, image, article"
    ></v-skeleton-loader>
    <template v-else>
      <v-text-field
        prepend-inner-icon="mdi-magnify"
        outlined
        class="mb-5 discover-search"
        type="search"
        hide-details
        @keyup.enter="fetchDetails()"
        dense
        v-model="search"
        clearable
        placeholder="Search around"
      />
      <nearby-group-list
        :search="searchText"
        v-if="!isGroupSelected"
        :groups="groups"
      />
      <people-around-list :search="searchText" :users="peoples" />
      <div
        class="text-md-h5 text-subtitle-1 my-2 d-flex align-center justify-between"
        v-if="!isAuth"
      >
        Get listed, get found!
        <v-btn depressed small class="primary" to="/signup">Join Now</v-btn>
      </div>
      <talent-of-vasai-virar-info></talent-of-vasai-virar-info>
      <by-interest-list
        :search="searchText"
        :items="interests"
        v-if="interests"
      />
      <by-interest-list
        :search="searchText"
        type="skills"
        v-if="skills"
        :items="skills"
      />
      <latest-posts-list :search="searchText" class="mb-10" :posts="posts" />
      <public-footer></public-footer>
    </template>
  </app-layout>
</template>

<script>
import AppLayout from "@/components/ui/AppLayout.vue";
import PeopleAroundList from "@/components/public/PeopleAroundList.vue";
import ByInterestList from "@/components/public/ByInterestList.vue";
import LatestPostsList from "@/components/public/LatestPostsList.vue";
import {
  fetchAlgoliaFacets,
  fetchAlgoliaPosts,
  fetchAlgoliaUsers,
  searchClient,
} from "@/utils/algolia.utils";
import { mapDocs } from "@/utils/common.utils";
import TalentOfVasaiVirarInfo from "@/components/public/TalentOfVasaiVirarInfo.vue";
import PublicFooter from "@/components/public/PublicFooter.vue";
import NearbyGroupList from "@/components/public/NearbyGroupList.vue";
import { colCohorts } from "@/utils/firebase.utils";
import { documentId, getDocs, query, where } from "firebase/firestore";
export default {
  components: {
    AppLayout,
    PeopleAroundList,
    ByInterestList,
    LatestPostsList,
    TalentOfVasaiVirarInfo,
    PublicFooter,
    NearbyGroupList,
  },
  data() {
    return {
      search: "",
      people: [],
      interests: {},
      skills: {},
      posts: [],
      users: {},
      groups: [],
      loading: true,
      previousLocation: null,
    };
  },
  watch: {
    currentLocation: {
      handler() {
        if (this.$route.name == "index") {
          this.fetchDetails();
        }
      },
      deep: true,
    },
  },
  methods: {
    async fetchDetails() {
      const vm = this;
      console.log("Fetching index details....");
      try {
        vm.searchText = vm.search;
        vm.loading = true;
        vm.groups = [];
        vm.posts = [];
        let requestBody = {
          page: 0,
          hitsPerPage: 10,
          query: vm.search || "*",
          index: "users",
          ...vm.globalFilter,
        };
        let facetRequestBody = {
          ...requestBody,
          facets: ["professionalskills", "interests"],
        };
        let peopleRes = await fetchAlgoliaUsers({
          ...requestBody,
        });
        let postRes = await fetchAlgoliaPosts({
          ...requestBody,
          index: "userposts",
        });
        let facetRes = await fetchAlgoliaFacets({
          ...facetRequestBody,
        });
        let { interests, professionalskills } = facetRes.facets;
        vm.interests = interests;
        vm.skills = professionalskills;
        vm.filterFacets();
        let peoples = mapDocs(peopleRes.docs).map((i) => {
          i.loading = false;
          return i;
        });
        peoples = peoples.filter((i) => i.id != vm.uid);
        vm.peoples = await vm.getUsersFollow(peoples);
        let posts = mapDocs(postRes.docs);
        await vm.getPostUsers(posts);
        if (!vm.isGroupSelected) {
          await vm.fetchNearbyGroups();
        }
        vm.loading = false;
      } catch (error) {
        vm.handleError(error);
      }
    },
    filterFacets() {
      if (this.search) {
        let interests = {};
        let skills = {};
        if (this.interests) {
          Object.keys(this.interests).forEach((key) => {
            let txt = key.toString().toLowerCase();
            if (txt.startsWith(this.search)) {
              interests[key] = this.interests[key];
            }
          });
        }
        if (this.skills) {
          Object.keys(this.skills).forEach((key) => {
            let txt = key.toString().toLowerCase();
            if (txt.startsWith(this.search)) {
              skills[key] = this.skills[key];
            }
          });
        }
        this.interests = interests;
        this.skills = skills;
      }
    },
    fetchNearbyGroups() {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const vm = this;
          const index = searchClient.initIndex("cohorts");
          let res = await index.search(this.search || "*", {
            hitsPerPage: 10,
            page: 0,
            aroundLatLng: vm.currentLocation.latlong,
            aroundRadius: "250000",
            cacheable: true,
          });
          let ids = res.hits.map((i) => i.objectID);
          if (ids.length != 0) {
            let cohortQuery = query(colCohorts, where(documentId(), "in", ids));
            let cohortDocs = (await getDocs(cohortQuery)).docs;
            vm.groups = cohortDocs.map((i) => ({ ...i.data(), id: i.id }));
          }
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  activated() {
    let previous = JSON.stringify(this.previousLocation);
    let current = JSON.stringify(this.currentLocation);
    if (previous != current) {
      this.fetchDetails();
      this.previousLocation = this.currentLocation;
    }
  },
};
</script>

<style></style>
