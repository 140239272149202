<template>
  <div>
    <invite-header></invite-header>
    <v-container>
      <p class="text-center black--text text-sm md:text-xl leading-loose mb-10">
        Hello, our local content bots have created this post for you for people
        around to see!
        <br />
        Please sign up and claim this listing to stay updated!
      </p>
      <div class="invite-content">
        <div class="d-flex justify-center" v-if="isPostLoading">
          <v-progress-circular
            indeterminate
            color="secondary"
          ></v-progress-circular>
        </div>
        <template v-else-if="post">
          <post-card :post="post"></post-card>
          <v-btn
            class="secondary mb-10 text-none"
            :to="`/invite-login/${$route.params.id}`"
            block
            depressed
            large
            >Sign up & Claim</v-btn
          >
        </template>
      </div>
    </v-container>
  </div>
</template>

<script>
import PostCard from "@/components/invite/PostCard.vue";
import InviteHeader from "@/components/invite/InviteHeader.vue";
import inviteMixins from "@/mixins/invite.mixins";
export default {
  components: { PostCard, InviteHeader },
  data() {
    return {
      isPostLoading: true,
      post: null,
    };
  },
  mixins: [inviteMixins],
  mounted() {
    this.getPost();
  },
};
</script>

<style>
.invite-content {
  max-width: 400px;
  margin: 0 auto;
}
</style>
