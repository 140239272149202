<template>
  <v-container style="max-width: 800px">
    <form-wrapper @submit="submit()">
      <div class="title mb-5 mt-2">Post something for your followers</div>
      <label>Text</label>
      <v-textarea dense v-model="form.text" outlined></v-textarea>
      <div class="d-flex align-center">
        <div class="subtitle-1">Add Images</div>
        <v-spacer></v-spacer>
        <gallery-picker v-model="form.images" />
      </div>
      <v-row class="mb-10">
        <v-col
          v-for="n in form.images"
          :key="n.id"
          class="d-flex child-flex"
          cols="6"
          md="3"
        >
          <v-img :src="n.thumbnail" aspect-ratio="1" class="grey lighten-2">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col>
      </v-row>
      <!-- <div class="d-flex align-center mt-10">
      <div class="subtitle-1">Videos:</div>
      <v-spacer></v-spacer>
      <gallery-picker type="video" v-model="form.videos" />
    </div>
    <v-row class="mb-10">
      <v-col
        v-for="n in form.videos"
        :key="n.id"
        class="d-flex child-flex"
        cols="6"
        md="3"
      >
        <v-img :src="n.thumbnail" aspect-ratio="1" class="grey lighten-2">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row> -->
      <label>Hashtags</label>
      <combo-box :dense="false" v-model="form.hashtags"></combo-box>
      <label>When to post?</label>
      <v-radio-group
        :rules="[rules.required]"
        row
        class="mt-0"
        dense
        v-model="form.when_to_post"
      >
        <v-radio label="Immediate" value="immediate"></v-radio>
        <v-radio label="Schedule" value="schedule"></v-radio>
      </v-radio-group>
      <v-datetime-picker
        v-if="form.when_to_post == 'schedule'"
        :textFieldProps="{
          outlined: true,
        }"
        class="mt-3"
        label="Select Datetime"
        v-model="form.scheduled_at"
      >
        <v-icon slot="dateIcon">mdi-calendar-month</v-icon>
        <v-icon slot="timeIcon">mdi-clock-outline</v-icon>
      </v-datetime-picker>
      <div class="mt-1 d-flex align-center">
        <label>Where to post?</label>
        <v-btn class="red ml-2" @click="form.social_media = []" text small dark>
          clear
        </v-btn>
      </div>
      <v-radio-group
        :rules="[rules.multiselect]"
        row
        class="mt-0"
        dense
        multiple
        clearable
        v-model="form.social_media"
      >
        <v-radio
          v-for="(item, i) in connections"
          :key="i"
          :label="item.name"
          :value="item.value"
        ></v-radio>
      </v-radio-group>
      <select-field
        v-model="form.facebook_page"
        rules="required"
        :items="facebookPages"
        label="Select Facebook Page"
        v-if="isSocial('facebook')"
      />
      <div class="d-flex">
        <v-spacer></v-spacer>
        <v-btn
          outlined
          color="red"
          large
          class="mr-5"
          @click="$router.replace('/fly')"
          >Close</v-btn
        >
        <v-btn
          depressed
          large
          :loading="loading"
          class="secondary"
          type="submit"
          >Submit</v-btn
        >
      </div>
    </form-wrapper>
  </v-container>
</template>

<script>
import GalleryPicker from "@/components/fly/GalleryPicker.vue";
import ComboBox from "@/components/ui/form/ComboBox.vue";
import rulesConstants from "@/assets/constants/rules.constants";
import FormWrapper from "@/components/ui/form/FormWrapper.vue";
import { collection, doc, getDocs, query } from "firebase/firestore";
import { colUsers, functions } from "@/utils/firebase.utils";
import SelectField from "@/components/ui/form/SelectField.vue";
import { httpsCallable } from "firebase/functions";
export default {
  components: { GalleryPicker, ComboBox, FormWrapper, SelectField },
  data() {
    return {
      form: {
        text: "",
        images: [],
        hashtags: [],
        when_to_post: "",
        scheduled_at: "",
        social_media: [],
        facebook_page: "",
      },
      loading: false,
      rules: rulesConstants,
      connectedApps: [],
    };
  },
  computed: {
    connections() {
      let list = [
        {
          name: "Facebook",
          value: "facebook",
        },
      ];
      list = list.filter((i) => {
        let item = this.connectedApps.find(
          (item) => item.id == i.value && item.status == true
        );
        return item != null;
      });
      return list;
    },
    facebookPages() {
      let item = this.connectedApps.find((item) => item.id == "facebook") || {};
      return (item.pages || []).map((i) => ({ ...i, value: i.id }));
    },
  },
  methods: {
    isSocial(key) {
      return this.form.social_media.indexOf(key) != -1;
    },
    async submit() {
      const vm = this;
      let { images, text } = vm.form;
      if (images.length == 0 && text.trim() == "") {
        vm.$alert.show("Either a text or an image field is required.");
        return;
      }
      try {
        let doc = { ...vm.form };
        doc.images = doc.images.map((i) => i.thumbnail);
        vm.loading = true;
        let addSocialPost = httpsCallable(functions, "addSocialPost");
        let { status, message } = (await addSocialPost(doc)).data;
        if (!status) {
          vm.$alert.show(message);
        }
        vm.$router.replace("/fly");
        vm.loading = false;
      } catch (error) {
        vm.handleError(error);
      }
    },
    async fetchConnections() {
      const vm = this;
      try {
        let q = query(collection(doc(colUsers, vm.uid), "connected-apps"));
        vm.connectedApps = (await getDocs(q)).docs.map((i) => ({
          ...i.data(),
          id: i.id,
        }));
      } catch (error) {
        vm.handleError(error);
      }
    },
  },
  beforeMount() {
    this.fetchConnections();
  },
};
</script>

<style></style>
