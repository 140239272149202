<template>
  <div ref="post">
    <v-card flat tile>
      <v-list-item class="px-0">
        <v-list-item-avatar class="mr-2" size="30" @click="openProfile()">
          <v-avatar :color="logo ? '' : 'primary'" size="30">
            <v-img v-if="logo" :src="logo" />
            <span v-else class="white--text font-weight-bold">{{
              logoName
            }}</span>
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="text-sm md:text-lg d-flex align-center">
            <a class="black--text" @click="openProfile()">
              {{ post.user.display_name }}
            </a>
            <template v-if="post.user.isFollowing != undefined">
              <v-btn
                v-if="post.user.isFollowing"
                color="primary"
                rounded
                text
                @click="unFollowUser(post.user)"
                :loading="post.user.loading"
                small
                >Following</v-btn
              >
              <v-btn
                @click="followUser(post.user)"
                class="primary--text"
                v-else
                rounded
                text
                :loading="post.user.loading"
                small
                depressed
                >Follow</v-btn
              >
            </template>

            <v-spacer></v-spacer>
            <v-chip
              x-small
              v-if="post.tag"
              filter
              rounded
              class="primary--text py-3"
              outlined
              >{{ post.tag }}</v-chip
            >
            <post-view-menu
              :post="post"
              v-if="isMenu"
              @onPostDelete="$emit('onPostDelete', $event)"
            />
          </v-list-item-title>
          <v-list-item-subtitle class="text-xs md:text-sm" v-html="subtitle">
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-card-text class="pa-0">
        <html-read-more :content="content" />
        <html-read-more :content="hashtags" />
        <post-link-preview
          v-for="(item, i) in previews"
          :key="i"
          :item="item"
        />
        <div v-if="postLocation" class="mb-1 d-flex">
          <div>
            <v-icon size="16">mdi-map-marker</v-icon>
          </div>
          <div>
            {{ postLocation }}
          </div>
        </div>
        <v-carousel
          hide-delimiters
          v-model="carousel"
          v-if="carousels.length"
          :show-arrows="carousels.length > 1"
          :height="isMobile ? '400px' : '500px'"
        >
          <v-carousel-item v-for="(item, i) in carousels" :key="i">
            <div class="d-flex align-center h-full black">
              <iframe
                width="100%"
                v-if="item.type == 'youtube'"
                height="300px"
                :src="item.src"
              >
              </iframe>
              <post-image-dialog v-if="item.type == 'image'" :url="item.src">
                <v-img :src="item.src" />
              </post-image-dialog>
              <video-auto-play-component
                v-if="item.type == 'video'"
                :videoSrc="item.src"
              />
            </div>
          </v-carousel-item>
        </v-carousel>
        <div class="d-flex my-1 align-center">
          <div style="min-width: 70px" class="d-flex align-center black--text">
            <v-btn
              :loading="loading"
              tile
              icon
              small
              :disabled="isAuthUserPost"
              color="red"
              @click="likePost()"
            >
              <v-icon size="19" v-if="isLiked">mdi-heart</v-icon>
              <v-icon size="19" v-else>mdi-heart-outline</v-icon>
            </v-btn>
            <b>{{ likesCount }}</b>
          </div>
          <div style="min-width: 55px" class="d-flex align-center black--text">
            <v-icon size="19" color="grey" class="mr-1">mdi-eye</v-icon>
            <b>{{ post.views || 0 }}</b>
          </div>
          <v-btn depressed class="white" :to="`/comments/${post.id}`">
            <v-icon size="18" color="grey" class="mr-1"
              >mdi-comment-outline</v-icon
            >
            <b>{{ post.comments || 0 }}</b>
          </v-btn>
          <v-spacer />
          <v-btn
            icon
            large
            color="secondary"
            class="ml-2"
            tile
            @click="copyShareLink()"
          >
            <v-icon size="19">mdi-share-variant-outline</v-icon>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { auth, colPosts, colUsers, isLocalHost } from "@/utils/firebase.utils";
import moment from "moment";
import {
  arrayRemove,
  arrayUnion,
  doc,
  getDoc,
  increment,
  updateDoc,
} from "firebase/firestore";
import _ from "lodash";
import VideoAutoPlayComponent from "./VideoAutoPlayComponent.vue";
import peopleMixins from "@/mixins/people.mixins";
import PostViewMenu from "./PostViewMenu.vue";
import HtmlReadMore from "./HtmlReadMore.vue";
import PostImageDialog from "./PostImageDialog.vue";
import axios from "axios";
import PostLinkPreview from "./PostLinkPreview.vue";
export default {
  components: {
    VideoAutoPlayComponent,
    PostViewMenu,
    HtmlReadMore,
    PostImageDialog,
    PostLinkPreview,
  },
  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
  },
  mixins: [peopleMixins],
  data() {
    return {
      carousel: 0,
      loading: false,
      timer: null,
      overlay: false,
      previews: [],
    };
  },
  computed: {
    isMenu() {
      return this.isAuthUserPost && this.$route.name == "profile";
    },
    isAuthUserPost() {
      return this.getDocPath(this.post.userref) == this.uid;
    },
    postLocation() {
      let name = _.get(this.post, "postLocation.Name", "");
      let address = _.get(this.post, "postLocation.Address", "");
      if (name) return name;
      let split = address.split(",").splice(0, 2).join(",");
      return split;
    },
    hashtags() {
      let hashtags = this.post.hashtags || [];
      return hashtags
        .map((i) => {
          if (!i.startsWith("#")) return `#${i}`;
          return i;
        })
        .toString()
        .split(",")
        .join(" ");
    },
    carousels() {
      let list = [];
      let embed = this.post.embeddedvideolink || "";
      let { video, image, content } = this.post;
      let urls = this.extractYouTubeUrls(content || "");
      if (embed.indexOf("youtube.com/watch?v=") != -1) {
        embed = embed.split("/watch?v=").join("/embed/");
      }
      if (embed.indexOf("youtu.be") != -1) {
        embed = embed
          .split("https://youtu.be/")
          .join("https://www.youtube.com/embed/");
      }
      urls.forEach((src) => {
        list.push({
          src,
          type: "youtube",
        });
      });
      if (embed.indexOf("youtube.com") != -1) {
        list.push({
          src: embed,
          type: "youtube",
        });
      } else if (embed) {
        list.push({
          src: embed,
          type: "video",
        });
      } else if (video) {
        video = decodeURIComponent(video);
        let firebasePrefix =
          "https://firebasestorage.googleapis.com/v0/b/around-a8790.appspot.com/o/users/";
        video = video.replace(
          firebasePrefix,
          "https://ik.imagekit.io/yf8u2v3qx/"
        );
        list.push({
          src: video,
          type: "video",
        });
      }
      (image || []).map((i) => {
        list.push({
          src: i,
          type: "image",
        });
      });
      list = list.filter((i) => i.src);
      return list;
    },
    subtitle() {
      let { username } = this.post.user;
      if (username) {
        return `@${username} &#x2022; ${this.timeAgo}`;
      }
      return this.timeAgo;
    },
    logo() {
      return this.post.user.photo_url;
    },
    logoName() {
      let name = _.get(this.post, "user.display_name", "");
      return name.charAt(0).toUpperCase();
    },
    timeAgo() {
      return moment(this.post.timeanddateofpost.seconds * 1000).fromNow();
    },
    likesCount() {
      let likes = this.post.likes || [];
      return likes.length.toLocaleString("en-US");
    },
    isLiked() {
      let likes = (this.post.likes || []).map((i) => {
        let segments = _.get(i, "_key.path.segments", []);
        return segments[segments.length - 1];
      });
      return likes.indexOf(this.uid) != -1;
    },
    content() {
      let content = this.post.content || "";
      content = content.toString().replace(/(?:\r\n|\r|\n)/g, " <br>");
      content = content.replace(
        /(https?:\/\/[^\s]+)/g,
        '<a href="$1" target="_blank" class="primary--text text-decoration-underline">$1</a>'
      );
      return content;
    },
  },
  methods: {
    extractYouTubeUrls(text) {
      const youtubeUrlRegex =
        /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/g;
      const matches = text.match(youtubeUrlRegex) || [];

      return matches.map((embed) => {
        if (embed.indexOf("youtube.com/watch?v=") != -1) {
          embed = embed.split("/watch?v=").join("/embed/");
        }
        if (embed.indexOf("youtu.be") != -1) {
          embed = embed
            .split("https://youtu.be/")
            .join("https://www.youtube.com/embed/");
        }
        return embed;
      });
    },
    startTimer() {
      const vm = this;
      this.timer = setTimeout(async () => {
        this.timer = null;
        if (this.uid) {
          if (!isLocalHost) {
            let userRef = doc(colUsers, vm.uid);
            let postRef = doc(colPosts, vm.post.id);
            await updateDoc(postRef, {
              viewedBy: arrayUnion(userRef),
              views: increment(1),
            });
            let post = (await getDoc(postRef)).data();
            vm.$emit("onPost", post);
          }
        }
      }, 3000);
    },
    cancelTimer() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
    },
    openProfile() {
      this.$router.push(`/@${this.post.user.username}`);
    },
    copyShareLink() {
      const vm = this;
      let path = vm.$router.resolve({
        name: "post-view",
        params: { id: vm.post.id },
      });
      let url = `https://${location.host}${path.href}`;
      navigator.clipboard.writeText(url);
      vm.$snackbar.show("Post url copied to the clipboard");
    },
    async likePost() {
      const vm = this;
      if (!vm.confirmLogin()) return;

      try {
        vm.loading = true;
        let userRef = doc(colUsers, auth.currentUser.uid);
        let postRef = doc(colPosts, vm.post.id);
        await updateDoc(postRef, {
          likes: vm.isLiked ? arrayRemove(userRef) : arrayUnion(userRef),
        });
        let post = (await getDoc(postRef)).data();
        vm.$emit("onPost", post);
        vm.loading = false;
      } catch (error) {
        vm.handleError(error);
      }
    },
    observePost() {
      const options = {
        root: null, // use the viewport as the root
        rootMargin: "0px",
        threshold: 0.5, // 50% of the video should be in the viewport
      };

      const observer = new IntersectionObserver(
        this.handleIntersection,
        options
      );
      observer.observe(this.$refs.post);
    },
    handleIntersection(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.startTimer();
        } else {
          this.cancelTimer();
        }
      });
    },
    async fetchPostLinks() {
      const vm = this;
      console.log(vm.post.content);
      let urls = (vm.post.content || "").match(/\bhttps?:\/\/\S+/gi) || [];
      urls = urls.filter(
        (i) => i.indexOf("youtu.be") == -1 && i.indexOf("youtube.com") == -1
      );
      try {
        for (let url of urls) {
          let { data } = await axios.get(
            `https://link-preview-api.nivaldo.workers.dev/preview?url=${url}`
          );
          vm.previews.push(data);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.observePost();
    this.fetchPostLinks();
  },
};
</script>

<style lang="scss">
.comment-input {
  .v-input__slot::after,
  .v-input__slot::before {
    border: 0px !important;
  }
}
</style>
