<template>
  <div class="d-flex align-center justify-center">
    <v-progress-circular indeterminate color="secondary"></v-progress-circular>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
