import { getDoc, doc } from "firebase/firestore";
import { colPosts } from "@/utils/firebase.utils";
export default {
  methods: {
    isRoute(name) {
      return this.$route.name == name;
    },
    async getPost() {
      const vm = this;
      try {
        vm.isPostLoading = true;
        let id = vm.$route.params.id;
        let post = (await getDoc(doc(colPosts, id))).data();
        if (post == null) {
          vm.isPostLoading = false;
          vm.$alert.show("Post not found.");
          return;
        }
        if (vm.isRoute("signup-complete")) {
          let user = (await getDoc(post.userref)).data() || {};
          post.invite_name = user.display_name || "";
          vm.post = post;
          vm.isPostLoading = false;
          return;
        }
        vm.isPostLoading = false;
        if (post.is_claimed == true) {
          vm.$alert.show("Post is already claimed.");
          return;
        }
        if (post.is_invite !== true) {
          vm.$alert.show("Post can't be claimed.");
          return;
        }
        vm.post = post;
        if (vm.isRoute("invite-login")) {
          vm.phone = vm.post.invite_phone;
          vm.form.email = vm.post.invite_email;
          vm.form.display_name = vm.post.invite_name;
          setTimeout(() => {
            vm.initCaptcha();
          }, 1000);
        }
      } catch (error) {
        vm.isPostLoading = false;
        vm.$alert.show(error.message);
      }
    },
  },
};
