import {
  FB_APP_ID,
  FB_REDIRECT_URI,
  INSTAGRAM_REDIRECT_URI,
} from "@/assets/constants/connected.apps.constants";
import { auth } from "@/utils/firebase.utils";
export default {
  methods: {
    initFBConnection() {
      let scope =
        "public_profile,pages_read_engagement,pages_show_list,pages_manage_posts";
      let uid = auth.currentUser.uid;
      let url = `https://www.facebook.com/v17.0/dialog/oauth?client_id=${FB_APP_ID}&redirect_uri=${FB_REDIRECT_URI}&state=${uid}&scope=${scope}`;
      this.openLoginDialog(url);
    },
    initInstagramConnection() {
      let scope = "instagram_basic,pages_show_list";
      let uid = auth.currentUser.uid;
      let url = `https://www.facebook.com/v17.0/dialog/oauth?client_id=${FB_APP_ID}&redirect_uri=${INSTAGRAM_REDIRECT_URI}&state=${uid}&scope=${scope}&response_type=code`;
      this.openLoginDialog(url);
    },
    initConnection(type) {
      switch (type) {
        case "Facebook":
          this.initFBConnection();
          return;
        case "Instagram":
          // this.initInstagramConnection();
          return;
      }
    },
    openLoginDialog(url) {
      let w = 500;
      let h = 600;
      var left = window.screen.width / 2 - w / 2;
      var top = window.screen.height / 2 - h / 2;
      var win = window.open(
        url,
        "_blank",
        "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
          w +
          ", height=" +
          h
      );
      win.moveBy(left, top);
    },
  },
};
