<template>
  <div>
    <group-item
      v-for="(item, i) in cohorts"
      :item="item"
      class="mb-4"
      :key="i"
    ></group-item>

    <infinite-loading ref="loader" @infinite="infiniteHandler" :distance="430">
      <v-skeleton-loader slot="spinner" type="list-item-avatar-three-line" />
    </infinite-loading>
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import { searchClient } from "@/utils/algolia.utils";
import { documentId, getDocs, query, where } from "firebase/firestore";
import { colCohorts } from "@/utils/firebase.utils";
import GroupItem from "@/components/groups/GroupItem.vue";
export default {
  data() {
    return {
      page: 0,
      cohorts: [],
      previousLocation: null,
    };
  },
  components: {
    InfiniteLoading,
    GroupItem,
  },
  watch: {
    currentLocation: {
      handler() {
        if (this.$route.name == "groups-nearby") {
          this.page = 0;
          this.$refs.loader.stateChanger.reset();
          this.cohorts = [];
        }
      },
      deep: true,
    },
  },

  methods: {
    async infiniteHandler($state) {
      const vm = this;
      try {
        console.log("Fetching nearby list...");
        const index = searchClient.initIndex("cohorts");
        let res = await index.search("*", {
          hitsPerPage: 10,
          page: vm.page,
          aroundLatLng: vm.currentLocation.latlong,
          aroundRadius: "100000",

          cacheable: true,
        });
        vm.page = vm.page + 1;

        let ids = res.hits.map((i) => i.objectID);
        if (ids.length != 0) {
          let cohortQuery = query(colCohorts, where(documentId(), "in", ids));
          let cohortDocs = (await getDocs(cohortQuery)).docs;
          cohortDocs = cohortDocs.map((i) => ({ ...i.data(), id: i.id }));
          vm.cohorts = vm.cohorts.concat(cohortDocs);
        }

        if (ids.length < 10) {
          $state.complete();
        } else {
          $state.loaded();
        }
      } catch (error) {
        $state.complete();
        vm.handleError(error);
      }
    },
  },
  activated() {
    let previous = JSON.stringify(this.previousLocation);
    let current = JSON.stringify(this.currentLocation);
    if (previous != current) {
      this.page = 0;
      this.$refs.loader.stateChanger.reset();
      this.cohorts = [];
      this.previousLocation = this.currentLocation;
    }
  },
};
</script>

<style></style>
