import { getRules } from "@/utils/common.utils";

export default {
  inheritAttrs: false,
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number, Array],
      default: "",
    },
    help: {
      type: String,
      default: "",
    },
    dense: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    rules: {
      type: String,
      default: "",
    },
    customRules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      input: "",
    };
  },
  computed: {
    getRules() {
      let { customRules, rules } = this;
      if (customRules.length > 0) return customRules;
      return getRules(rules);
    },
  },
  watch: {
    value(val) {
      this.input = val;
    },
  },
  mounted() {
    this.input = this.value;
  },
};
