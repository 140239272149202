<template>
  <app-layout title="#TalentOfVasaiVirar">
    <div class="flex items-center pr-4">
      <v-btn-toggle
        v-model="indexName"
        tile
        color="green"
        group
        @change="onSortChange"
        dense
      >
        <v-btn value="userposts" class="text-none"> Latest First </v-btn>
        <v-btn value="trending_posts" class="text-none"> Top First </v-btn>
      </v-btn-toggle>
    </div>
    <template v-for="(post, i) of posts">
      <post-view-card @onPost="onPost(i, $event)" :post="post" :key="i" />
      <v-divider :key="'divider-' + i"></v-divider>
    </template>
    <infinite-loading ref="loader" @infinite="infiniteHandler" :distance="430">
      <v-skeleton-loader
        slot="spinner"
        type="list-item-avatar-three-line, image, article"
      />
    </infinite-loading>
  </app-layout>
</template>

<script>
import PostViewCard from "@/components/public/PostViewCard.vue";
import InfiniteLoading from "vue-infinite-loading";
import { fetchAlgoliaPosts } from "@/utils/algolia.utils.js";
import AppLayout from "@/components/ui/AppLayout.vue";
export default {
  components: { PostViewCard, InfiniteLoading, AppLayout },
  data() {
    return {
      loading: true,
      posts: [],
      users: {},
      page: 0,
      indexName: "userposts",
    };
  },
  methods: {
    onSortChange() {
      this.page = 0;
      this.$refs.loader.stateChanger.reset();
      this.posts = [];
    },
    onPost(index, post) {
      post = { ...this.posts[index], ...post };
      this.$set(this.posts, index, post);
    },
    async infiniteHandler($state) {
      const vm = this;
      try {
        let hashtag = vm.$route.params.hashtag;
        let requestBody = {
          filters: "(hashtags:'#" + hashtag + "')",
          index: vm.indexName,
          hitsPerPage: 10,
          page: vm.page,
        };
        if (hashtag == "TalentOfVasaiVirar") {
          requestBody.aroundLatLng = [19.3919, 72.8397];
        } else if (hashtag == "TalentOfMiraBhayandar") {
          requestBody.aroundLatLng = [19.2952, 72.8544];
        }
        let res = await fetchAlgoliaPosts(requestBody);
        let posts = res.docs.map((i) => {
          return { ...i.data(), id: i.id };
        });
        await vm.getPostUsers(posts);
        console.log(vm.posts);
        vm.page = vm.page + 1;
        if (res.docs.length < 10) {
          $state.complete();
        } else {
          $state.loaded();
        }
      } catch (error) {
        $state.complete();
        vm.$alert.show(error.message);
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.post-view {
  max-width: 100p;
}
</style>
