<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on" :loading="loading">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn></template
    >
    <v-list class="pa-0">
      <v-list-item @click="deletePost()"> Delete Post </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { deleteObject, ref } from "firebase/storage";
import { colPosts, storage } from "@/utils/firebase.utils";
import { deleteDoc, doc } from "firebase/firestore";
export default {
  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    deletePost() {
      const vm = this;
      vm.$confirm.show({
        message:
          "Are you sure you want to delete this post? <br/>The action cannot be undone!",
        onConfirm: async () => {
          try {
            vm.loading = true;
            let { video_thumb, video, image } = vm.post;
            let files = image || [];
            if (video) files.push(video);
            if (video_thumb) files.push(video_thumb);
            files = files.filter((i) => {
              let isFirebase = i.startsWith("https://firebasestorage");
              let isCloud = i.startsWith("https://storage");
              return i && (isFirebase || isCloud);
            });
            for (let file of files) {
              try {
                await deleteObject(ref(storage, file));
              } catch (error) {
                console.log("Error", error);
              }
            }
            await deleteDoc(doc(colPosts, vm.post.id));
            vm.$emit("onPostDelete", vm.post.id);
            vm.loading = false;
          } catch (error) {
            vm.handleError(error);
          }
        },
      });
    },
  },
};
</script>

<style></style>
