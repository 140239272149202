import { searchClient } from "@/utils/algolia.utils";
import { colChatRooms } from "@/utils/firebase.utils";
import { addDoc, getDocs, limit, query, where } from "firebase/firestore";

export default {
  methods: {
    async openChat({ facet, value, name }) {
      const vm = this;
      vm.$loader.show();
      try {
        let group = this.currentLocation;
        let chatQuery = query(
          colChatRooms,
          where("group_id", "==", group.id),
          where("hashtag", "==", name),
          limit(1)
        );
        let room = (await getDocs(chatQuery)).docs[0];
        if (room != null) {
          vm.$router.push(`/chat-room/${room.id}`);
        } else {
          let ids = await vm.fetchFacetUsers({
            facet,
            value,
          });
          console.log(ids);
          if (ids.indexOf(vm.uid) == -1) ids.push(vm.uid);
          let snap = await addDoc(colChatRooms, {
            lastUpdated: new Date(),
            chat_type: "group",
            group_name: group.name,
            group_id: group.id,
            hashtag: name,
            group_icon: group.iconPath,
            users: ids,
          });
          vm.$router.push(`/chat-room/${snap.id}`);
          console.log(ids);
        }
        vm.$loader.hide();
      } catch (error) {
        vm.handleError(error);
      }
    },
    fetchFacetUsers({ value, facet }) {
      const vm = this;
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          let facetFilters = [];
          let requestBody = {
            page: 0,
            hitsPerPage: value,
            cacheable: false,
            ...vm.globalFilter,
          };
          if (vm.isInterest) {
            facetFilters.push(`interests:${facet}`);
          } else {
            facetFilters.push(`professionalskills:${facet}`);
          }
          if (facetFilters.length > 0) requestBody.facetFilters = facetFilters;
          const index = searchClient.initIndex("users");
          let res = await index.search("*", {
            ...requestBody,
          });
          let ids = res.hits.map((i) => i.objectID);
          resolve(ids);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
