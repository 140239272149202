<template>
  <app-layout title="Create Your Group">
    <form-wrapper @submit="submit()">
      <profile-upload
        v-model="form.iconPath"
        class="mb-4 mx-auto"
      ></profile-upload>
      <input-field
        label="Name your group"
        rules="required"
        v-model="form.name"
      />
      <Textarea
        label="Describe your group briefly here"
        v-model="form.description"
        rules="required"
      />
      <div class="input-label">Your group's location</div>
      <div class="text-caption ml-1">For nearby people to see and join</div>
      <address-field
        v-model="location"
        @onAddress="onAddress"
        rules="required"
      />

      <div class="text-center mb-3">or</div>
      <v-btn
        outlined
        block
        :loading="isLocationLoading"
        @click="useCurrent()"
        color="primary"
        class="my-3"
        >Use Current Location</v-btn
      >

      <div class="input-label mb-1">Group Type</div>
      <v-list-item-group v-model="form.type" color="primary" mandatory>
        <v-list-item v-for="(item, i) in types" :key="i" :value="item.name">
          <v-list-item-avatar>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
      <template v-if="form.type == 'Protected'">
        <div class="input-label mt-3">Join code</div>
        <v-otp-input type="number" v-model="form.passcode" length="4" />
      </template>
      <v-btn
        class="mb-10 mt-3 secondary"
        type="submit"
        :loading="loading"
        depressed
        block
      >
        Create group
      </v-btn>
    </form-wrapper>
  </app-layout>
</template>

<script>
import AppLayout from "@/components/ui/AppLayout.vue";
import FormWrapper from "@/components/ui/form/FormWrapper.vue";
import ProfileUpload from "@/components/ui/form/ProfileUpload.vue";
import InputField from "@/components/ui/form/InputField.vue";
import Textarea from "@/components/ui/form/Textarea.vue";
import AddressField from "@/components/ui/form/AddressField.vue";
import { doc, GeoPoint, setDoc } from "firebase/firestore";
import { colCohorts, colUsers, storage } from "@/utils/firebase.utils";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import { MAP_API_KEY } from "@/assets/constants/config.constants";
import axios from "axios";
export default {
  name: "CreateGroup",
  components: {
    AppLayout,
    FormWrapper,
    ProfileUpload,
    InputField,
    Textarea,
    AddressField,
  },
  data() {
    return {
      form: {
        passcode: "",
        iconPath: "",
      },
      location: "",
      loading: false,
      isLocationLoading: false,
    };
  },
  computed: {
    types() {
      return [
        {
          name: "Public",
          text: "Everyone can see and join",
          icon: "mdi-earth",
        },
        {
          name: "Private",
          icon: "mdi-shield-lock-outline",
          text: "People only with the link can join",
        },
        {
          name: "Protected",
          icon: "mdi-form-textbox-password",
          text: "People with the passcode can join",
        },
      ];
    },
  },
  methods: {
    onAddress(e) {
      if (e) {
        this.location = e.Address || "";
        this.form.place = {
          State: e.administrative_area_level_1,
          Address: `${e.name}, ${e.locality}, ${e.country}`,
          Country: e.country,
          City: e.locality,
          zipcode: e.postal_code || "",
          LatLang: new GeoPoint(e.latitude, e.longitude),
        };
      } else {
        this.form.place = null;
        this.location = "";
      }
    },
    useCurrent() {
      const vm = this;
      vm.isLocationLoading = true;
      if ("geolocation" in navigator) {
        // Get the user's current position
        navigator.geolocation.getCurrentPosition(
          function (position) {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;

            let apiKey = MAP_API_KEY;
            const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
            axios
              .get(apiUrl)
              .then((res) => {
                let results = res.data.results;
                console.log(results);
                vm.isLocationLoading = false;
                if (results.length == 0) {
                  vm.$alert("Nothing found here!");
                  return;
                }
                let e = results[0];
                vm.form.place = {
                  State: vm.findAddressDetail(e, "administrative_area_level_1"),
                  Address: `${vm.findAddressDetail(
                    e,
                    "sublocality"
                  )}, ${vm.findAddressDetail(
                    e,
                    "locality"
                  )}, ${vm.findAddressDetail(e, "country")}`,
                  Country: vm.findAddressDetail(e, "country"),
                  City: vm.findAddressDetail(e, "locality"),
                  zipcode: vm.findAddressDetail(e, "postal_code") || "",
                  LatLang: new GeoPoint(latitude, longitude),
                };
                vm.location = vm.form.place.Address;
                // vm.$alert.show("Nothing found here!");
              })
              .catch((e) => {
                vm.handleError(e);
              });

            // Now you can use the latitude and longitude values as needed
          },
          function (error) {
            vm.isLocationLoading = false;
            vm.$alert.show(
              "Please enable location access for goaround.me.",
              "Access Needed"
            );
            console.error("Error getting location:", error.message);
          }
        );
      } else {
        vm.isLocationLoading = false;
        vm.$alert.show("Geolocation is not supported by this browser.");
        console.log("Geolocation is not supported by this browser.");
      }
    },
    findAddressDetail(address, key) {
      let item = address.address_components.find((i) => i.types.includes(key));
      return item ? item.long_name : "";
    },
    async submit() {
      const vm = this;
      let userRef = doc(colUsers, vm.uid);
      let passcode = vm.form.passcode.toString();
      if (vm.form.type == "Protected" && passcode.length != 4) {
        vm.$alert.show("4 digit passcode is required");
        return;
      }
      let data = {
        ...vm.form,
        created_at: new Date(),
        created_by: userRef,
        members_count: 0,
      };
      try {
        vm.loading = true;
        let groupId = doc(colCohorts).id;
        let photo = vm.form.iconPath;
        if (photo.startsWith("data:image")) {
          let profileRef = ref(
            storage,
            `users/${vm.uid}/uploads/${groupId}.png`
          );
          await uploadString(profileRef, photo, "data_url");
          data.iconPath = await getDownloadURL(profileRef);
        }
        await setDoc(doc(colCohorts, groupId), data);
        vm.$router.replace(`/group/${groupId}`);
        vm.loading = false;
      } catch (error) {
        vm.handleError(error);
      }
    },
  },
};
</script>

<style></style>
