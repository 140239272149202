import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    _isAuth: false,
    _authUser: {},
    _muted: true,
    _currentLocation: {
      name: "Vasai",
      latlong: "19.4003,72.7777",
      type: "location",
    },
  },
  getters: {
    muted: (state) => state._muted,
    isAuth: (state) => state._isAuth,
    authUser: (state) => state._authUser,
    currentLocation: (state) => state._currentLocation,
  },
  mutations: {
    SET_MUTED(state, value) {
      state._muted = value;
    },
    SET_AUTH(state, value) {
      state._isAuth = value;
    },
    SET_USER(state, value) {
      state._authUser = value;
    },
    SET_CURRENT_LOCATION(state, value) {
      localStorage.location = JSON.stringify(value);
      value.ts = new Date().getTime();
      state._currentLocation = value;
    },
  },
  actions: {
    setCurrentLocation(context) {
      let local = localStorage.location;
      let location = null;
      if (local) {
        location = JSON.parse(local);
      }
      if (location) {
        location.ts = new Date().getTime();
        context.commit("SET_CURRENT_LOCATION", location);
      }
    },
  },
  modules: {},
});
