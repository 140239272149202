<template>
  <div>
    <div class="d-flex mt-5 justify-space-between align-center">
      <div class="subtitle-1 font-weight-medium">{{ title }}</div>
      <a
        class="subtitle-2 font-weight-medium blue--text text-decoration-underline"
        @click="openAll()"
        >Show All</a
      >
    </div>

    <v-slide-group
      multiple
      show-arrows
      :max="2"
      class="people-slide"
      style="height: 128px"
    >
      <v-slide-item v-for="(item, i) in list" :key="i">
        <v-card class="mr-3 my-1" width="160px" outlined rounded="lg">
          <v-card-text class="d-flex align-center flex-column">
            <div
              class="subtitle-2 mt-2 text-truncate text-lowercase text-center black--text"
              style="width: 100%"
            >
              {{ item.name }}
            </div>
            <div class="caption text-truncate text-center" style="width: 100%">
              {{ item.value }} People
            </div>
            <div>
              <v-btn
                class="mt-2"
                color="primary"
                text
                rounded
                small
                depressed
                @click="openAsk(item.facet)"
                >Ask</v-btn
              >
              <v-btn
                class="mt-2"
                color="primary"
                text
                rounded
                small
                v-if="isGroupSelected"
                depressed
                @click="openChat(item)"
                >Chat</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
      </v-slide-item>
    </v-slide-group>
  </div>
</template>

<script>
import { mapFacets } from "@/utils/common.utils";
import chatMixin from "@/mixins/chat.mixin.js";
export default {
  props: {
    type: {
      String,
      default: "interest",
    },
    items: {
      type: Object,
      default: () => ({}),
    },
    search: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      chatLoading: {},
    };
  },
  mixins: [chatMixin],
  computed: {
    list() {
      return mapFacets(this.items);
    },
    title() {
      let isInterest = this.type == "interest";
      let search = this.search.trim();
      if (search) {
        if (isInterest) {
          return `Interests matching: ${search}`;
        } else {
          return `Skills matching: ${search}`;
        }
      }
      return isInterest ? "Find by interest" : "Find by pro skills";
    },
    isInterest() {
      return this.title.indexOf("interest") != -1;
    },
  },
  methods: {
    openAsk(tag) {
      const vm = this;
      let path = `/post?hashtag=${tag}`;
      if (!vm.confirmLogin(path)) return;
      this.$router.push(path);
    },

    openAll() {
      if (this.isInterest) {
        this.$router.push("/interests");
      } else {
        this.$router.push("/skills");
      }
    },
  },
};
</script>

<style></style>
