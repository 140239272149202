import TalentOfVasaiVirar from "../pages/website/TalentOfVasaiVirar.vue";
import PrivacyPolicy from "../pages/website/PrivacyPolicy.vue";
import Terms from "../pages/website/Terms.vue";
import CookiePolicy from "../pages/website/CookiePolicy.vue";
let routes = [
  {
    path: "/TalentOfVasaiVirar",
    name: "TalentOfVasaiVirar",
    component: TalentOfVasaiVirar,
  },
  {
    path: "/TalentOfVasaiVirar.html",
    name: "TalentOfVasaiVirar-html",
    component: TalentOfVasaiVirar,
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicy,
  },
  {
    path: "/terms",
    name: "terms",
    component: Terms,
  },
  {
    path: "/cookie-policy",
    name: "cookie-policy",
    component: CookiePolicy,
  },
];

let websiteRoutes = routes.map((i) => {
  let meta = i.meta || {};
  meta.isWebsite = true;
  return { ...i, meta };
});

export default websiteRoutes;
