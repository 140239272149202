<template>
  <v-card class="d-flex mb-2" @click="openLink()">
    <v-list-item class="pa-0" style="overflow: hidden" three-line>
      <v-list-item-avatar class="ma-0" width="128px" height="100%" tile>
        <img :src="item.image" alt="" />
      </v-list-item-avatar>
      <v-list-item-content class="px-2">
        <v-list-item-title>
          {{ item.title }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ item.description }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    openLink() {
      window.open(this.item.url, "_blank");
    },
  },
};
</script>

<style></style>
