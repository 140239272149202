<template>
  <div class="text-center">
    <v-dialog v-model="loader" persistent :max-width="isStandBy ? 300 : 500">
      <v-card color="primary" dark>
        <v-card-text>
          {{ message }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0 mt-1"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import LoaderModal from "./index";
export default {
  data() {
    return {
      loader: false,
      message: "Please stand by",
    };
  },
  computed: {
    isStandBy() {
      return this.message == "Please stand by";
    },
  },
  beforeMount() {
    LoaderModal.EventBus.$on("show", (message) => {
      this.loader = true;
      this.message = message || "Please stand by";
    });
    LoaderModal.EventBus.$on("hide", () => {
      this.loader = false;
    });
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  height: 70px;
}
.v-card__text {
  padding-top: 17px !important;
}
</style>
