<template>
  <section class="hero flex pt-10 items-center flex-col">
    <div class="site-logo text-5xl text-secondary font-logo">
      <img src="../../assets/img/logo.png" width="150" />
    </div>
    <h1
      class="text-3xl md:text-6xl text-title font-bold text-center my-5 !leading-tight"
    >
      your local social network
    </h1>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
