<template>
  <div>
    <invite-header></invite-header>
    <v-container>
      <div class="d-flex justify-center" v-if="isPostLoading">
        <v-progress-circular
          indeterminate
          color="secondary"
        ></v-progress-circular>
      </div>
      <div class="invite-content" v-else-if="post">
        <template v-if="!isLoggedIn">
          <v-card v-show="!isOtpSend" flat>
            <v-card-title>Enter your phone number</v-card-title>
            <v-card-text class="pt-3">
              <v-text-field
                :show-feedback="false"
                v-model="phone"
                label="Phone Number"
                outlined
                dense
              />
              <div id="recaptcha-container" class="my-3"></div>
              <v-btn
                :loading="loading"
                depressed
                block
                class="secondary text-none"
                large
                @click="sendOTP()"
                >Submit</v-btn
              >
            </v-card-text>
          </v-card>
          <v-card v-show="isOtpSend" flat>
            <v-card-title> Verify your phone number </v-card-title>
            <v-card-text>
              <p class="input-label--medium text-black mt-0">
                Enter the 6-digit code we sent to
                <a
                  style="cursor: pointer"
                  @click="isOtpSend = false"
                  class="primary--text"
                  >+91{{ phone }}</a
                >
              </p>
              <v-text-field
                v-model="otp"
                label="OTP"
                outlined
                dense
              ></v-text-field>
              <v-btn
                class="secondary text-none"
                depressed
                block
                large
                :loading="loading"
                @click="submitOTP()"
                >Verify</v-btn
              >
            </v-card-text>
          </v-card>
        </template>
        <v-card v-else flat>
          <v-card-title>Register</v-card-title>
          <v-card-text>
            <v-form
              v-model="isValid"
              @submit.prevent="submitRegister()"
              ref="form"
            >
              <v-text-field
                label="Name"
                outlined
                class="mb-1"
                :rules="rules"
                v-model="form.display_name"
                dense
              ></v-text-field>

              <v-text-field
                class="mb-1"
                v-model="form.email"
                label="Email"
                :rules="emailRules"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="form.username"
                label="Username"
                class="mb-1"
                :rules="rules"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="form.Title"
                label="Title"
                class="mb-1"
                :rules="rules"
                outlined
                dense
              ></v-text-field>
              <address-field
                label="Your Location"
                class="mb-1"
                @onAddress="form.residential = $event"
              ></address-field>
              <!-- <combo-box label="Interests" v-model="form.interests"></combo-box>
              <combo-box
                label="Skills"
                v-model="form.professionalskills"
              ></combo-box>
 -->
              <v-btn
                class="secondary text-none"
                depressed
                block
                type="submit"
                large
                :loading="loading"
                >Submit & Claim Listing</v-btn
              >
            </v-form>
          </v-card-text>
        </v-card>
      </div>
    </v-container>
  </div>
</template>
<script>
import InviteHeader from "@/components/invite/InviteHeader.vue";
import inviteMixins from "@/mixins/invite.mixins";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth, colPosts, colUsers, db, storage } from "@/utils/firebase.utils";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import rulesConstants from "@/assets/constants/rules.constants.js";
import AddressField from "@/components/ui/form/AddressField.vue";
import {
  ref,
  move,
  getDownloadURL,
  uploadBytes,
  deleteObject,
} from "firebase/storage";
import axios from "axios";
export default {
  components: { InviteHeader, AddressField },
  data() {
    return {
      isPostLoading: false,
      post: false,
      isOtpSend: false,
      phone: "",
      loading: true,
      isValid: false,
      isLoggedIn: false,
      rules: [rulesConstants.required],
      emailRules: [rulesConstants.required, rulesConstants.email],
      form: {
        display_name: "",
        email: "",
        username: "",
        Title: "",
        interests: [],
        professionalskills: [],
        residential: null,
      },
      otp: "",
    };
  },
  mixins: [inviteMixins],
  methods: {
    async submitOTP() {
      const vm = this;
      if (vm.otp.length != 6) {
        vm.$alert.show("Please enter 6 digit otp");
        return;
      }
      let result = window.confirmationResult;
      try {
        vm.loading = true;
        let { user } = await result.confirm(vm.otp);
        let userDoc = (await getDoc(doc(colUsers, user.uid))).data();
        let id = vm.$route.params.id;
        if (userDoc == null) {
          vm.form.username = await vm.generateUniqueUsername(
            vm.form.display_name
          );
          vm.isLoggedIn = true;
        } else {
          await updateDoc(doc(colPosts, id), {
            is_claimed: true,
            userref: doc(colUsers, user.uid),
          });
          vm.$router.replace(`/signup-complete/${id}`);
          console.log("User already exists...");
        }
        vm.loading = false;
      } catch (e) {
        vm.loading = false;
        vm.$alert.show(e.message);
      }
    },
    async generateUniqueUsername(name) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          if (!name) {
            resolve("");
            return;
          }
          const cleanedName = name.replace(/[^a-zA-Z0-9]/g, ".");
          let suffix = "";
          let isUnique = false;
          let generatedUsername = "";
          let attempts = 0;
          while (!isUnique && attempts < 10) {
            generatedUsername = (cleanedName + suffix).toLowerCase();
            let q = query(colUsers, where("username", "==", generatedUsername));
            const snapshot = await getDocs(q);
            if (snapshot.empty) {
              isUnique = true;
            }
            suffix = suffix === "" ? "1" : (parseInt(suffix) + 1).toString();
            attempts++;
          }
          resolve(generatedUsername);
        } catch (error) {
          reject(error);
        }
      });
    },

    sendOTP() {
      const vm = this;
      if (vm.phone.length != 10) {
        vm.$alert.show("Please enter valid phone number");
        return;
      }
      var appVerifier = window.recaptchaVerifier;
      vm.loading = true;
      signInWithPhoneNumber(auth, `+91${vm.phone}`, appVerifier)
        .then((result) => {
          vm.loading = false;
          window.confirmationResult = result;
          console.log(window.confirmationResult);
          vm.isOtpSend = true;
        })
        .catch(function (e) {
          vm.loading = false;
          vm.$alert.show(e.message);
        });
    },
    initCaptcha() {
      this.loading = true;
      // let div = document.getElementById("recaptcha-container");
      // if (div) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: function (response) {
            console.log(response);
          },
        },
        auth
      );
      window.recaptchaVerifier.render().then((widgetId) => {
        window.recaptchaWidgetId = widgetId;
        this.loading = false;
      });
      // }
    },
    async submitRegister() {
      const vm = this;
      vm.$refs.form.validate();
      if (!vm.isValid) return;
      if (vm.form.residential == null) {
        vm.$alert.show("Please  select your location.");
        return;
      }
      try {
        vm.loading = true;
        let username = vm.form.username.trim().toLowerCase();
        let q = query(colUsers, where("username", "==", username));
        let size = (await getDocs(q)).size;
        if (size > 0) {
          vm.loading = false;
          vm.$alert.show(
            "Username already exists. Please use a different username"
          );
          return;
        }
        let data = { ...vm.form, created_time: new Date() };
        data.phone_number = `+91${vm.phone}`;
        let uid = auth.currentUser.uid;
        let batch = writeBatch(db);
        let id = vm.$route.params.id;
        let userRef = doc(colUsers, uid);
        let images = [];
        for (let image of vm.post.image) {
          let name = decodeURIComponent(image)
            .split("/")
            .pop()
            .split("#")[0]
            .split("?")[0];
          let sourceRef = ref(storage, image);
          const destinationRef = ref(storage, `users/${uid}/uploads/${name}`);
          let file = (await axios.get(image, { responseType: "blob" })).data;
          await uploadBytes(destinationRef, file);
          let url = await getDownloadURL(destinationRef);
          await deleteObject(sourceRef);
          images.push(url);
        }

        batch.set(userRef, data);
        batch.update(doc(colPosts, id), {
          is_claimed: true,
          image: images,
          userref: doc(colUsers, uid),
        });
        vm.form.interests.forEach((skillname) => {
          let ref = collection(userRef, "professionalskills");
          batch.set(doc(ref), {
            skillname,
            thumbs_up_count: 0,
          });
        });
        vm.form.interests.forEach((interestname) => {
          let ref = collection(userRef, "interests");
          batch.set(doc(ref), {
            interestname,
            thumbs_up_count: 0,
          });
        });
        await batch.commit();
        // let segments = vm.post.userref._key.path.segments;
        // let userUid = segments[segments.length - 1];
        vm.$router.replace(`/signup-complete/${id}`);
        vm.loading = false;
      } catch (e) {
        vm.loading = false;
        vm.$alert.show(e.message);
      }
    },
  },
  mounted() {
    this.getPost();
  },
};
</script>
