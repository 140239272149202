<template>
  <app-layout title="Chats" :full-width="true">
    <v-divider></v-divider>
    <v-list class="py-0">
      <template v-for="(room, i) in rooms">
        <v-list-item
          :key="room.id"
          @click="$router.push(`/chat-room/${room.id}`)"
        >
          <v-list-item-avatar>
            <user-logo :size="40" :user="room.user"></user-logo>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title> {{ room.user.display_name }}</v-list-item-title>
            <v-list-item-subtitle
              v-if="room.user.hashtag"
              class="primary--text"
            >
              {{ room.user.hashtag }}</v-list-item-subtitle
            >
            <v-list-item-subtitle v-else>
              {{ room.content }}</v-list-item-subtitle
            >
          </v-list-item-content>
          <v-list-item-action>
            <v-list-item-action-text
              v-text="room.timestamp"
            ></v-list-item-action-text>
            <!-- <v-badge color="success" content="6" inline></v-badge> -->
          </v-list-item-action>
        </v-list-item>
        <v-divider :key="'divider-' + i"></v-divider>
      </template>
    </v-list>
    <infinite-loading ref="loader" @infinite="infiniteHandler" :distance="430">
      <v-skeleton-loader
        slot="spinner"
        type="list-item-avatar-three-line, image, article"
      />
    </infinite-loading>
  </app-layout>
</template>

<script>
import AppLayout from "@/components/ui/AppLayout.vue";
import UserLogo from "@/components/public/UserLogo.vue";
import {
  collection,
  documentId,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { colChatRooms, colUsers } from "@/utils/firebase.utils";
import InfiniteLoading from "vue-infinite-loading";
import { formatTimestamp, mapDocs } from "@/utils/common.utils";
export default {
  components: { AppLayout, UserLogo, InfiniteLoading },
  data() {
    return {
      rooms: [],
      lastRoom: null,
      users: [],
      listener: null,
    };
  },
  methods: {
    async infiniteHandler($state) {
      const vm = this;
      try {
        let conditions = [
          orderBy("lastUpdated", "desc"),
          where("users", "array-contains", vm.uid),
        ];
        if (vm.lastRoom) {
          conditions.push(startAfter(vm.lastRoom));
        }
        conditions.push(limit(10));
        let roomQuery = query(colChatRooms, ...conditions);
        let docs = (await getDocs(roomQuery)).docs;
        vm.lastRoom = docs[docs.length - 1];
        let rooms = await vm.mapRoomsUser(mapDocs(docs));
        rooms = await vm.mapLastMessage(rooms);
        vm.rooms = vm.rooms.concat(rooms);
        if (vm.listener == null) {
          vm.listenRoomUpdate();
        }
        if (docs.length < 10) {
          $state.complete();
        } else {
          $state.loaded();
        }
      } catch (error) {
        $state.complete();
        vm.handleError(error);
      }
    },
    mapRoomsUser(rooms) {
      const vm = this;
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          let peopleIds = [];
          rooms.forEach((room) => {
            if (room.chat_type == "one2one") {
              peopleIds = peopleIds.concat(
                room.users.filter((i) => i != vm.uid)
              );
            }
          });
          let users = [];
          if (peopleIds.length > 0) {
            let userDocQuery = query(
              colUsers,
              where(documentId(), "in", peopleIds)
            );
            users = mapDocs((await getDocs(userDocQuery)).docs);
          }
          rooms = rooms.map((i) => {
            i.user = null;
            if (i.chat_type == "one2one") {
              let uid = i.users.find((u) => u != vm.uid);
              let user = users.find((u) => u.id == uid);
              i.user = user;
            }
            if (i.chat_type == "group") {
              i.user = {
                photo_url: i.group_icon,
                display_name: i.group_name,
                hashtag: i.hashtag,
              };
            }
            return i;
          });
          rooms = rooms.filter((i) => i.user != null);

          resolve(rooms);
        } catch (error) {
          reject(error);
        }
      });
    },
    mapLastMessage(rooms) {
      const vm = this;
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          let promises = [];
          for (let room of rooms) {
            promises.push(vm.fetchLastMessage(room));
          }
          let result = await Promise.all(promises);
          rooms = rooms.map((i) => {
            let message = result.find((m) => m.roomId == i.id);
            let messageUpdate = vm.getLastMessage({ message, room: i });
            i = { ...i, ...messageUpdate };
            return i;
          });
          resolve(rooms);
        } catch (error) {
          reject(error);
        }
      });
    },
    fetchLastMessage(room) {
      let messageQuery = query(
        collection(colChatRooms, room.id, "messages"),
        orderBy("timestamp", "desc"),
        limit(1)
      );
      return getDocs(messageQuery)
        .then((snap) => {
          if (snap.docs[0]) {
            return { ...snap.docs[0].data(), roomId: room.id };
          } else {
            return {};
          }
        })
        .catch((e) => {
          console.log(e);
          return {};
        });
    },
    formatLastMessage(message) {
      if (!message.timestamp) return;

      let content = message.content;
      if (message.files?.length) {
        const file = message.files[0];
        content = `${file.name}.${file.extension || file.type}`;
      }
      return {
        content,
        timestamp: formatTimestamp(
          new Date(message.timestamp.seconds * 1000),
          message.timestamp
        ),
      };
    },
    listenRoomUpdate() {
      const vm = this;
      let conditions = [
        orderBy("lastUpdated", "desc"),
        where("users", "array-contains", vm.uid),
        limit(1),
      ];
      vm.listener = onSnapshot(
        query(colChatRooms, ...conditions),
        async (snap) => {
          if (snap.docs[0]) {
            let room = { ...snap.docs[0].data(), id: snap.docs[0].id };
            let index = vm.rooms.findIndex((i) => i.id === room.id);
            if (index != -1) {
              let oldLastUpdate = vm.rooms[index].lastUpdated.seconds;
              let newLastUpdate = room.lastUpdated.seconds;
              if (oldLastUpdate != newLastUpdate) {
                let message = await vm.fetchLastMessage(room);
                let messageUpdate = vm.getLastMessage({ message, room });
                vm.rooms[index] = {
                  ...vm.rooms[index],
                  ...messageUpdate,
                  lastUpdated: room.lastUpdated,
                };
                vm.rooms = [...vm.rooms];
                vm.sortRooms();
              }
            } else {
              let message = await vm.fetchLastMessage(room);
              let messageUpdate = vm.getLastMessage({ message, room });
              let rooms = await vm.mapRoomsUser([room]);
              room = { ...messageUpdate, ...rooms[0] };
              vm.rooms.push(room);
              vm.sortRooms();
            }
          }
        }
      );
      console.log("Listening room update....");
    },
    getLastMessage({ message, room }) {
      let update = {};
      if (message && message.timestamp) {
        let { timestamp, content } = this.formatLastMessage(message);
        update.content = content;
        update.timestamp = timestamp;
      } else {
        update.content = "Room created";
        update.timestamp = formatTimestamp(
          new Date(room.lastUpdated.seconds * 1000),
          room.lastUpdated
        );
      }
      return update;
    },
    sortRooms() {
      this.rooms = [...this.rooms].sort((a, b) => {
        return b.lastUpdated.seconds - a.lastUpdated.seconds;
      });
    },
  },
};
</script>

<style></style>
