<template>
  <v-dialog v-model="dialog" persistent max-width="400px">
    <dialog-card
      @close="dialog = false"
      title="Enter Passcode"
      @submit="submit()"
    >
      <p>Contact the group owner for the passcode</p>
      <v-otp-input type="number" v-model="passcode" length="4" />
    </dialog-card>
  </v-dialog>
</template>

<script>
import DialogCard from "../ui/DialogCard.vue";
export default {
  props: {
    group: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dialog: false,
      passcode: "",
    };
  },
  components: { DialogCard },
  methods: {
    submit() {
      const vm = this;
      let passcode = vm.passcode.toString();
      if (passcode != vm.group.passcode.toString()) {
        vm.$snackbar.show("Incorrect passcode.");
        return;
      }
      vm.passcode = "";
      vm.dialog = false;
      vm.$emit("onConfirm");
    },
  },
};
</script>

<style></style>
