<template>
  <section
    class="justify-between flex py-7 items-center border-t border-paragraph flex-col md:flex-row"
  >
    <p class="text-paragraph hidden">
      Copyright © 2022 around All Rights Reserved.
    </p>
    <div class="site-logo text-5xl text-primary font-logo">
      <img src="../../assets/img/logo.png" width="150px" />
    </div>
    <v-spacer></v-spacer>
    <div class="d-flex space-x-5 mt-5 mt-md-0">
      <a @click="$router.push('/terms')" class="underline">Terms</a
      ><a @click="$router.push('/privacy-policy')" class="underline"
        >Privacy Policy</a
      ><a @click="$router.push('/cookie-policy')" class="underline"
        >Cookie Policy</a
      >
    </div>
    <div class="flex space-x-5 hidden">
      <button
        class="w-12 h-12 rounded-full border-2 border-secondary text-secondary text-xl"
      >
        <i class="fa-brands fa-facebook-f"></i></button
      ><button
        class="w-12 h-12 rounded-full border-2 border-secondary text-secondary text-xl"
      >
        <i class="fa-brands fa-twitter"></i></button
      ><button
        class="w-12 h-12 rounded-full border-2 border-secondary text-secondary text-xl"
      >
        <i class="fa-brands fa-linkedin-in"></i></button
      ><button
        class="w-12 h-12 rounded-full border-2 border-secondary text-secondary text-xl"
      >
        <i class="fa-brands fa-instagram"></i>
      </button>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
