<template>
  <app-layout :title="title">
    <people-filter v-model="filter" ref="filter" @onFilter="onFilter($event)" />
    <people-card v-for="(user, i) in peoples" :user="user" :key="i" />
    <infinite-loading ref="loader" @infinite="infiniteHandler" :distance="430">
      <v-skeleton-loader
        slot="spinner"
        type="list-item-avatar-three-line, image, article"
      />
    </infinite-loading>
  </app-layout>
</template>

<script>
import AppLayout from "@/components/ui/AppLayout.vue";
import { fetchAlgoliaUsers } from "@/utils/algolia.utils";
import { mapDocs } from "@/utils/common.utils";
import peopleMixins from "@/mixins/people.mixins";
import InfiniteLoading from "vue-infinite-loading";
import PeopleFilter from "@/components/public/PeopleFilter.vue";
import PeopleCard from "@/components/public/PeopleCard.vue";
export default {
  components: {
    AppLayout,
    InfiniteLoading,
    PeopleFilter,
    PeopleCard,
  },
  mixins: [peopleMixins],
  data() {
    return {
      peoples: [],
      loading: true,
      previousQuery: {},
      search: "",
      filter: {
        interests: "all",
        professionalskills: "all",
      },
      previousLocation: null,
      page: 0,
    };
  },
  computed: {
    title() {
      return this.isGroupSelected ? "Group members" : "People around";
    },
  },
  watch: {
    currentLocation: {
      handler() {
        if (this.$route.name == "people") {
          this.onFilter(this.filter);
          this.$refs.filter.fetchFacets();
        }
      },
      deep: true,
    },
  },

  methods: {
    async infiniteHandler($state) {
      const vm = this;
      try {
        let { interests, professionalskills, search } = vm.filter;
        let query = search?.trim() || "*";
        let facetFilters = [];

        let requestBody = {
          page: vm.page,
          hitsPerPage: 10,
          query,
          index: "users",
          ...vm.globalFilter,
        };
        if (interests && interests != "all") {
          facetFilters.push(`interests:${interests}`);
        }
        if (professionalskills && professionalskills != "all") {
          facetFilters.push(`professionalskills:${professionalskills}`);
        }
        if (facetFilters.length > 0) requestBody.facetFilters = facetFilters;
        let peopleRes = await fetchAlgoliaUsers({
          ...requestBody,
        });

        let peoples = mapDocs(peopleRes.docs).map((i) => {
          i.loading = false;
          return i;
        });

        peoples = await vm.getUsersFollow(peoples);
        vm.peoples = vm.peoples.concat(peoples);
        vm.page = parseInt(vm.page) + 1;
        if (peopleRes.docs.length < 10) {
          $state.complete();
        } else {
          $state.loaded();
        }
      } catch (error) {
        $state.complete();
        vm.handleError(error);
      }
    },
    onFilter(event) {
      this.filter = event;
      this.page = 0;
      this.$refs.loader.stateChanger.reset();
      console.log("Fetching people list...");
      this.peoples = [];
      if (this.$route.name == "people") {
        this.$router.replace({
          name: "people",
          query: this.filter,
        });
      }
      this.previousQuery = this.filter;
    },
  },
  activated() {
    let query = this.$route.query;
    let previousQuery = JSON.stringify(query);
    let currentQuery = JSON.stringify(this.filter);
    Object.keys(this.filter).forEach((i) => {
      if (query[i]) {
        this.filter[i] = query[i];
      }
    });
    let previous = JSON.stringify(this.previousLocation);
    let current = JSON.stringify(this.currentLocation);
    if (previous != current || previousQuery != currentQuery) {
      this.onFilter(this.filter);
      this.previousLocation = this.currentLocation;
      this.$refs.filter.fetchFacets();
    }
  },
};
</script>

<style></style>
