<template>
  <div class="pt-3">
    <people-card v-for="(user, i) in peoples" :user="user" :key="i" />
    <infinite-loading ref="loader" @infinite="infiniteHandler" :distance="430">
      <v-skeleton-loader
        slot="spinner"
        type="list-item-avatar-three-line, image, article"
      />
    </infinite-loading>
  </div>
</template>

<script>
import { mapDocs } from "@/utils/common.utils";
import _ from "lodash";
import peopleMixins from "@/mixins/people.mixins";

import InfiniteLoading from "vue-infinite-loading";
import {
  collection,
  doc,
  documentId,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { colUsers } from "@/utils/firebase.utils";
import PeopleCard from "./PeopleCard.vue";
export default {
  props: {
    collection: {
      type: String,
      default: "followers",
    },
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    InfiniteLoading,
    PeopleCard,
  },
  mixins: [peopleMixins],
  data() {
    return {
      peoples: [],
      loading: true,
      lastUser: null,
    };
  },
  methods: {
    async infiniteHandler($state) {
      const vm = this;
      try {
        let userRef = doc(colUsers, vm.user.id);
        let collectionRef = collection(userRef, this.collection);
        let conditions = [orderBy("created_at", "desc")];
        if (vm.lastUser) {
          conditions.push(startAfter(vm.lastUser));
        }
        conditions.push(limit(10));
        let userQuery = query(collectionRef, ...conditions);
        let docs = (await getDocs(userQuery)).docs;
        vm.lastUser = docs[docs.length - 1];
        let peopleIds = docs.map((i) => vm.getDocPath(i.data().user_ref));
        if (peopleIds.length > 0) {
          let userDocQuery = query(
            colUsers,
            where(documentId(), "in", peopleIds)
          );
          let users = (await getDocs(userDocQuery)).docs;
          let peoples = mapDocs(users).map((i) => {
            i.loading = false;
            return i;
          });
          peoples = await vm.getUsersFollow(peoples);
          vm.peoples = vm.peoples.concat(peoples);
        }
        if (docs.length < 10) {
          $state.complete();
        } else {
          $state.loaded();
        }
      } catch (error) {
        $state.complete();
        vm.handleError(error);
      }
    },
    getAddress(user) {
      return _.get(user, "residential.Address", "");
    },
  },
};
</script>

<style></style>
