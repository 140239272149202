<template>
  <app-layout :title="title">
    <div v-if="isLoading">
      <v-skeleton-loader
        slot="spinner"
        type="list-item-avatar-three-line, image, article"
      />
    </div>
    <div v-else>
      <div class="text-center">
        <v-avatar color="secondary" size="120">
          <v-img v-if="user.photo_url" :src="user.photo_url" cover></v-img>
          <span v-else class="text-h5 text-uppercase">
            {{ getLogoName }}
          </span>
        </v-avatar>
        <div class="title mt-1">{{ user.display_name }}</div>
        <v-btn
          small
          cl
          v-if="userId == uid && isAuth"
          class="primary my-1"
          to="/update-profile"
          depressed
          >Edit Profile</v-btn
        >
        <div v-if="userId != uid && isAuth" class="d-flex justify-center my-1">
          <template>
            <v-btn
              v-if="user.isFollowing"
              class="primary mr-1"
              outlined
              rounded
              @click="unFollowUser(user, init)"
              :loading="isFollowLoading"
              small
              >Following</v-btn
            >
            <v-btn
              @click="followUser(user, init)"
              class="primary mr-1"
              v-else
              rounded
              :loading="isFollowLoading"
              small
              depressed
              >Follow</v-btn
            >
            <v-btn
              color="grey"
              :loading="chatLoading"
              @click="openChat()"
              class="ml-1"
              outlined
              small
              >Message</v-btn
            >
          </template>
        </div>

        <div
          class="subtitle-2 font-weight-regular black--text"
          v-if="user.Title"
        >
          {{ user.Title }}
        </div>
        <div class="subtitle-2 grey--text" v-if="location">
          <v-icon size="16" color="primary">mdi-map-marker</v-icon>
          {{ location }}
        </div>
      </div>
      <v-divider class="my-3" />
      <template v-if="user.bio">
        <div class="subtitle-2 black--text">Bio</div>
        <read-more
          more-str="read more"
          class="caption mb-1 read-more"
          :text="user.bio"
          less-str="read less"
          :max-chars="80"
        ></read-more>
      </template>

      <template v-if="user.interests && user.interests.length">
        <div class="subtitle-2 black--text">Interests</div>
        <div class="py-1">
          <v-chip
            v-for="(item, i) in user.interests"
            :key="i"
            outlined
            class="mr-1 mb-1 text-lowercase"
            color="primary"
            small
            >{{ item }}</v-chip
          >
        </div>
      </template>

      <template
        v-if="user.professionalskills && user.professionalskills.length"
      >
        <div class="subtitle-2 black--text">Pro Skills</div>
        <div class="py-1">
          <v-chip
            v-for="(item, i) in user.professionalskills"
            :key="i"
            class="mr-1 mb-1 text-lowercase"
            outlined
            color="primary"
            small
            >{{ item }}</v-chip
          >
        </div>
      </template>

      <v-divider
        class="my-3"
        v-if="user?.interests?.length && user?.professionalskills?.length"
      />
      <v-tabs v-model="tab" icons-and-text fixed-tabs>
        <v-tab>
          <div class="text-center">
            <div class="title font-weight-bold">
              {{ user.post_count || 0 }}
            </div>
            <div class="subtitle-2 text-none">Posts</div>
          </div>
        </v-tab>
        <v-tab>
          <div class="text-center">
            <div class="title font-weight-bold">
              {{ user.followers_count || 0 }}
            </div>
            <div class="subtitle-2 text-none">Followers</div>
          </div>
        </v-tab>
        <v-tab>
          <div class="text-center">
            <div class="title font-weight-bold">
              {{ user.following_count || 0 }}
            </div>
            <div class="subtitle-2 text-none">Following</div>
          </div>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <profile-posts :userId="userId" :user="user" />
        </v-tab-item>
        <v-tab-item>
          <profile-people collection="followers" :user="user" />
        </v-tab-item>
        <v-tab-item>
          <profile-people collection="followings" :user="user" />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </app-layout>
</template>

<script>
import AppLayout from "@/components/ui/AppLayout.vue";
import {
  addDoc,
  collection,
  doc,
  getCountFromServer,
  getDocs,
  limit,
  query,
  where,
} from "firebase/firestore";
import { colChatRooms, colPosts, colUsers } from "@/utils/firebase.utils";
import _ from "lodash";
import ProfilePosts from "@/components/public/ProfilePosts.vue";
import ProfilePeople from "@/components/public/ProfilePeople.vue";
import peopleMixins from "@/mixins/people.mixins";
export default {
  name: "Profile",
  components: { AppLayout, ProfilePosts, ProfilePeople },
  data() {
    return {
      user: {},
      tab: 0,
      userId: "",
      isLoading: true,
      isFollowLoading: false,
      chatLoading: false,
    };
  },
  computed: {
    title() {
      if (this.user.display_name) return this.user.display_name;
      return "Profile";
    },
    getLogoName() {
      if (this.user && this.user.display_name) {
        return this.user.display_name.slice(0, 2);
      }
      return "A";
    },
    userName() {
      let id = this.$route.params.id;
      if (id.startsWith("@")) {
        return id.substring(1);
      }
      return id;
    },
    location() {
      return _.get(this.user, "residential.Address", "");
    },
  },
  mixins: [peopleMixins],
  watch: {
    $route() {
      this.tab = 0;
      this.init();
    },
  },
  methods: {
    async openChat() {
      const vm = this;
      if (!vm.confirmLogin()) {
        return;
      }
      try {
        vm.chatLoading = true;
        let room = {
          lastUpdated: new Date(),
          users: [vm.userId, vm.uid],
          chat_type: "one2one",
        };
        console.log(room.users);
        let userQueryOne = where("users", "==", [vm.userId, vm.uid]);
        let userQueryTwo = where("users", "==", [vm.uid, vm.userId]);
        let chatRoomQuerys = [
          where("chat_type", "==", room.chat_type),
          limit(1),
        ];
        let roomQuery = query(
          colChatRooms,
          ...[userQueryOne, ...chatRoomQuerys]
        );
        let snap = (await getDocs(roomQuery)).docs[0];
        if (!snap) {
          let roomQuery = query(
            colChatRooms,
            ...[userQueryTwo, ...chatRoomQuerys]
          );
          snap = (await getDocs(roomQuery)).docs[0];
        }
        if (!snap) {
          snap = await addDoc(colChatRooms, room);
        }
        vm.$router.push(`/chat-room/${snap.id}`);
        vm.chatLoading = false;
      } catch (error) {
        vm.chatLoading = false;
        vm.handleError(error);
      }
    },
    async init() {
      const vm = this;
      vm.isLoading = true;
      if (!vm.userName) return;
      console.log("Fetching profile name...");
      try {
        let userQuery = query(
          colUsers,
          where("username", "==", vm.userName),
          limit(1)
        );
        let user = (await getDocs(userQuery)).docs[0];
        if (user == null) {
          vm.$router.replace("/");
          vm.$alert.show("User not found.");
          return;
        }
        vm.userId = user.id;
        let userRef = doc(colUsers, vm.userId);
        vm.user = user.data();
        vm.user.id = vm.userId;
        let postCountQuery = query(colPosts, where("userref", "==", userRef));
        let postCountSnap = await getCountFromServer(postCountQuery);
        vm.user.post_count = postCountSnap.data().count;
        vm.user.loading = false;
        if (vm.userId != vm.uid && vm.isAuth) {
          let authUserRef = doc(colUsers, vm.uid);
          let followingRef = collection(authUserRef, "followings");
          let followQuery = query(
            followingRef,
            where("user_ref", "==", userRef),
            limit(1)
          );
          let followDocs = (await getDocs(followQuery)).docs;
          vm.user.isFollowing = followDocs.length != 0;
        }

        vm.isLoading = false;
      } catch (error) {
        vm.$router.replace("/");
        vm.handleError(error);
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
