<template>
  <app-layout :title="title">
    <feed-filter v-model="filter" ref="feed" @onFilter="onFilter" />
    <v-btn
      outlined
      class="my-3 primary--text text-subtitle-2"
      block
      @click="$router.push('/TalentOfVasaiVirar')"
      v-if="false"
    >
      Learn about&nbsp;
      <b>#TalentOfVasaiVirar</b>&nbsp;contest
    </v-btn>
    <template v-for="(post, i) of posts">
      <post-view-card @onPost="onPost(i, $event)" :post="post" :key="i" />
      <v-divider :key="'divider-' + i"></v-divider>
    </template>
    <infinite-loading ref="loader" @infinite="infiniteHandler" :distance="430">
      <v-skeleton-loader
        slot="spinner"
        type="list-item-avatar-three-line, image, article"
      />
    </infinite-loading>
    <v-btn
      fixed
      class="mb-13"
      bottom
      right
      fab
      to="/post"
      color="primary"
      width="50px"
      height="50px"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </app-layout>
</template>

<script>
import PostViewCard from "@/components/public/PostViewCard.vue";
import InfiniteLoading from "vue-infinite-loading";
import { fetchAlgoliaPosts } from "@/utils/algolia.utils.js";
import AppLayout from "@/components/ui/AppLayout.vue";
import FeedFilter from "@/components/public/FeedFilter.vue";
export default {
  components: { PostViewCard, InfiniteLoading, AppLayout, FeedFilter },
  data() {
    return {
      loading: true,
      posts: [],
      users: {},
      page: 0,
      filter: {
        hashtags: "all",
        tag: "all",
        sort: "latest",
      },
    };
  },
  computed: {
    title() {
      return this.isGroupSelected ? "Latest posts" : "Latest around";
    },
  },
  watch: {
    currentLocation: {
      handler() {
        if (this.$route.name == "feed") {
          this.onFilter(this.filter);
          this.$refs.feed.fetchFacets();
        }
      },
      deep: true,
    },
  },

  methods: {
    onSortChange() {
      this.page = 0;
      this.$refs.loader.stateChanger.reset();
      this.posts = [];
    },
    onPost(index, post) {
      post = { ...this.posts[index], ...post };
      this.$set(this.posts, index, post);
    },
    async infiniteHandler($state) {
      const vm = this;
      try {
        let { tag, hashtags, sort } = vm.filter;
        let requestBody = {
          index: sort == "latest" ? "userposts" : "trending_posts",
          hitsPerPage: 10,
          page: vm.page,
          query: "*",
          ...vm.globalFilter,
        };
        let facetFilters = [];
        if (hashtags && hashtags != "all") {
          facetFilters.push(`hashtags:${hashtags}`);
        }
        if (tag && tag != "all") {
          facetFilters.push(`tag:${tag}`);
        }
        if (facetFilters.length > 0) requestBody.facetFilters = facetFilters;
        let res = await fetchAlgoliaPosts(requestBody);
        let posts = res.docs.map((i) => {
          return { ...i.data(), id: i.id };
        });
        posts = await vm.getPostLastComments(posts);
        await vm.getPostUsers(posts);
        vm.page = vm.page + 1;
        if (res.docs.length < 10) {
          $state.complete();
        } else {
          $state.loaded();
        }
      } catch (error) {
        $state.complete();
        vm.$alert.show(error.message);
      }
    },
    onFilter(event) {
      console.log("Fetching feed list...");
      this.filter = event;
      this.page = 0;
      this.$refs.loader.stateChanger.reset();
      this.posts = [];
      if (this.$route.name == "feed") {
        this.$router.replace({
          name: "feed",
          query: this.filter,
        });
      }
    },
  },
  beforeMount() {
    let { hashtags, tag, sort } = this.$route.query;
    if (hashtags) {
      hashtags = decodeURIComponent(hashtags);
      if (!hashtags.startsWith("#")) hashtags = `#${hashtags}`;
      this.filter.hashtags = hashtags;
    }
    if (tag) {
      this.filter.tag = tag;
    }
    if (sort) {
      this.filter.sort = sort;
    }
  },
  activated() {
    let previous = JSON.stringify(this.previousLocation);
    let current = JSON.stringify(this.currentLocation);
    if (previous != current) {
      this.previousLocation = this.currentLocation;
      this.onFilter(this.filter);
      this.$refs.feed.fetchFacets();
    }
  },
};
</script>

<style lang="scss">
.post-view {
  max-width: 100p;
}
</style>
