<template>
  <v-card outlined rounded="lg" class="mb-3" :to="`/${user.username}`">
    <v-list-item three-line>
      <v-list-item-avatar>
        <v-avatar color="secondary">
          <v-img :src="getUserProfileUrl(user)"></v-img>
        </v-avatar>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="subtitle-1 text-capitalize">{{
          user.display_name
        }}</v-list-item-title>
        <v-list-item-subtitle class="caption black--text">
          {{ user.Title || "-" }}
        </v-list-item-subtitle>
        <v-list-item-subtitle class="caption">
          {{ getAddress(user) }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn
          class="mt-2"
          v-if="user.isFollowing"
          color="primary"
          outlined
          rounded
          @onFollowUpdate="$emit('onFollowUpdate')"
          @click.prevent="unFollowUser(user)"
          :loading="user.loading"
          small
          >Following</v-btn
        >
        <v-btn
          @click.prevent="followUser(user)"
          class="primary mt-2"
          v-else
          rounded
          :loading="user.loading"
          small
          @onFollowUpdate="$emit('onFollowUpdate')"
          depressed
          >Follow</v-btn
        >
      </v-list-item-action>
    </v-list-item>
    <v-card-text class="pt-0">
      <div class="subtitle-2 black--text">Interests</div>
      <div v-if="user.interests && user.interests.length" class="py-2">
        <v-chip
          v-for="(item, i) in user.interests"
          :key="i"
          outlined
          class="mr-1 mb-1 text-lowercase"
          color="primary"
          small
          >{{ item }}</v-chip
        >
      </div>
      <div v-else class="py-2">None Listed</div>
      <div class="subtitle-2 black--text">Pro Skills</div>
      <div
        v-if="user.professionalskills && user.professionalskills.length"
        class="py-2"
      >
        <v-chip
          v-for="(item, i) in user.professionalskills"
          :key="i"
          class="mr-1 mb-1 text-lowercase"
          outlined
          color="primary"
          small
          >{{ item }}</v-chip
        >
      </div>
      <div v-else class="py-2">None Listed</div>
    </v-card-text>
  </v-card>
</template>

<script>
import _ from "lodash";
import peopleMixins from "@/mixins/people.mixins";
export default {
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  mixins: [peopleMixins],
  methods: {
    getAddress(user) {
      return _.get(user, "residential.Address", "");
    },
  },
};
</script>

<style></style>
