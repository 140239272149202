<template>
  <div class="profile-upload" @click="showCropper = true">
    <img :src="image" v-if="image" />
    <v-btn icon tile class="profile-btn primary" dark>
      <v-icon small>mdi-camera</v-icon>
    </v-btn>
    <avatar-cropper v-model="showCropper" :uploadHandler="uploadHandler" />
    <!-- <avatar-cropper
      field="img"
      key="0"
      @input="showCropper = $event"
      lang-type="en"
      v-model="showCropper"
      v-if="showCropper"
      @cropSuccess="cropSuccess"
      :width="300"
      :height="300"
      img-format="png"
    ></avatar-cropper> -->
  </div>
</template>

<script>
import AvatarCropper from "vue-avatar-cropper";
export default {
  components: { AvatarCropper },
  props: {
    value: {},
  },
  data() {
    return {
      image: "",
      input: "",
      showCropper: false,
    };
  },
  watch: {
    value(val) {
      this.image = val;
    },
  },
  methods: {
    uploadHandler(cropper) {
      // console.log();
      let image = cropper.getCroppedCanvas().toDataURL("image/png");
      this.$emit("input", image);
    },
    cropSuccess({ createImgUrl }) {
      this.$emit("input", createImgUrl);
    },
  },
  mounted() {
    this.image = this.value;
  },
};
</script>

<style lang="scss">
.profile-upload {
  height: 150px;
  width: 150px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-image: url("../../../assets/img/user.png");
  background-size: 100px 100px;
  background-position: center;
  border-radius: 100px;
  border: 1px solid lightgrey;
  position: relative;
  img {
    background: lightgray;
    border-radius: 100px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.profile-btn {
  position: absolute;
  z-index: 1;
  bottom: 5px;
  right: 5px;
}
</style>
