<template>
  <div></div>
</template>
  
  <script>
export default {
  mounted() {
    window.opener.location.reload();
    window.close();
  },
};
</script>
  
  <style>
</style>