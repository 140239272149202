<template>
  <app-layout title="Edit your profile">
    <please-wait v-if="isLoading"></please-wait>
    <form-wrapper v-else @submit="submit()">
      <profile-upload
        v-model="form.photo_url"
        class="mb-4 mx-auto"
      ></profile-upload>
      <input-field
        label="Name"
        rules="required"
        v-model="form.display_name"
      ></input-field>
      <input-field
        v-model="form.Title"
        label="Professional Title"
        rules="required"
      ></input-field>
      <input-field
        v-model="form.email"
        label="Email"
        rules="email"
      ></input-field>
      <Textarea
        v-model="form.bio"
        label="Bio (a bit about yourself)"
        rules=""
      ></Textarea>
      <address-field
        label="Your residential location"
        v-model="location"
        @onAddress="onAddress"
      ></address-field>
      <address-field
        id="native-map"
        label="Your native location"
        v-model="native"
        @onAddress="onNativeAddress"
      ></address-field>
      <combo-box
        :fetch="fetchInterests"
        rules="multiselect"
        label="Interests"
        v-model="form.interests"
      ></combo-box>
      <combo-box
        label="Skills"
        rules="multiselect"
        v-model="form.professionalskills"
      ></combo-box>
      <v-btn
        class="primary text-none mb-10"
        depressed
        block
        type="submit"
        large
        :loading="loading"
        >Update Profile</v-btn
      >
    </form-wrapper>
  </app-layout>
</template>

<script>
import AppLayout from "@/components/ui/AppLayout.vue";
import InputField from "@/components/ui/form/InputField.vue";
import AddressField from "@/components/ui/form/AddressField.vue";
import ComboBox from "@/components/ui/form/ComboBox.vue";
import { searchClient } from "@/utils/algolia.utils";
import FormWrapper from "@/components/ui/form/FormWrapper.vue";
import Textarea from "@/components/ui/form/Textarea.vue";
import ProfileUpload from "@/components/ui/form/ProfileUpload.vue";
import PleaseWait from "@/components/ui/PleaseWait.vue";
import { GeoPoint, doc, getDoc, updateDoc } from "firebase/firestore";
import { colUsers, storage } from "@/utils/firebase.utils";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
export default {
  components: {
    AppLayout,
    InputField,
    AddressField,
    ComboBox,
    FormWrapper,
    Textarea,
    ProfileUpload,
    PleaseWait,
  },
  data() {
    return {
      loading: false,
      isLoading: true,
      location: "",
      native: "",
      form: {
        photo_url: "",
        bio: "",
        display_name: "",
        email: "",
        Title: "",
        interests: [],
        professionalskills: [],
        residential: null,
        native: null,
      },
    };
  },
  methods: {
    onAddress(e) {
      if (e) {
        this.location = e.Address || "";
        this.form.residential = {
          State: e.administrative_area_level_1,
          Address: `${e.name}, ${e.locality}, ${e.country}`,
          Country: e.country,
          City: e.locality,
          zipcode: e.postal_code || "",
          Latlang: new GeoPoint(e.latitude, e.longitude),
        };
      }
    },
    onNativeAddress(e) {
      if (e) {
        this.native = e.Address || "";
        this.form.native = {
          State: e.administrative_area_level_1,
          Address: `${e.name}, ${e.locality}, ${e.country}`,
          Country: e.country,
          City: e.locality,
          zipcode: e.postal_code || "",
          Latlang: new GeoPoint(e.latitude, e.longitude),
        };
      }
    },
    fetchInterests(val) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        const index = searchClient.initIndex("interests_master");
        try {
          let res = await index.search(val, {
            cacheable: true,
          });
          let list = res.hits.map((i) => i.interestname);
          resolve(list);
        } catch (error) {
          reject(error);
        }
      });
    },
    async initProfile() {
      const vm = this;
      try {
        vm.isLoading = true;
        let profile = (await getDoc(doc(colUsers, vm.uid))).data() || {};
        Object.keys(vm.form).forEach((key) => {
          if (profile[key] != undefined) {
            vm.form[key] = profile[key];
          }
        });
        if (vm.form.residential) {
          vm.location = vm.form.residential.Address || "";
        }
        if (vm.form.native) {
          vm.native = vm.form.native.Address || "";
        }
        vm.isLoading = false;
      } catch (error) {
        vm.isLoading = false;
        vm.handleError(error);
      }
    },
    async submit() {
      const vm = this;
      try {
        vm.loading = true;
        let photo = vm.form.photo_url;
        let data = { ...vm.form };
        if (photo.startsWith("data:image")) {
          let profileRef = ref(storage, `users/${vm.uid}/uploads/profile.png`);
          await uploadString(profileRef, photo, "data_url");
          data.photo_url = await getDownloadURL(profileRef);
        }
        await updateDoc(doc(colUsers, vm.uid), {
          ...data,
        });
        vm.$router.replace(`/@${vm.authUser.username}`);
        vm.loading = false;
      } catch (error) {
        vm.handleError(error);
      }
    },
  },
  mounted() {
    this.initProfile();
  },
};
</script>

<style></style>
