<template>
  <v-container style="max-width: 600px">
    <div class="d-flex mb-3">
      <v-spacer></v-spacer>
      <v-btn depressed to="/create-post" class="secondary">Add Post</v-btn>
    </div>
    <please-wait v-if="isLoading"></please-wait>
    <template v-else>
      <v-card outlined class="mb-5" v-for="(post, i) in posts" :key="i">
        <v-card-text>
          <div class="subtitle-1">
            {{ post.text }}
          </div>
          <div class="subtitle-1 blue--text">
            {{ formatHashtags(post.hashtags) }}
          </div>
          <img
            class="mt-1"
            style="max-height: 400px; object-fit: contain; width: 100%"
            v-if="post.images.length"
            :src="post.images[0]"
          />
          <div class="mt-1 caption">
            {{ post.created_at }}
          </div>
        </v-card-text>
      </v-card>
    </template>
  </v-container>
</template>

<script>
import { collection, getDocs, limit, orderBy, query } from "firebase/firestore";
import { colUsers } from "@/utils/firebase.utils";
import PleaseWait from "@/components/ui/PleaseWait.vue";
import { getDate } from "@/utils/common.utils";

export default {
  components: { PleaseWait },
  data() {
    return {
      posts: [],
      isLoading: false,
    };
  },
  methods: {
    async fetchPosts() {
      const vm = this;
      try {
        vm.isLoading = true;
        let col = collection(colUsers, vm.uid, "social-posts");
        let q = query(col, orderBy("created_at", "desc"), limit(10));
        vm.posts = (await getDocs(q)).docs.map((i) => {
          let post = { ...i.data(), id: i.id };
          return { ...post, created_at: getDate() };
        });
        vm.isLoading = false;
      } catch (error) {
        vm.handleError(error);
      }
    },
    formatHashtags(tags) {
      return tags
        .map((i) => {
          if (i.toString().startsWith("#")) return i;
          return `#${i}`;
        })
        .map((i) => i.split(" ").join(""))
        .toString()
        .split(",")
        .join(" ");
    },
  },
  mounted() {
    this.fetchPosts();
  },
};
</script>

<style></style>
