import { onAuthStateChanged } from "firebase/auth";
import store from "../../store/index.js";
import { auth, colUsers } from "@/utils/firebase.utils.js";
import { doc, getDoc } from "firebase/firestore";
export const isAuth = (_to, _from, next) => {
  onAuthStateChanged(auth, (user) => {
    store.commit("SET_AUTH", user != null);
    if (user) {
      getDoc(doc(colUsers, user.uid)).then((snap) => {
        store.commit("SET_USER", snap.data());
        if (snap.data() == null) {
          next("/login");
        } else {
          next();
        }
      });
    } else {
      next("/login");
    }
  });
};
export const isNotAuth = (_to, _from, next) => {
  const unsubscribe = onAuthStateChanged(auth, (user) => {
    store.commit("SET_AUTH", user != null);
    if (user) {
      getDoc(doc(colUsers, user.uid)).then((snap) => {
        store.commit("SET_USER", snap.data());
        if (snap.data() == null) {
          next();
        } else {
          let redirect = _to.query.redirect;
          if (redirect) {
            next(redirect);
          } else {
            next("/");
          }
        }
      });
    } else {
      next();
    }
    unsubscribe();
  });
};
