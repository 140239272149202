<template>
  <app-layout :title="title">
    <v-skeleton-loader
      v-if="loading"
      type="list-item-avatar-three-line, image, article"
    />
    <template v-else>
      <v-text-field
        prepend-inner-icon="mdi-magnify"
        outlined
        type="search"
        hide-details
        dense
        class="mb-3"
        v-model="filter.search"
        clearable
        placeholder="Search"
      />
      <v-card
        outlined
        rounded="lg"
        v-for="(item, i) in list"
        :key="i"
        class="mb-3"
      >
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="text-lowercase">{{
              item.name
            }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ item.value }} people
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <div class="d-flex">
              <v-btn
                color="primary"
                @click="openShow(item.facet)"
                text
                rounded
                small
                depressed
                >Show</v-btn
              >
              <v-btn
                color="primary"
                @click="openAsk(item.facet)"
                text
                rounded
                small
                depressed
                >Ask</v-btn
              >
              <v-btn
                color="primary"
                text
                rounded
                small
                v-if="isGroupSelected"
                depressed
                @click="openChat(item)"
                >Chat</v-btn
              >
            </div>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </template>
  </app-layout>
</template>

<script>
import AppLayout from "@/components/ui/AppLayout.vue";
import { fetchAlgoliaFacets } from "@/utils/algolia.utils";
import { mapFacets } from "@/utils/common.utils";
import _ from "lodash";
import peopleMixins from "@/mixins/people.mixins";
import chatMixin from "@/mixins/chat.mixin";

export default {
  name: "Interests",
  components: {
    AppLayout,
  },
  mixins: [peopleMixins, chatMixin],
  data() {
    return {
      interests: [],
      skills: [],
      loading: true,
      filter: {
        search: "",
        within: 25000,
      },
      page: 0,
    };
  },
  computed: {
    withinItems() {
      return [
        {
          name: "With in 3km",
          value: 3000,
        },
        {
          name: "With in 5km",
          value: 5000,
        },
        {
          name: "With in 10km",
          value: 10000,
        },
        {
          name: "With in 25km",
          value: 25000,
        },
      ];
    },
    title() {
      switch (this.$route.name) {
        case "interests":
          return "By Interests";
        case "skills":
          return "By Skills";
        default:
          return "";
      }
    },
    facets() {
      switch (this.$route.name) {
        case "interests":
          return ["interests"];
        default:
          return ["professionalskills"];
      }
    },
    list() {
      let isInterest = this.$route.name == "interests";
      let list = isInterest ? this.interests : this.skills;
      let search = this.filter.search.trim().toLowerCase();
      if (!search) return list;
      return [...list].filter(
        (i) => i.name.toString().trim().toLowerCase().indexOf(search) != -1
      );
    },
    isInterest() {
      return this.$route.name == "interests";
    },
  },
  methods: {
    async fetchFacets() {
      const vm = this;
      try {
        vm.loading = true;
        let requestBody = {
          page: 0,
          hitsPerPage: 0,
          query: "*",
          index: "users",
          ...vm.globalFilter,
          facets: vm.facets,
        };
        let facetRes = await fetchAlgoliaFacets(requestBody);
        let { interests, professionalskills } = facetRes.facets;
        vm.interests = mapFacets(interests || {});
        vm.skills = mapFacets(professionalskills || {});
        vm.loading = false;
      } catch (error) {
        vm.loading = false;
        console.error(error);
      }
    },
    getAddress(user) {
      return _.get(user, "residential.Address", "");
    },
    openAsk(tag) {
      const vm = this;
      let path = `/post?${this.$route.name}=${tag}`;
      if (!vm.confirmLogin(path)) return;
      this.$router.push(path);
    },
    openShow(tag) {
      let query = this.$route.name;
      query = query === "skills" ? "professionalskills" : query;
      let path = `/people?${query}=${tag}`;
      this.$router.push(path);
    },
  },
  mounted() {
    this.fetchFacets();
  },
};
</script>

<style></style>
